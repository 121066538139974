import { toast } from "react-toastify";
import { AIRPORT, LANGUAGE } from "../constants";
import { METHOD_PAYMENT } from "../hooks/usePayment";
import dayjs from "dayjs";

const isThaiNationality = (code) => code === "TH";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const getLangIdFormPMLSlang = (lang) => {
  let langId;
  if (lang === LANGUAGE.CODE.TH) {
    langId = LANGUAGE.ID.TH;
  } else if (lang === LANGUAGE.CODE.EN) {
    langId = LANGUAGE.ID.EN;
  }
  return langId;
};

const getAirportNameFormPmlsAndLang = (pmlsId, lang) => {
  if (!pmlsId || !lang) return "-";
  return AIRPORT.TEXT_BY_ID[pmlsId][lang];
};

const getPaymentTranslationKeyByPaymentMethod = (paymentMethod) => {
  let key = "";
  if (paymentMethod === METHOD_PAYMENT.QR_CODE) {
    key = "QR PromptPay";
  } else if (paymentMethod === METHOD_PAYMENT.CREDIT_CARD) {
    key = "Credit Card";
  } else {
    key = "3";
  }
  return key;
};

const successRequest = (response) => {
  return (
    response.ouIsComplete === 1 &&
    response.ouMessage === "Success" &&
    response.ouReasonCode === "100000"
  );
};

const toastErr = (msg, autoClose = 1500) =>
  toast.error(msg, {
    position: "top-center",
    autoClose: autoClose,
  });

const toastWarning = (msg, autoClose = 1500) =>
  toast.warning(msg, {
    position: "top-center",
    autoClose: autoClose,
  });

const getLicensePlateProvinceText = (data) => {
  if (data?.licenseplate && data?.licenseprovince) {
    return `${data?.licenseplate} ${data?.licenseprovince}`;
  } else {
    return "-";
  }
};

// ตรวจสอบเงื่อนไขการปิดการเลือกวันที่
const shouldDisableDateReceiveInvoice = (date) => {
  // คำนวณวันที่เริ่มต้น (วันนี้) และวันที่สิ้นสุด (วันนี้ + 60 วัน)
  const today = dayjs();
  const endDate = today.add(60, "day");
  // ตรวจสอบว่าวันนั้นเป็นวันเสาร์หรือวันอาทิตย์หรือไม่
  const day = date.day();
  if (day === 0 || day === 6) return true; // 0 = Sunday, 6 = Saturday

  // ตรวจสอบว่าหากเป็นวันที่วันนี้ และเวลาเกิน 16:00 น. ไม่สามารถเลือกได้
  const now = dayjs();
  if (date.isSame(today, "day") && now.hour() >= 16 && now.minute() >= 0) {
    return true;
  }

  // ตรวจสอบว่าวันนั้นเกินช่วง 60 วันหรือไม่
  if (date.isBefore(today, "day") || date.isAfter(endDate, "day")) {
    return true;
  }

  return false;
};

const isPathMatch = (pathname, paths) => paths.some((path) => pathname.includes(path));

export {
  classNames,
  isThaiNationality,
  getLangIdFormPMLSlang,
  getAirportNameFormPmlsAndLang,
  getPaymentTranslationKeyByPaymentMethod,
  toastErr,
  toastWarning,
  successRequest,
  getLicensePlateProvinceText,
  shouldDisableDateReceiveInvoice,
  isPathMatch
};
