import React, { useRef } from "react";
import { Box, styled, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DATE_FORMATS } from "../../constants";
import {
  formattedPriceWithTextCurrency,
} from "../../utils/format";
import SavePaymentAction from "../Base/SavePaymentAction";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";

const BoxTex = styled(Box)(({ theme }) => ({
  display: "flex",
  width: 600,
  border: "1px solid #B3BBC2",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "1rem",
  paddingBottom: "1rem",
  [theme.breakpoints.down("lg")]: {
    width: 580,
  },
  [theme.breakpoints.down("md")]: {
    width: 450,
  },
}));

const BoxTexDetail = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "0.5rem",
  paddingTop: "0.5rem",
  borderBottom: "1px solid #B3BBC2",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "1rem",
    marginRight: "1rem",
  },
}));

const BoxButton = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "1rem 4rem 3rem 4rem",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const TextWelcome = styled(Typography)(() => ({
  font: "normal normal bold 24px/54px Noto Sans Thai",
  letterSpacing: 0,
  color: "#3D9AEE",
}));

const TextSend = styled(Typography)(() => ({
  font: "normal normal normal 15px/60px Noto Sans Thai",
  letterSpacing: 0,
  color: "#1A191A",
}));

const TextGreyBold = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 16px/40px Noto Sans Thai",
  letterSpacing: 0,
  color: "#2F2E2F",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal normal 16px/40px Noto Sans Thai",
  },
}));

const TextBlueBold = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 16px/40px Noto Sans Thai",
  letterSpacing: 0,
  color: "#002afd",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal normal 16px/40px Noto Sans Thai",
  },
}));

const ButtonBackHome = styled(Button)(({ theme }) => ({
  width: "35rem",
  height: "4rem",
  marginTop: "1.884rem",
  font: "normal normal normal 18px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#FFFFFF",
  background:
    "transparent linear-gradient(90deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box",
  border: "2px solid #3D9AEE",
  borderRadius: "10px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const currentDate = dayjs().format(DATE_FORMATS.DEFAULT);

// TODO: paytype map with ms/paytype
// TODO: displayInformation ใช้แทนที่ สถานที่จอดรถ โซนที่จอดรถ
const ReservedParkingSummary = ({
  responsePayment,
  displayInformation,
  paymentNameByPaymentCode,
  formik,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pmlsId } = useSelector((state) => state.airport);
  const frameRef = useRef(null);

  const onClickSaveScreenshot = () => {
    const frameNode = frameRef.current;

    if (frameNode) {
      html2canvas(frameNode).then((canvas) => {
        const image = canvas.toDataURL();

        const link = document.createElement("a");
        link.download = "screenshot.png";
        link.href = image;
        link.click();
      });
    }
  };

  const onClickRequestReceipt = () => {
    const receiptNo = responsePayment?.receipt?.data?.receiptno;
    if (!receiptNo) {
      return alert("ไม่พบหมายเลขขอใบกำกับภาษี");
    } else {
      navigate(`/requestreceipt?rr=${receiptNo}&pmlsid=${pmlsId}`);
    }
  };

  return (
    <div className="flex items-center flex-col justify-center">
      <div className="flex items-center flex-col  pt-2" ref={frameRef}>
        <img
          src="/images/prepaid/img_complate.png"
          alt=""
          className="w-[200px] h-auto"
        />
        <TextWelcome>{t("Completed")}</TextWelcome>
        <TextSend>{currentDate}</TextSend>
        <div className="flex flex-col justify-center items-center mx-2">
          <BoxTex>
            <BoxTexDetail>
              <TextGreyBold>{t("Receipt No")}</TextGreyBold>
              <TextGreyBold>
                {responsePayment?.receipt?.data?.receiptno || "-"}
              </TextGreyBold>
            </BoxTexDetail>
            <BoxTexDetail>
              <TextGreyBold>{t("Payment Method")}</TextGreyBold>
              <TextGreyBold sx={{ textAlign: "right" }}>
                {paymentNameByPaymentCode || "-"}
              </TextGreyBold>
            </BoxTexDetail>
            <BoxTexDetail>
              <TextGreyBold>{t("Total")}</TextGreyBold>
              <TextGreyBold>
                {formattedPriceWithTextCurrency(
                  responsePayment?.payment?.tbonlinepayment?.amount,
                  "th"
                )}
              </TextGreyBold>
            </BoxTexDetail>

            <BoxTexDetail>
              <TextGreyBold>{t("License Plate")}</TextGreyBold>
              <TextBlueBold>{formik?.values?.licenseplate}</TextBlueBold>
            </BoxTexDetail>

            <BoxTexDetail>
              <TextGreyBold>{t("Province")}</TextGreyBold>
              <TextBlueBold>
                {formik.values.licenseprovince?.display}
              </TextBlueBold>
            </BoxTexDetail>

            <BoxTexDetail>
              <TextGreyBold>{t("Airport")}</TextGreyBold>
              <TextGreyBold>{t("Suvarnabhumi airport")}</TextGreyBold>
            </BoxTexDetail>
            <BoxTexDetail>
              <TextGreyBold>{t("Parking Location")}</TextGreyBold>
              <TextGreyBold>
                {/* {responsePayment?.reserve?.parkingid || "-"} */}
                {displayInformation.parking || "-"}
                {/* เอาไปแมพหา name มาแสดง จากเส้น /subsystem/pmls/sys/parking/all? */}
              </TextGreyBold>
            </BoxTexDetail>
            <BoxTexDetail>
              <TextGreyBold>{t("Parking Zone")}</TextGreyBold>
              <TextGreyBold>
                {/* {responsePayment?.reserve?.areaid || "-"} */}
                {displayInformation.zone || "-"}
                {/* ไปแมพกับ parkingareaid จากเส้น /subsystem/pmls/sys/parking/area? เอา areaname มาแสดง */}
              </TextGreyBold>
            </BoxTexDetail>
            <BoxTexDetail sx={{ border: "none" }}>
              <TextGreyBold>{t("Parking Card No")}</TextGreyBold>
              <TextGreyBold>
                {responsePayment?.reserve?.ticketno || "-"}
              </TextGreyBold>
            </BoxTexDetail>

            <div className="flex flex-col gap-y-4 items-center my-4">
              <Box
                component="img"
                src={`data:image/png;base64,${responsePayment?.reserve?.imgqrcode}`}
                alt="qr code"
                sx={{ width: "300px", height: "300px" }}
              />
              <span className="text-lg font-semibold">
                กรุณานำรถเข้าก่อนเวลา {responsePayment?.reserve?.resvenddt}
              </span>
            </div>
          </BoxTex>
        </div>
      </div>
      <BoxButton>
        <SavePaymentAction
          onClickSaveScreenshot={onClickSaveScreenshot}
          onClickRequestReceipt={onClickRequestReceipt}
        />
        <ButtonBackHome
          onClick={() => {
            navigate("/home");
          }}
        >
          {t("Back to Home")}
        </ButtonBackHome>
      </BoxButton>
    </div>
  );
};

export default ReservedParkingSummary;
