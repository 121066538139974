import { useMutation } from "@tanstack/react-query";
import ConfigService from "../services/config-service";
import { successRequest, toastErr } from "../utils";
import { useMemo, useState } from "react";

export const isOnlineConfigStatusClose = (status) => status === 0; //for testing === 1

const findIsMApp = (data) =>
  data.length && data?.find((item) => item.app === "mapp");

const useOnlineConfig = () => {
  const [onlineConfigData, setOnlineConfigData] = useState(undefined);

  const getOnlineConfigMutate = useMutation({
    mutationFn: (params) =>
      ConfigService.getOnlineConfigFromPMLS({ pmlsid: params.pmlsid }),
    onError: (err) => {
      toastErr(err);
      console.error(err);
    },
  });

  const getOnlineConfig = async (pmlsid) => {
    if (pmlsid === undefined) return;
    try {
      const res = await getOnlineConfigMutate.mutateAsync({ pmlsid });
      if (successRequest(res)) {
        // response from API
        // "SysOnlineConfig": [
        //     {
        //         "onlineconfigid": 1,
        //         "app": "mapp",
        //         "isactive": 1,
        //         "isonlinepayment": 1,
        //         "ismemberqr": 1,
        //         "isvisitorqr": 1,
        //         "ismembercreditcard": 0,
        //         "isvisitorcreditcard": 0
        //     }
        // ]
        // }
        const sysOnlineConfig = res?.data?.SysOnlineConfig;
        if (sysOnlineConfig?.length > 0) {
          const isMAppData = findIsMApp(sysOnlineConfig);
          if (Object.keys(isMAppData).length > 0) {
            setOnlineConfigData(isMAppData);
          }
        }
      }
    } catch (err) {
      toastErr(err);
      console.error(err);
    }
  };

  const isCloseApp = useMemo(() => {
    return isOnlineConfigStatusClose(onlineConfigData?.isactive);
  }, [onlineConfigData]);

  return {
    onlineConfigData,
    isCloseApp,
    getOnlineConfig,
  };
};

export default useOnlineConfig;
