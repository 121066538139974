import React from "react";
import BoxContent from "../../Box/BoxContent";
import { TitleWithValue } from "../../Base/TitleWithValue";
import { useTranslation } from "react-i18next";
import { DATE_FORMATS } from "../../../constants";
import dayjs from "dayjs";
import AutocompleteAOT from "../../Controls/AutoCompleteAOT";
import { renewExpiredtIsBeforeCurrentMonth } from ".";

const DisplayInformationBox = ({
  airport,
  memberType,
  vehicleTypeName,
  licensePlate,
  licenseProvince,
  parkingGroupName,
  packageGroupName,
  packageExpiredDate,
  numberOfMonth,
  newPackageStartDate,
  newPackageExpiredDate,
  selectRenewMonth,
  onChangeSelectRenewCurrentMonth,
  renewWithMonthlyCap = false,
  isEffectiveDateConfig,
}) => {
  const { t } = useTranslation();

  const generateCurrentAndNextMonthOptions = () => {
    const currentMonth = dayjs().format("MMMM");
    const nextMonth = dayjs().add(1, "month").format("MMMM");
    return [
      {
        display: currentMonth,
        id: "1",
      },
      {
        display: nextMonth,
        id: "2",
      },
    ];
  };

  const memberInformationData = [
    {
      title: t("Airport"),
      value: airport,
    },
    {
      title: t("Member Type"),
      value: memberType,
    },
    {
      title: t("Vehicle Type"),
      value: vehicleTypeName,
    },
    {
      title: t("License Plate"),
      value: licensePlate,
    },
    {
      title: t("Province"),
      value: licenseProvince,
    },
    {
      title: t("Expired Date"),
      value: dayjs(packageExpiredDate).format(DATE_FORMATS.FORMAT_01),
    },
    {
      title: t("Parking Group"),
      value: parkingGroupName,
    },
    {
      title: t("Member Package"),
      value: packageGroupName,
    },
  ];

  const renewDurationData = [
    {
      title: t("Month Amount"),
      value: `${numberOfMonth} ${t("Month")}`,
      isShow: !renewWithMonthlyCap,
    },
    {
      title: t("Month for renewal"),
      value: (
        <AutocompleteAOT
          options={generateCurrentAndNextMonthOptions()}
          size="small"
          label={t("Select Month")}
          val={selectRenewMonth}
          required
          handleChange={(_, newValue) => {
            onChangeSelectRenewCurrentMonth(newValue);
          }}
          fullWidth
          sxa={{
            width: "170px",
          }}
        />
      ),
      isShow: renewWithMonthlyCap
        ? false
        : renewExpiredtIsBeforeCurrentMonth(packageExpiredDate),
    },
    {
      title: t("Apply Start Date"),
      value:
        newPackageStartDate &&
        selectRenewMonth !== null &&
        renewExpiredtIsBeforeCurrentMonth(packageExpiredDate)
          ? dayjs(newPackageStartDate)?.format(DATE_FORMATS.FORMAT_01)
          : !renewExpiredtIsBeforeCurrentMonth(packageExpiredDate) &&
            newPackageStartDate
          ? dayjs(newPackageStartDate)?.format(DATE_FORMATS.FORMAT_01)
          : "dd/mm/yyyy",
      isShow: true,
      isShowAlert: renewWithMonthlyCap ? false : isEffectiveDateConfig,
      valueAlert: (
        <p className="text-red-500 text-center font-semibold pb-4">
          {t("Remark renew parking fee")}
        </p>
      ),
    },
    {
      title: t("Expired Date"),
      value:
        newPackageStartDate &&
        selectRenewMonth !== null &&
        renewExpiredtIsBeforeCurrentMonth(packageExpiredDate)
          ? dayjs(newPackageExpiredDate)?.format(DATE_FORMATS.FORMAT_01)
          : !renewExpiredtIsBeforeCurrentMonth(packageExpiredDate) &&
            newPackageExpiredDate
          ? dayjs(newPackageExpiredDate)?.format(DATE_FORMATS.FORMAT_01)
          : "dd/mm/yyyy",
      isShow: true,
    },
  ];

  return (
    <BoxContent
      headerTitle={t("Renew Membership")}
      headerIcon={<img src="/images/renew/ico_renew_bl.svg" alt="icon" />}
    >
      <div className="space-y-12">
        <div className="px-2">
          <h3 className="text-base md:text-lg lg:text-xl font-semibold">
            {t("Enter Renew Membership Information")}
          </h3>
          <div className="divide-y px-4 lg:px-6 mt-2">
            {memberInformationData.map((item, index) => (
              <TitleWithValue
                title={item.title}
                value={item.value}
                valueStyles="text-gray-500"
                boxStyles="py-4"
                key={index}
              />
            ))}
          </div>
        </div>
        {renewWithMonthlyCap && (
          <p className="text-center text-base text-red-500 font-semibold">
            {t("Unable to renew due to membership has been renewed in advance")}
          </p>
        )}
        <div className="px-2">
          <h3 className="text-base md:text-lg lg:text-xl font-semibold ">
            {renewWithMonthlyCap
              ? t("Advance Renew Membership Information")
              : t("Renew Duration")}
          </h3>
          <div className="divide-y px-4 lg:px-6 mt-2">
            {renewDurationData.map((item, index) => {
              if (item?.isShow) {
                return (
                  <div key={index}>
                    <TitleWithValue
                      title={item.title}
                      value={item.value}
                      valueStyles="text-gray-500"
                      boxStyles="py-4"
                      key={index}
                    />
                    {item?.isShowAlert && item.valueAlert
                      ? item.valueAlert
                      : null}
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </BoxContent>
  );
};

export default DisplayInformationBox;
