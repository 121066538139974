/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageContent from "../../layouts/PageContent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import TicketService from "../../services/ticket-service";
import { useSelector } from "react-redux";
import { formattedBase64Image, formattedDate_01 } from "../../utils/format";
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import ParkingService from "../../services/parking-service";
import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";

const isNormalTicket = (type) => type !== "reserve";

const MyTicketDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { pmlsId } = useSelector((state) => state.airport);
  const { id } = params;
  const { t, i18n } = useTranslation();
  const [qrCodeImage, setQrCodeImage] = useState(undefined);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { data: myTicketData, isLoading: myTicketLoading } = useQuery({
    queryKey: ["GET_MY_TICKET_BY_TICKET_NO", id],
    queryFn: () => TicketService.getMyTicketDetailByTicketNo(id),
    onSuccess: (response) => {
      if (response?.status === 200 && response?.data?.ouIsComplete === 1) {
        // const tickettype = response?.data?.data?.Detail?.tickettype;
        const ticketno = response?.data?.data?.Detail?.ticketno;
        const gateentry = response?.data?.data?.Detail?.gateentry;

        if (ticketno) {
          getTicketQRCodeMutate.mutate({
            pmlsid: pmlsId,
            ticketno: String(ticketno),
          });
        } else {
          toast.error("ticketno not found!", {
            position: "top-center",
            autoClose: 1000,
          });
        }

        getParkingGateFormPMLSAllMutate({
          pmlsid: pmlsId,
          langid: i18n?.language === "th" ? 2 : 1,
        });

        if (gateentry) {
          getParkingGateFormPmlsMutate({
            pmlsid: pmlsId,
            parkinggateid: gateentry,
            langid: i18n?.language === "th" ? 2 : 1, //TODO: state for language id
          });
        }
      }
    },
    enabled: id !== null,
  });

  const getTicketQRCodeMutate = useMutation({
    mutationFn: (body) => TicketService.getTicketQRCode(body),
    onSuccess: (response) => {
      if (response.status === 200 && response?.data?.data?.imgqrcode) {
        const imgqrcode = response?.data?.data?.imgqrcode;
        setQrCodeImage(imgqrcode);
      } else {
        toast.error("imgqrcode not found!!", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    },
    onError: (response) => {
      toast.error("something went wrong!", {
        position: "top-center",
        autoClose: 1000,
      });
    },
  });

  const deleteTicketMutate = useMutation({
    mutationFn: (body) => TicketService.deleteTicket(body),
    onSuccess: (response) => {
      if (response.status === 200 && response?.data?.ouIsComplete === 1) {
        toast.success("ลบสำเร็จ", {
          position: "top-center",
          autoClose: 1000,
        });
        navigate("/members/myticket");
      }
    },
    onError: (response) => {
      alert("something went wrong!");
    },
  });

  const { data: parkingGateData, mutate: getParkingGateFormPmlsMutate } =
    useMutation({
      mutationFn: (params) => ParkingService.getParkingGateFromPMLS(params),
      onSuccess: (response) => {
        if (response.status === 200) {
        } else {
          alert("someting went wrong!");
        }
      },
      onError: (response) => {
        alert("something went wrong!");
      },
    });

  const { data: parkingGateAllData, mutate: getParkingGateFormPMLSAllMutate } =
    useMutation({
      mutationFn: (params) => ParkingService.getParkingGateFormPMLSAll(params),
      onSuccess: (response) => {
        if (response.status === 200) {
        } else {
          alert("someting went wrong!");
        }
      },
      onError: (response) => {
        alert("something went wrong!");
      },
    });

  const ticketData = myTicketData?.data?.data || {};
  const ticketDetailData = ticketData?.Detail || {};
  const _parkingGateData = parkingGateData?.data?.data?.tbParkingGate;

  const parkingGateEntryExit = useMemo(() => {
    const parkingGate = parkingGateAllData?.data?.data?.tbParkingGate;
    const _gateentry = parkingGate?.find(
      (item) => item.parkinggateid === ticketDetailData?.gateentry
    );
    const _gateexit = parkingGate?.find(
      (item) => item.parkinggateid === ticketDetailData?.gateexit
    );
    return { _gateentry, _gateexit };
  }, [parkingGateAllData?.data, ticketDetailData]);

  const ticketType = useMemo(() => {
    return ticketDetailData?.tickettype;
  }, [ticketDetailData]);

  const onClickBackButtonHandler = () => {
    navigate("/members/myticket");
  };

  if (myTicketLoading) {
    return (
      <div className="w-full h-[400px] flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <PageContent
      title="รายละเอียดบัตรจอดรถ"
      icon={<StyleOutlinedIcon fontSize="large" htmlColor="#245AB1" />}
      breadcrumb={
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            หน้าหลัก
          </Link>
          <Link underline="hover" color="inherit" href="/members/myticket">
            บัตรจอดรถของฉัน
          </Link>
          <Typography color="#245AB1">รายละเอียดบัตรจอดรถ</Typography>
        </Breadcrumbs>
      }
    >
      <section className="w-full">
        {/* header */}
        <div className="w-full bg-[#245AB1] h-20 flex items-center justify-between px-8 mt-4 rounded-sm">
          <p className="text-white text-lg lg:text-xl font-semibold">
            {!isNormalTicket(ticketType)
              ? "บัตรจองที่จอดรถ"
              : "บัตรจอดรถทั่วไป"}
          </p>
          <div
            onClick={onClickBackButtonHandler}
            className="text-white cursor-pointer inline-flex gap-1 rounded-sm"
          >
            <ArrowBackIcon />
            {t("Back")}
          </div>
        </div>
        <div className="w-full h-12 bg-[#E9F0F5] flex items-center justify-between px-8 gap-7">
          <div className="font-bold">
            <span className="">หมายเลขบัตรจอดรถ:</span>{" "}
            <span>{ticketDetailData?.ticketno}</span>
          </div>
          <DeleteIcon className="cursor-pointer" onClick={handleClickOpen} />
        </div>
        {/* content */}
        <div className="flex flex-col lg:flex-row  gap-x-28 gap-y-8 py-9  lg:mx-10">
          <div className="">
            <FieldWithQrCode
              loading={getTicketQRCodeMutate?.isLoading}
              qrBase64={qrCodeImage}
              state={ticketData?.TbMyTicket[0]?.state}
            />
          </div>
          <div className="space-y-6 lg:w-1/2">
            {!isNormalTicket(ticketType) && (
              <ReserveTicket data={ticketDetailData} />
            )}
            <NormalTicket
              data={ticketDetailData}
              parkingGateData={_parkingGateData}
              gateEntry={parkingGateEntryExit?._gateentry?.name_translate}
              gateExit={parkingGateEntryExit?._gateexit?.name_translate}
            />
          </div>
        </div>
      </section>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ยืนยันการลบบัตรจอดรถของคุณ{" "}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ยืนยันลบบัตรจอดรถ
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
          <Button
            onClick={() => {
              const myticketid = ticketData?.TbMyTicket[0]?.myticketid;
              if (!myticketid) {
                return alert("myticketid not found, can't delete this ticket");
              }
              deleteTicketMutate.mutate({
                myticketid,
              });
            }}
            autoFocus
          >
            ยืนยัน
          </Button>
        </DialogActions>
      </Dialog>
    </PageContent>
  );
};

const NormalTicket = ({ data, parkingGateData, gateEntry, gateExit }) => {
  return (
    <div className="space-y-6">
      <p className=" text-xl lg:text-2xl font-bold">รายละเอียดการจอดรถ</p>
      <div className="ml-4 py-2 space-y-3 ">
        <FieldWithValue
          title="วันที่เข้าจอดรถ"
          value={data?.entrydt ? formattedDate_01(data?.entrydt) : "-"}
        />
        <FieldWithValue
          title="วันที่นำรถออก"
          value={data?.exitdt ? formattedDate_01(data?.exitdt) : "-"}
        />
        <FieldWithValue
          title="เลขทะเบียนรถ"
          value={data?.licenseplate || "-"}
        />
        <FieldWithValue
          title="สถานที่จอดรถ"
          value={parkingGateData?.parkingname_translate || "-"}
        />
        <FieldWithValue
          title="ชั้น"
          value={parkingGateData?.parkingfloorname_translate || "-"}
        />
        <FieldWithValue
          title="โซนที่จอดรถ"
          value={parkingGateData?.parkingareaname_translate || "-"}
        />
        <FieldWithValue title="ทางเข้า" value={gateEntry || "-"} />
        <FieldWithValue title="ทางออก" value={gateExit || "-"} />
      </div>
    </div>
  );
};

const ReserveTicket = ({ data }) => {
  return (
    <div>
      <p className="text-2xl font-bold">ข้อมูลการจอง</p>
      <div className="ml-4 py-2 space-y-2">
        <FieldWithValue
          title="วันที่จอง"
          value={formattedDate_01(data?.reservebooking?.createdt)}
        />
        <FieldWithValue
          title="กรุณานำรถเข้าลานจอดรถ"
          value={formattedDate_01(data?.reservebooking?.resvenddt)}
        />
        <FieldWithValue
          title="สถานที่จอดรถ"
          value={data?.reservebooking?.parkingname}
        />
        <FieldWithValue
          title="ชั้น"
          value={data?.reservebooking?.pakingfloorname}
        />
        <FieldWithValue
          title="โซนที่จอดรถ"
          value={data?.reservebooking?.pakingareaname}
        />
        <FieldWithValue title="เลขที่ใบเสร็จ" value={data?.fee[0]?.receiptno} />
        <FieldWithValue
          title="ช่องทางการชำระเงิน"
          value={data?.fee[0]?.paytypename}
        />
        <FieldWithValue
          title="ยอดทั้งหมด"
          value={`${data?.fee[0]?.totalamount} บาท`}
        />
      </div>
    </div>
  );
};

const FieldWithValue = ({ title, value }) => {
  return (
    <div className="grid grid-cols-2 text-base lg:text-lg">
      <p className=" font-semibold col-span-1">{title}:</p>
      <p className="col-span-1 text-gray-600">{value}</p>
    </div>
  );
};

const FieldWithQrCode = ({ qrBase64, state, loading = true }) => {
  const isExpiredState = state === "Expired";

  return (
    <div className="flex flex-col gap-y-2 text-center items-center w-full h-auto">
      {state ? (
        <p
          className={`text-2xl font-semibold ${
            isExpiredState ? "text-red-500" : ""
          }`}
        >
          {state}
        </p>
      ) : (
        <div className="h-4" />
      )}{" "}
      {loading ? (
        <div className="h-[250px] flex items-center justify-center">
          <CircularProgress className="" />
        </div>
      ) : (
        <img
          src={formattedBase64Image("png", qrBase64)}
          className="w-[250px] h-auto  flex-none"
          alt="ticket_qrcode"
        />
      )}
      <div className="text-slate-600 font-semibold text-lg">
        <p>QR Code</p>
        <p>สำหรับใช้เข้าลานจอดรถ</p>
      </div>
    </div>
  );
};

export default MyTicketDetail;
