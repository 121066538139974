import React from "react";
import { getLicensePlateProvinceText, getPaymentTranslationKeyByPaymentMethod } from "../../utils";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import PaymentDetailBoxPaidFee from "../Payment/PaymentDetailBoxPaidFee";
import ReceivePaymentConfirmCheckbox from "../Payment/ReceivePaymentConfirmCheckbox";
import { DATE_FORMATS } from "../../constants";

const QRTicketFeeParkingPreviewPaymentDetail = ({
  parkingFeeCalculateData,
  method,
  receivePaymentConfirmFn = {
    emailIsValid: false,
    checkedSendEmail: false,
    sendEmailValue: "",
    setCheckedSendEmail: () => {},
    setSendEmailValue: () => {},
  },
}) => {
  const { t } = useTranslation();

  const confirmPaymentDetails = [
    {
      title: t("License Plate"),
      value: getLicensePlateProvinceText(parkingFeeCalculateData),
    },
    {
      title: t("Entry Date Time"),
      value:
        (parkingFeeCalculateData?.start &&
          dayjs(parkingFeeCalculateData?.start).format(DATE_FORMATS.FORMAT_03)) ||
        "-",
    },
    {
      title: t("Exit Date Time"),
      value:
        (parkingFeeCalculateData?.end &&
          dayjs(parkingFeeCalculateData?.end).format(DATE_FORMATS.FORMAT_03)) ||
        "-",
    },
    {
      title: t("Total Parking Time"),
      value: `${
        parkingFeeCalculateData?.durationday
          ? parkingFeeCalculateData?.durationday + ` ${t("Day")}`
          : ""
      }
      ${
        parkingFeeCalculateData?.durationhour
          ? parkingFeeCalculateData?.durationhour + ` ${t("Hour")}`
          : ""
      } ${
        parkingFeeCalculateData?.durationminute
          ? parkingFeeCalculateData?.durationminute + ` ${t("Minute")}`
          : ""
      }`,
    },
  ];

  return (
    <PaymentDetailBoxPaidFee
      titleIcon={"/images/feeparking/ico_name_carpark.svg"}
      titleText={t("Parking Fee Summary")}
      methodText={getPaymentTranslationKeyByPaymentMethod(method)}
      details={confirmPaymentDetails}
      parkingFeeCalculateData={parkingFeeCalculateData}
      footerExtra={
        <ReceivePaymentConfirmCheckbox
          emailIsValid={receivePaymentConfirmFn?.emailIsValid}
          checkedSendEmail={receivePaymentConfirmFn?.checkedSendEmail}
          sendEmailValue={receivePaymentConfirmFn?.sendEmailValue}
          setCheckedSendEmail={receivePaymentConfirmFn?.setCheckedSendEmail}
          setSendEmailValue={receivePaymentConfirmFn?.setSendEmailValue}
        />
      }
    />
  );
};

export default QRTicketFeeParkingPreviewPaymentDetail;
