import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { store } from "./app/store";
import Loading from "./components/Loading";
import { queryClientConfig } from "./config/react-query";
import "./index.css";
import "./i18n";
import App from "./App";

const rootElement = document.getElementById("root");

if (rootElement) {
  const queryClient = new QueryClient(queryClientConfig);
  const root = createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </Suspense>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
} else {
  console.error("Root element with id 'root' not found");
}
