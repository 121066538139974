import { Box, styled, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useNavigate } from "react-router";
import {
  formattedDate_01,
  formattedPriceWithTextCurrency,
} from "../../utils/format";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SavePaymentAction from "../Base/SavePaymentAction";
import html2canvas from "html2canvas";
import ButtonAction from "../Base/ButtonAction";
import { AIRPORT, DATE_FORMATS } from "../../constants";

const BoxTransfer = styled(Box)(({ theme }) => ({
  width: "95%",
  display: "flex",
  justifyContent: "space-between",
  paddingTop: "0.2rem",
  borderBottom: "1px solid #B3BBC2",
  [theme.breakpoints.down("xl")]: {},
}));


const TextTitleItem = styled(Typography)(({ theme }) => ({
  font: "normal normal bold 18px/60px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("md")]: {
    font: "normal normal bold 16px/60px Noto Sans Thai",
  },
}));

const TextParagraphItem = styled(Typography)(({ theme }) => ({
  font: "normal normal 18px/60px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("md")]: {
    font: "normal normal 16px/60px Noto Sans Thai",
  },
}));

const BoxFeeParking4 = ({
  receiptNo,
  completedTransactionDate,
  resultPaymentReceiptDisplay,
  isPaymentPendingTransaction = false,
  isQRTicket,
  onClickAdditionalPaymentBtnHandler = () => {},
}) => {
  const navigate = useNavigate();
  const { pmlsId } = useSelector((state) => state.airport);
  const { memberInfo } = useSelector((state) => state.auth);
  const frameRef = useRef(null);
  const { t } = useTranslation();

  const handleScreenshot = () => {
    const frameNode = frameRef.current;

    if (frameNode) {
      html2canvas(frameNode).then((canvas) => {
        const image = canvas.toDataURL();
        const link = document.createElement("a");
        link.download = "screenshot.png";
        link.href = image;
        link.click();
      });
    }
  };

  const handleRequestReceipt = () => {
    if (!receiptNo) {
      return alert("ไม่พบหมายเลขขอใบกำกับภาษี");
    } else {
      navigate(`/requestreceipt?rr=${receiptNo}&pmlsid=${pmlsId}`);
    }
  };

  return (
    <div className="w-full flex items-center flex-col justify-center">
      {isPaymentPendingTransaction && (
        <p className="text-base md:text-xl font-semibold mt-10">
          {t("Payment Result")}
        </p>
      )}
      <div
        ref={frameRef}
        className="flex flex-col justify-center items-center pb-4 pt-4 w-full"
      >
        <div className="space-y-4 text-center">
          <img
            src="/images/status/success_result.png"
            alt="success_payment"
            className="w-[180px] h-auto"
          />
          <p className="text-[#3D9AEE] font-bold text-xl">{t("Completed")}</p>

          <p className="text-base">
            {completedTransactionDate &&
              dayjs(completedTransactionDate)
                .locale("th")
                .format(DATE_FORMATS.FORMAT_02)}
          </p>
        </div>

        <div className="border border-[#B3BBC2] w-full max-w-[500px] flex px-4 flex-col items-center mt-4">
          <BoxTransfer>
            <TextTitleItem>{t("Receipt No")}</TextTitleItem>
            <TextParagraphItem>{receiptNo || "-"}</TextParagraphItem>
          </BoxTransfer>
          <BoxTransfer>
            <TextTitleItem>{t("Airport")}</TextTitleItem>
            <TextParagraphItem>
              {resultPaymentReceiptDisplay?.airport || ""}
            </TextParagraphItem>
          </BoxTransfer>
          <BoxTransfer>
            <TextTitleItem>{t("License Plate")}</TextTitleItem>
            <TextParagraphItem>
              {resultPaymentReceiptDisplay?.licensePlate}
            </TextParagraphItem>
          </BoxTransfer>

          <BoxTransfer>
            <TextTitleItem>{t("Entry Date Time")}</TextTitleItem>
            <TextParagraphItem>
              {formattedDate_01(resultPaymentReceiptDisplay?.start)}
            </TextParagraphItem>
          </BoxTransfer>

          <BoxTransfer>
            <TextTitleItem>{t("Total Parking Time")}</TextTitleItem>
            <TextParagraphItem>
              {resultPaymentReceiptDisplay?.totalParkingTime}
            </TextParagraphItem>
          </BoxTransfer>
          <BoxTransfer>
            <TextTitleItem>{t("Payment Method")}</TextTitleItem>
            <TextParagraphItem>
              {resultPaymentReceiptDisplay?.paymentMethod}
            </TextParagraphItem>
          </BoxTransfer>

          <BoxTransfer>
            <TextTitleItem>{t("Transfer Amount")}</TextTitleItem>
            <TextParagraphItem>
              {formattedPriceWithTextCurrency(
                resultPaymentReceiptDisplay?.totalamount
              )}
            </TextParagraphItem>
          </BoxTransfer>
          <div className="my-6 text-red-500 text-xl font-bold text-center leading-10">
            <p className="">{t("Please exit before")}</p>
            <p>
              *{t("Date2")}{" "}
              {dayjs(resultPaymentReceiptDisplay?.displaydelaytime).format(
                "DD/MM/YYYY"
              )}{" "}
              {t("Time")}{" "}
              {dayjs(resultPaymentReceiptDisplay?.displaydelaytime).format(
                "HH:mm"
              )}
              *
            </p>
            <p className="text-black font-medium text-base">
              {t("Exceed time additional service charge")}
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center mt-2 w-full gap-y-4 max-w-[500px]">
        <p className="text-center font-medium px-10">
          {pmlsId === AIRPORT.ID.DONMUANG
            ? t("Remark receive receipt dmk")
            : t("Remark receive receipt")}
        </p>
        <SavePaymentAction
          requestReceipt={false}
          onClickSaveScreenshot={handleScreenshot}
          onClickRequestReceipt={handleRequestReceipt}
        />
        <div className="w-full space-y-3">
          {resultPaymentReceiptDisplay?.fee !== 0  && (
              <ButtonAction
                variant="orange"
                textSx={{
                  color: "white",
                }}
                onClick={onClickAdditionalPaymentBtnHandler}
              >
                {t("Make an additional payment")}
              </ButtonAction>
            )}
          {!isQRTicket && (
            <ButtonAction
              onClick={() => {
                navigate(`/${memberInfo ? "pages" : "home"}`);
              }}
            >
              {t("Back to Home")}
            </ButtonAction>
          )}
        </div>
      </div>
    </div>
  );
};

export default BoxFeeParking4;
