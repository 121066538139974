import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ContainerCenter from "../../layouts/ContainerCenter";
import ParkingDensityBox from "../ParkingDensity/ParkingDensityBox";
import dayjs from "dayjs";
import { DATE_FORMATS } from "../../constants";

const ChartTitleLegendText = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 28px/30px Noto Sans Thai",
  color: "#2F2E2F",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal 600 24px/26px Noto Sans Thai",
  },
}));

const BoxCarparkChart = ({ data }) => {
  const { t } = useTranslation();
  return (
    <ContainerCenter>
      <Box sx={{ paddingY: "4rem" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ChartTitleLegendText>{t("Parking Density")}</ChartTitleLegendText>
        </Box>
        <div className="flex flex-col md:flex-row mt-10 gap-10 px-4 lg:px-32 ">
          {data?.map((item, index) => (
            <ParkingDensityBox
              key={index}
              parkingName={item?.parkingname_translate}
              totalGuidePlace={item?.totalguideplace}
              leftGuidePlace={item?.leftguideplace}
              floors={item?.floor}
            />
          ))}
        </div>
        <p className="text-center mt-10 text-primaryBlue_600 font-medium">
          {t("Latest Update")} {dayjs().format(DATE_FORMATS.FORMAT_03)}
        </p>
        {/* <NewCarparkBarChart remainSpaceData={data} /> */}
      </Box>
    </ContainerCenter>
  );
};

export default BoxCarparkChart;
