import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import BoxCarparkChart from "../components/HomeAOT/BoxCarparkChart";
import { useQuery } from "@tanstack/react-query";
import ParkingService from "../services/parking-service";
import { LANGUAGE } from "../constants";
import { useTranslation } from "react-i18next";
import { toastErr } from "../utils";

const ParkingDensityWebView = () => {
  const location = useLocation();
  const { i18n } = useTranslation();

  // Extract query parameters
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const lang = searchParams.get("lang");
  const pmlsid = searchParams.get("pmlsid");

  // Memoize langid for ParkingService
  const langid = useMemo(
    () => (lang === LANGUAGE.CODE.TH ? LANGUAGE.ID.TH : LANGUAGE.ID.EN),
    [lang]
  );

  // React Query to fetch parking data
  const { data, isLoading } = useQuery(
    ["PARKING_REMAIN_SPACE", pmlsid, lang],
    () =>
      ParkingService.remainSpace({
        airportid: pmlsid,
        langid,
      }),
    {
      enabled: !!pmlsid && !!lang, // Ensure the query only runs with valid params
    }
  );

  // Update i18n language
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  // Extract parking data
  const parkingRemainSpaceData = data?.data?.data?.allParkingStat;

  // Render UI
  if (!pmlsid || !lang) {
    toastErr('Invalid parameters. Please provide both "lang" and "pmlsid".');
    return <p>Invalid parameters. Please provide both `lang` and `pmlsid`.</p>;
  }

  return (
    <section>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="mx-auto">
          <BoxCarparkChart data={parkingRemainSpaceData} />
        </div>
      )}
    </section>
  );
};

export default ParkingDensityWebView;
