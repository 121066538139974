import { useTranslation } from "react-i18next";
import { formattedPriceWithTextCurrency } from "../../utils/format";
import TextWithIcon from "../Base/TextWithIcon";
import { TitleWithValue } from "../Base/TitleWithValue";
import BoxSemiCircle from "../BoxSemiCircle";

export const PaymentDetailBoxPaidFee = ({
  titleText,
  titleIcon,
  airportText = "",
  methodText,
  footerExtra,
  details,
  parkingFeeCalculateData,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full border border-[#B3BBC2] border-t-[15px] border-t-primaryBlue_500 relative">
        <div className="p-10 flex flex-col gap-y-6">
          {titleIcon && titleText && (
            <TextWithIcon
              gap={20}
              icon={<img src={titleIcon} className="w-10" alt="icon-title"/>}
              text={
                <p className="text-lg font-bold text-primaryBlue_600">
                  {titleText}
                </p>
              }
            />
          )}
          {airportText !== "" && (
            <TextWithIcon
              gap={24}
              icon={<img src={"/images/ico_plane.svg"} className="w-8" alt="icon-plane"/>}
              text={<p className="text-base font-medium ">{airportText}</p>}
            />
          )}
          {details?.length > 0 &&
            details?.map((item, index) => (
              <TitleWithValue
                key={index}
                title={item?.title}
                value={item?.value}
              />
            ))}
          <hr className="w-full" />
          <TitleWithValue title={t("Payment Method")} value={t(methodText)} />
          <hr className="w-full" />
          <TitleWithValue
            title={t("Amount")}
            value={formattedPriceWithTextCurrency(
              parkingFeeCalculateData?.fee + parkingFeeCalculateData?.paid
            )}
          />
          {/* เพิ่มแสดงยอดชำระแล้ว */}
          <TitleWithValue
            title={t("Paid Amount")}
            value={formattedPriceWithTextCurrency(
              parkingFeeCalculateData?.paid
            )}
          />
          <TitleWithValue
            title={t("Payment Amount")}
            value={formattedPriceWithTextCurrency(parkingFeeCalculateData?.fee)}
            titleStyles="font-bold text-xl"
            valueStyles="text-red-500 font-bold text-xl"
          />
        </div>
        <BoxSemiCircle />
      </div>
      {footerExtra && footerExtra}
    </>
  );
};

export default PaymentDetailBoxPaidFee;
