import axios from "axios";
import {
  getPmlsIdFromStorage,
  getRefreshTokenFromStorage,
  getTokenFromStorage,
} from "../utils/localStorageUtil";
import { AIRPORT, PUBLIC_WEB_TOKEN } from "../constants";
import { v4 as uuidv4 } from "uuid";

const instance = axios.create({
  baseURL: "",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});

// Request Interceptor
instance.interceptors.request.use(
  async (config) => {
    const token = getTokenFromStorage() || PUBLIC_WEB_TOKEN;
    const pmlsId = getPmlsIdFromStorage() || AIRPORT.ID.SUVARNABHUMI;
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["X-Request-Id"] = String(pmlsId) + "-" + uuidv4();
    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response) {
      const { status, data } = error.response;

      if (status === 401 && !error.config._retry) {
        error.config._retry = true;

        try {
          const response = await refreshToken();
          const { accesstoken } = response.data;
          const user = JSON.parse(localStorage.getItem("pmsuser"));

          if (user) {
            const updatedUser = { ...user, accesstoken };
            localStorage.setItem("pmsuser", JSON.stringify(updatedUser));
          }

          return instance(error.config);
        } catch (_error) {
          handleRequestError(_error);
        }
      } else {
        handleRequestError(data || error);
      }
    } else {
      handleRequestError(error);
    }
  }
);

// Refresh Token Function
const refreshToken = () => {
  const refreshToken = getRefreshTokenFromStorage();
  const input = {};
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${refreshToken}`,
  };
  return axios.post("/sys/login/refreshtoken", input, { headers });
};

// Error Handling Function
const handleRequestError = (error) => {
  console.error("Error:", error);
  return Promise.reject(error);
};

export default instance;
