/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { INTERVAL_QR_QUERY, usePayment } from "../../../hooks/usePayment";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import MemberService from "../../../services/member-service";
import { toast } from "react-toastify";
import registerService from "../../../services/registerService";
import PaymentService from "../../../services/payment-service";
import dayjs from "dayjs";
import { DATE_FORMATS } from "../../../constants";
import ONLINE_PAYMENT_STATUS from "../../../constants/payment";
import LoadingSpinner from "../../LoadingSpinner";
import Form from "./Form";
import ConfirmPayment from "./ConfirmPayment";
import { getAirportNameFormPmlsAndLang, toastErr } from "../../../utils";
import { useTranslation } from "react-i18next";
import RenewSummary from "../RenewSummary";
import WaitPaymentStep from "../../Payment/WaitPaymentStep";
import { useLocation } from "react-router-dom";
import RenewStepper from "../RenewStepper";
import ContainerCenter from "../../../layouts/ContainerCenter";
import PageContent from "../../../layouts/PageContent";
import { Link, Typography, Breadcrumbs } from "@mui/material";

export const RENEW_STEP = {
  FORM: 1,
  CONFIRM_PAYMENT: 2,
  WAIT_PAYMENT: 3,
  COMPLETED_PAYMENT: 4,
};

const Index = () => {
  const { pathname } = useLocation();
  const {
    setCompletedDisplayData,
    completedDisplayData,
    method,
    onChangePaymentMethod,
  } = usePayment();
  const { title } = useSelector((state) => state.masterData);
  const { i18n, t } = useTranslation();
  const { memberInfo, memberVehicles, profile } = useSelector(
    (state) => state.auth
  );
  const { pmlsId } = useSelector((state) => state.airport);

  const isPagesRenew = ["/bkk/renew", "/dmk/renew"].includes(pathname);
  const [step, setStep] = useState(RENEW_STEP.FORM);
  const [selectedVehicle, setSelectedVehicle] = useState({
    vehicletypeid: "",
    licenseplate: "",
    licenseprovince: "",
  });
  const [dateDuration, setDataDuration] = useState({
    startDate: "",
    endDate: "",
  });
  const [numberOfMonths, setNumberOfMonths] = useState(1);
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  const memberPackageByMemberId = useQuery({
    queryKey: ["MEMBER_PACKAGE", pmlsId, memberInfo],
    queryFn: () =>
      MemberService.mmapMemberPackage({
        pmlsid: pmlsId,
        memberid: memberInfo?.pmlsmemberid,
      }),
    select: (data) => data?.data?.tbMemberPackage,
    enabled: pmlsId !== undefined && memberInfo !== undefined,
  });

  const memberRenewUpdateMutate = useMutation({
    mutationFn: (payload) => MemberService.mmapMemberRenewUpdate(payload),
    onSuccess: (response) => {
      if (response.ouIsComplete === 1) {
        setStep(RENEW_STEP.COMPLETED_PAYMENT);
      } else {
        toast.error(response?.ouMessage, {
          autoClose: 2000,
          position: "top-center",
        });
      }
    },
    onError: (response) => {
      toast.error(response?.ouMessage, {
        autoClose: 2000,
        position: "top-center",
      });
    },
  });

  useEffect(() => {
    if (memberVehicles?.length > 0) {
      const primaryVehicle = memberVehicles?.find(
        (item) => item.isprimary === 1
      );
      setSelectedVehicle({
        licenseplate: primaryVehicle?.licenseplate,
        vehicletypeid: primaryVehicle?.vehicletypeid,
        licenseprovince: primaryVehicle?.licenseprovince,
      });
    }
  }, [memberVehicles]);

  useEffect(() => {
    if (memberInfo?.memberpackage) {
      let _newStart = "";
      let _newEnd = "";
      const nowDate = dayjs();
      const packageEndDate = memberInfo?.memberpackage?.enddate;
      const monthsPassed = nowDate.diff(packageEndDate, "month");
      if (monthsPassed >= 1) {
        // console.log("More than 1 month has passed since the end date month.");
        _newStart = dayjs().startOf("month").format(DATE_FORMATS.DEFAULT);
        _newEnd = dayjs().endOf("month").format(DATE_FORMATS.DEFAULT);
      } else {
        // console.log("Less than 1 month has passed since the end date month.");
        _newStart = dayjs(packageEndDate)
          .add(1, "month")
          .startOf("month")
          .format(DATE_FORMATS.DEFAULT);
        _newEnd = dayjs(packageEndDate)
          .add(1, "month")
          .endOf("month")
          .format(DATE_FORMATS.DEFAULT);
      }

      setDataDuration({
        startDate: _newStart,
        endDate: _newEnd,
      });
    }
  }, [memberInfo]);

  const getPackageGroup = useQuery({
    queryKey: ["GET_PACKAGE_GROUP", memberInfo, pmlsId],
    queryFn: () => {
      if (!memberInfo?.memberpackage?.packagegroupid) {
        toastErr("memberpackage.packagegroupid not found!");
      }
      return registerService.getPackagegroup({
        pmlsid: pmlsId,
        packagegroupid: memberInfo?.memberpackage?.packagegroupid,
      });
    },
    enabled: memberInfo?.memberpackage !== undefined && pmlsId !== undefined,
    select: (data) => data?.data?.PackageGroup,
  });

  const titleName = useMemo(() => {
    return title?.find((item) => String(item.titleid) === memberInfo?.titleid)
      ?.title;
  }, [memberInfo, title]);

  const onChangeVehicle = (vehicletypeid) => {
    const findVehicle = memberVehicles.find(
      (vehicle) => vehicle.vehicletypeid === vehicletypeid
    );
    if (findVehicle) {
      setSelectedVehicle({
        licenseplate: findVehicle?.licenseplate,
        vehicletypeid: findVehicle?.vehicletypeid,
        licenseprovince: findVehicle?.licenseprovince,
      });
    }
  };

  const getTotalPackagePrice = useCallback(() => {
    if (getPackageGroup?.data?.items?.length > 0) {
      const total = getPackageGroup?.data?.items
        ?.filter((item) => item.chargetype === "002")
        .reduce((sum, item) => {
          return (
            sum +
            Number(item?.totalprice ? item?.totalprice * numberOfMonths : 0)
          );
        }, 0);
      return total;
    } else {
      return 0;
    }
  }, [getPackageGroup?.data, numberOfMonths]);

  // 2. create payment
  const onClickPaymentBtn = () => {
    try {
      const processIdFormatted = String(
        memberRenewUpdateMutate?.data?.data?.memberprocessid
      ).padStart(9, "0");
      const memberNo = memberInfo?.memberno;
      const currentDateFormatted = dayjs().format("DDMMYYYY");
      const paymentRef = processIdFormatted + memberNo + currentDateFormatted;
      const payload = {
        pmlsid: pmlsId,
        amount: getTotalPackagePrice(),
        paymentsource: "06", //WEB QR
        paymentref1: paymentRef,
      };
      createPaymentQR.mutate(payload);
    } catch (err) {
      toast.error(err, {
        autoClose: 2000,
        position: "top-center",
      });
    }
  };

  const createPaymentQR = useMutation({
    mutationFn: async (payload) =>
      PaymentService.mmapMemberCreateSecurePaymentQrCode(payload),
    onSuccess: (response) => {
      if (response.ouIsComplete === 1) {
        if (!response?.data?.onlinetransid) {
          toast.error("Error occurred onlinetransid not found!", {
            autoClose: 2000,
            position: "top-center",
          });
          setStep(RENEW_STEP);
          return;
        }
        setStep(RENEW_STEP.WAIT_PAYMENT);
      } else {
        toast.error(response?.ouMessage, {
          autoClose: 2000,
          position: "top-center",
        });
        setIsPaymentComplete(false);
      }
    },
    onError: (response) => {
      toast.error(response, {
        autoClose: 2000,
        position: "top-center",
      });
      setIsPaymentComplete(false);
    },
    select: (data) => data?.data,
  });

  // 3. query qr status
  const qrQueryStatus = useMutation({
    mutationFn: async (payload) => PaymentService.queryQRStatus(payload),
    onSuccess: async (response) => {
      try {
        const onlinePaymentData = response?.data?.tbonlinepayment;
        if (
          response.ouIsComplete === 1 &&
          onlinePaymentData?.responsestatus === "200" &&
          onlinePaymentData?.responsedt !== null
        ) {
          clearInterval(intervalId);
          setIsPaymentComplete(true);
          setCompletedDisplayData({
            updatedt: onlinePaymentData?.updatedt,
          });
          const payloadGetRenew = {
            pmlsid: pmlsId,
            memberid: memberInfo?.pmlsmemberid,
            processid: memberRenewUpdateMutate?.data?.data?.memberprocessid,
          };

          const responseGetMemberRenew = await getMemberRenew.mutateAsync(
            payloadGetRenew
          );
          if (responseGetMemberRenew.ouIsComplete === 1) {
            const memberRenewData = responseGetMemberRenew?.data?.Member;
            const memberRenewOrder = memberRenewData?.memberOrder[0];
            const orderItem = memberRenewOrder?.OrderItem[0];
            const payloadCreateReceiptRenewMember = {
              pmlsid: pmlsId,
              processid: memberRenewUpdateMutate?.data?.data?.memberprocessid,
              createby: profile?.userMenu?.userid, // --> userid
              transtype: "T03",
              paytype: "02",
              totalamount: getTotalPackagePrice(), //--ยอดรวม
              paysourcecode: "06",
              memberid: memberInfo?.pmlsmemberid,
              memberno: memberInfo?.memberno,
              remark: "", //ยังไม่ต้องใส่
              receipttype: "01",
              memberorderid: memberRenewOrder?.memberorderid, //renew--> memberorder --> memberorderid
              detail: [
                {
                  orderitemid: orderItem?.orderitemid, //renew -->items--> orderitemid
                  productname: orderItem?.packagename, // --> packagename
                  producttype: orderItem?.producttype,
                  productcode: orderItem?.productcode,
                  itemno: 1, // fixed 1
                  unitamount: orderItem?.unitprice, // renew --> items --> unitprice
                  qty: orderItem?.qty, // renew --> items -->  qty
                  itemdesc1: `${dayjs(orderItem?.startdate).format(
                    DATE_FORMATS.DEFAULT
                  )} ${dayjs(orderItem?.enddate).format(DATE_FORMATS.DEFAULT)}`, // startdate -  enddate
                  itemdesc2: "",
                  itemdesc3: "",
                },
              ],
              vatrate: 0,
              info1: "",
              info2: "",
            };
            createReceiptRenewMember.mutate(payloadCreateReceiptRenewMember);
          }
        } else if (onlinePaymentData?.responsestatus !== null) {
          clearInterval(intervalId);
          const paymentStatusDesc =
            ONLINE_PAYMENT_STATUS[Number(onlinePaymentData?.responsestatus)]
              ?.description;
          toast.error(paymentStatusDesc, {
            autoClose: 2000,
            position: "top-center",
          });
          setIsPaymentComplete(false);
          setStep(RENEW_STEP.FORM);
        }
      } catch (err) {
        toastErr(err);
      }
    },
    onError: (response) => {
      toastErr(response);
      clearInterval(intervalId);
      setIsPaymentComplete(false);
      setStep(RENEW_STEP.FORM);
    },
  });

  const getMemberRenew = useMutation({
    mutationFn: (params) => MemberService.mappGetMemberRenew(params),
  });

  const createReceiptRenewMember = useMutation({
    mutationFn: (payload) =>
      PaymentService.createReceiptForRenewMember(payload),
    onSuccess: (response) => {
      if (response.ouIsComplete === 1) {
        setStep(RENEW_STEP.COMPLETED_PAYMENT);
      } else {
        toastErr(response?.ouMessage);
      }
    },
    onError: (response) => {
      toastErr(response);
    },
  });

  // interval loop after createPaymentQR success && !isPaymentComplete
  // clear interval after return or qrQueryStatus completed
  useEffect(() => {
    let newIntervalId = null;
    if (createPaymentQR?.data?.ouIsComplete === 1 && !isPaymentComplete) {
      newIntervalId = setInterval(() => {
        const payloadQRQueryStatus = {
          pmlsid: pmlsId,
          onlinetransid: createPaymentQR?.data?.data?.onlinetransid,
        };
        qrQueryStatus.mutate(payloadQRQueryStatus);
      }, INTERVAL_QR_QUERY);
      setIntervalId(newIntervalId);
    }

    return () => {
      clearInterval(newIntervalId);
    };
  }, [createPaymentQR?.data, isPaymentComplete, pmlsId]);

  //1. Create renew member request at pmls
  const onClickUpdateButtonHandler = () => {
    const currentDate = dayjs().format(DATE_FORMATS.DEFAULT);
    const isFuture = currentDate < dateDuration.startDate ? 1 : 0;

    const packageGroupDataItems = getPackageGroup?.data?.items
      ?.filter((item) => item?.chargetype === "002")
      .map((item) => ({
        orderitemid: 0, // lock
        chargetype: "002", // "002"
        packageid: item.packageid,
        qty: numberOfMonths, // จำนวนเดือน
        unitprice: item.totalprice, //ราคาต่อเดือน
        totalprice: item.totalprice * numberOfMonths, // qty x unitprice
      }));

    const payload = {
      createsource: 3, // WEB = 3
      pmlsid: pmlsId,
      processid: 0, // lock
      memberid: memberInfo?.pmlsmemberid,
      memberorder: {
        memberorderid: memberInfo?.memberpackage?.orderid, //? BUG: 1481: https://dev.azure.com/mmats/AOT-CARPARK/_workitems/edit/1481/
        packagegroupid: memberInfo?.memberpackage?.packagegroupid,
        isfuture: isFuture, //ถ้าเป็นเวลาอนาคต = 1
        startdate: dateDuration.startDate,
        enddate: dateDuration.endDate,
        parkinggroupid: memberPackageByMemberId?.data?.parkinggroupid,
        vehicletypeid: selectedVehicle.vehicletypeid,
        items: packageGroupDataItems,
      },
    };
    memberRenewUpdateMutate.mutate(payload);
  };

  const disabledNextButtonStep1 = getPackageGroup?.isLoading;

  const loading =
    memberRenewUpdateMutate?.isLoading || createPaymentQR.isLoading;

  let elementRender = null;

  const previewBoxData = {
    airport: getAirportNameFormPmlsAndLang(pmlsId, i18n?.language),
    licenseplate: selectedVehicle?.licenseplate,
    numberOfMonths: numberOfMonths,
    startdate: dateDuration?.startDate,
    enddate: dateDuration?.endDate,
    paymentType: method,
    totalPrice: getTotalPackagePrice(),
  };

  const paymentDetails = [
    {
      title: t("License Plate"),
      value: selectedVehicle?.licenseplate,
    },
    {
      title: t("Renew Duration"),
      value: `${numberOfMonths} ${t("Month")}`,
    },
    {
      title: t("Apply Start Date"),
      value: dayjs(dateDuration?.startDate).format(DATE_FORMATS.FORMAT_01),
    },
    {
      title: t("Expired Date"),
      value: dayjs(dateDuration?.endDate).format(DATE_FORMATS.FORMAT_01),
    },
  ];

  if (step === RENEW_STEP.FORM) {
    elementRender = (
      <Form
        data={memberInfo}
        memberVehicles={memberVehicles}
        titleName={titleName}
        selectedVehicle={selectedVehicle}
        packageData={getPackageGroup?.data}
        dateDuration={dateDuration}
        numberOfMonths={numberOfMonths}
        disabled={disabledNextButtonStep1}
        setSelectedVehicle={setSelectedVehicle}
        setDataDuration={setDataDuration}
        setNumberOfMonths={setNumberOfMonths}
        onChangeVehicle={onChangeVehicle}
        getTotalPackagePrice={getTotalPackagePrice}
        onClickUpdateButtonHandler={onClickUpdateButtonHandler}
      />
    );
  } else if (step === RENEW_STEP.CONFIRM_PAYMENT) {
    elementRender = (
      <ConfirmPayment
        method={method}
        paymentDetails={paymentDetails}
        previewBoxData={previewBoxData}
        totalPrice={getTotalPackagePrice}
        onChangePaymentMethod={onChangePaymentMethod}
        onClickConfirmPaymentBtn={onClickPaymentBtn}
        onClickBackBtn={() => {
          setStep(RENEW_STEP.FORM);
        }}
      />
    );
  } else if (step === RENEW_STEP.WAIT_PAYMENT) {
    elementRender = (
      <WaitPaymentStep requestData={createPaymentQR?.data?.data} />
    );
  } else if (step === RENEW_STEP.COMPLETED_PAYMENT) {
    elementRender = (
      <RenewSummary
        responseData={createReceiptRenewMember?.data?.data}
        previewBoxData={previewBoxData}
        completedDisplayData={completedDisplayData}
      />
    );
  }

  return (
    <>
      {isPagesRenew && (
        <div className="bg-[#E9F0F5] pt-20 md:pt-[150px]">
          <ContainerCenter>
            <RenewStepper
              sxa={{
                transform: isPagesRenew
                  ? "translateY(-20px)"
                  : "translateY(30px)",
              }}
              step={step}
            />
          </ContainerCenter>
        </div>
      )}
      <PageContent
        title={t("Renew Membership")}
        icon={<img src="/images/renew/ico_renew_bl.svg" alt="icon-renew" />}
        breadcrumb={
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              {t("Home")}
            </Link>
            <Typography color="#245AB1">{t("Renew Membership")}</Typography>
          </Breadcrumbs>
        }
      >
        {!isPagesRenew && <RenewStepper step={step} />}
        {loading ? <LoadingSpinner /> : elementRender}
      </PageContent>
    </>
  );
};

export default Index;
