import React, { useState } from "react";
import { DatePickerAOT } from "../../components/Controls";
// import CheckingTable from "../../components/Checking/CheckingTable";
import { useQuery } from "@tanstack/react-query";
import MemberService from "../../services/member-service";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { DATE_FORMATS } from "../../constants";
import PaymentTable from "../../components/Payment/PaymentTable";
import { useTranslation } from "react-i18next";

const PaymentHistoryPage = () => {
  const { t } = useTranslation();
  const { memberInfo } = useSelector((state) => state.auth);
  const { pmlsId } = useSelector((state) => state.airport);
  const [filterValue, setFilterValue] = useState({
    startdt: "",
    enddt: "",
  });

  const memberReceiptHistory = useQuery({
    queryKey: ["GET_MEMBER_RECEIPT_HISTORY", filterValue],
    queryFn: () =>
      MemberService.mappMemberHistoryReceiptFilter({
        pmlsid: pmlsId,
        memberid: memberInfo?.pmlsmemberid,
        ...filterValue,
      }),
    select: (data) => data?.data?.receipt,
    enabled: pmlsId !== undefined && memberInfo?.pmlsmemberid !== undefined,
  });

  return (
    <div className="w-full p-20 text-[#2F2E2F]">
      <div className="flex justify-between items-center">
        <div>
          <div className="inline-flex items-center mb-4">
            <div className="inline-flex gap-2 items-center">
              <img src="/images/payment/ico_payment_head.svg" alt="ico_payment"/>
              <p className="text-3xl text-[#2F2E2F] font-bold">
                {t("Payment and Payment History")}
              </p>
            </div>
          </div>
        </div>
        <div className="text-sm">
          {t("Home")} /
          <span className={`${"text-[#245AB1] font-semibold"} `}>
            {" "}
            {t(["Payment"])}
          </span>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 mt-5 gap-5">
        <DatePickerAOT
          label={t("Start Date")}
          handleChange={(e) => {
            setFilterValue({
              ...filterValue,
              startdt: e !== null ? dayjs(e).format(DATE_FORMATS.DEFAULT) : "",
            });
          }}
          sxa={{}}
          val={filterValue?.startdt}
        />
        <DatePickerAOT
          label={t("End Date")}
          handleChange={(e) => {
            setFilterValue({
              ...filterValue,
              enddt: e !== null ? dayjs(e).format(DATE_FORMATS.DEFAULT) : "",
            });
          }}
          sxa={{}}
          val={filterValue?.enddt}
        />
      </div>

      <div>
        <PaymentTable
          data={memberReceiptHistory?.data}
          loading={memberReceiptHistory.isLoading}
        />
      </div>
    </div>
  );
};

export default PaymentHistoryPage;
