import instance from "../config/apiAxios";

const BASE_PATH = "/ms";

const fetchData = async (endpoint, params = {}) => {
  const response = await instance.get(`${BASE_PATH}/${endpoint}`, { params });
  return response.data;
};

const MasterService = {
  getAirports: (langid) => fetchData("airport", { langid }),
  getPMLS: () => fetchData("pmls"),
  getProvince: () => fetchData("province"),
  getDistrictByProvinceId: (provinceid) => fetchData("district", { provinceid }),
  getSubDistrict: (provinceid, districtid) =>
    fetchData("subdistrict", { provinceid, districtid }),
  getMembertype: () => fetchData("membertype"),
  getNationality: () => fetchData("nationality"),
  getTitle: () => fetchData("title"),
  getLicense: () => fetchData("license"),
  getVehicletype: (langid) => fetchData("vehicletype", { langid }),
  getOwnerVehicle: () => fetchData("ownervehicle"),
  getDoctype: () => fetchData("doctype"),
  getDoctypeGroup: () => fetchData("doctypegroup"),
  getProvinceLicense: () => fetchData("provincelicense"),
  getOfficeType: () => fetchData("office"),
  getCarBrand: () => fetchData("carbrand"),
  getPaymentType: () => fetchData("paymenttype"),
};

export const getAllMasterData = async () => {
  const [
    airports,
    pmls,
    membertype,
    nationality,
    title,
    license,
    vehicletype,
    ownerVehicle,
    doctype,
    doctypeGroup,
    provinceLicense,
    officeType,
    province,
    carBrand,
    paymentType,
  ] = await Promise.all([
    MasterService.getAirports(),
    MasterService.getPMLS(),
    MasterService.getMembertype(),
    MasterService.getNationality(),
    MasterService.getTitle(),
    MasterService.getLicense(),
    MasterService.getVehicletype(),
    MasterService.getOwnerVehicle(),
    MasterService.getDoctype(),
    MasterService.getDoctypeGroup(),
    MasterService.getProvinceLicense(),
    MasterService.getOfficeType(),
    MasterService.getProvince(),
    MasterService.getCarBrand(),
    MasterService.getPaymentType(),
  ]);

  return {
    airports,
    pmls,
    membertype,
    nationality,
    title,
    license,
    vehicletype,
    ownerVehicle,
    doctype,
    doctypeGroup,
    provinceLicense,
    officeType,
    province,
    carBrand,
    paymentType,
  };
};

export default MasterService;
