import { createTheme } from "@mui/material";
export const themeApp = createTheme({
  components: {
    // MuiFormControl: {
    //   styleOverrides: {
    //     root: ({ theme, error }) => ({
    //       backgroundColor: "#FFFFFF",
    //       borderRadius: theme.spacing(1.5),
    //       border: `2px solid ${error ? "red" : "#3D9AEE"}`,
    //       overflow: "hidden",
    //     }),
    //   },
    // },
    MuiSelect: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: "100%",
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          border: "2px solid #3D9AEE",
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: "red" },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            borderRadius: "10px",
            backgroundColor: "#FFFFFF", // Default input background
            border: "2px solid #3D9AEE",
          },
          "& .MuiInputBase-input": {
            // color: "#245AB1", // Input text color
          },
          "&.Mui-disabled .MuiInputBase-root": {
            backgroundColor: "#e0e0e0", // Disabled input background
          },
          "&.Mui-disabled .MuiInputAdornment-root": {
            backgroundColor: "#e0e0e0", // Adornment background when disabled
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#3D9AEE", // Selected date background
            color: "#fffff", // Selected date text color
          },
          "&:hover": {
            backgroundColor: "#3D9AEE33", // Hover background
          },
          "&.Mui-disabled": {
            backgroundColor: "#e0e0e0", // Disabled day background color
            color: "rgba(0, 0, 0, 0.5)", // Disabled day text color
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent", // Ensure transparent background by default
          color: "#3D9AEE", // Default icon color
          "&.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.5)", // Disabled icon color
            backgroundColor: "transparent", // Ensure no background for disabled icons
          },
        },
      },
    },
    // MuiButton: {
    //   styleOverrides: {
    //     root: ({ theme, size, variant }) => {
    //       const baseStyles = {
    //         width: "100%",
    //         height: theme.spacing(
    //           size === "small" ? 5 : size === "medium" ? 7 : 9
    //         ),
    //         borderRadius: theme.spacing(1.5),
    //         "&.Mui-disabled": {
    //           background:
    //             variant === "primary" ? Colors.disabledButton : "#E9F0F5",
    //           border: "none",
    //           cursor: "not-allowed",
    //           pointerEvents: "auto",
    //         },
    //       };

    //       if (variant === "primary") {
    //         return {
    //           ...baseStyles,
    //           background: `transparent linear-gradient(90deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box`,
    //         };
    //       } else if (variant === "secondary") {
    //         return {
    //           ...baseStyles,
    //           background: "#E9F0F5",
    //         };
    //       } else if (variant === "orange") {
    //         return {
    //           ...baseStyles,
    //           background: `transparent linear-gradient(90deg, #ff975b 0%, #ff8844 100%) 0% 0% no-repeat padding-box`,
    //         };
    //       } else {
    //         return baseStyles;
    //       }
    //     },
    //   },
    // },
  },
  typography: {
    fontFamily: ["Noto Sans Thai", "Helvetica Neue", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#3859CB",
    },
  },
});
