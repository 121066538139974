import React from "react";
import { Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Colors } from "../../constants/colors";

const ButtonAction = ({
  onClick,
  disabled,
  children,
  icon,
  type = "button",
  size = "medium",
  variant = "primary",
  sx,
  textSx,
}) => {
  const theme = useTheme();

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      type={type}
      size={size}
      variant={variant}
      sx={{
        width: "100%",
        height: theme.spacing(size === "small" ? 5 : size === "medium" ? 7 : 9),
        borderRadius: theme.spacing(1.5),
        background:
          variant === "primary"
            ? `transparent linear-gradient(90deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box`
            : variant === "secondary"
            ? "#E9F0F5"
            : variant === "orange"
            ? `transparent linear-gradient(90deg, #ff975b 0%, #ff8844 100%) 0% 0% no-repeat padding-box`
            : "transparent",
        "&.Mui-disabled": {
          background: variant === "primary" ? Colors.disabledButton : "#E9F0F5",
          border: "none",
          cursor: "not-allowed",
          pointerEvents: "auto",
        },
        gap: 2,
        ...sx,
      }}
    >
      {icon && icon}
      <Typography
        sx={{
          font: "normal normal normal 18px/25px Noto Sans Thai",
          letterSpacing: "0px",
          color:
            variant === "primary"
              ? "#FFFFFF"
              : variant === "secondary"
              ? "#383838"
              : "#000000",
          [theme.breakpoints.down("lg")]: {
            font: "normal normal normal 16px/25px Noto Sans Thai",
          },
          ...textSx,
        }}
      >
        {children}
      </Typography>
    </Button>
  );
};

export default ButtonAction;
