import React from "react";
import { AIRPORT } from "../constants";

const LogoAotWithAirportText = ({ pmlsid }) => {
  return (
    <div className="relative w-44">
      <img src="/images/aot_logo.png" alt="" className="w-[180px] h-auto" />
      <div className="absolute -right-10 -bottom-2">
        <span className="text-sm font-bold">
          {['1', '2'].includes(String(pmlsid)) && AIRPORT.TEXT_BY_ID[pmlsid]
            ? AIRPORT.TEXT_BY_ID[pmlsid].th
            : ""}
        </span>
      </div>
    </div>
  );
};

export default LogoAotWithAirportText;
