import { Alert, Box, Snackbar, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import BoxCondition from "../../components/Register/BoxCondition";
import RegisterHeader from "../../components/Register/RegisterHeader";
import RegisterParkingStep1 from "../../components/Register/RegisterParkingStep1";
import RegisterParkingStep2 from "../../components/Register/RegisterParkingStep2";
import RegisterParkingStep3 from "../../components/Register/RegisterParkingStep3";
import RegisterParkingSummary from "../../components/Register/RegisterParkingSummary";
import RegisterStepper from "../../components/Register/RegisterStepper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import registerService from "../../services/registerService";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import userService from "../../services/userService";
import { useTranslation } from "react-i18next";
import { isThaiNationality } from "../../utils";
import { toast } from "react-toastify";

const BoxGreyRegister = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "11.5rem",
  background: "#E9F0F5 0% 0% no-repeat padding-box",
  paddingTop: "2rem",
  paddingLeft: "9rem",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "1rem",
  },
}));

const ImgRegCar = styled((props) => <Box component="img" {...props} />)(() => ({
  width: "3.6rem",
  height: "2.6rem",
  marginRight: "2rem",
}));

const initialValues = {
  lang: "th",
  memberid: 0,
  membertype: "",
  memberno: "",
  Idcard: "",
  passport: "",
  nationality: { id: "TH", display: "Thai" },
  birthdate: "",
  gender: "",
  title: "",
  firstname: "",
  middlename: "",
  lastname: "",
  identitytype: "",
  affiliation: "",
  agency: "",
  telOffice: "",
  tel: "",
  identityno: "",
  email: "",
  province: "",
  district: "",
  subdistrict: "",
  road: "",
  bypath: "",
  building: "",
  houseno: "",
  stickerno: "",
  cardno: "",
  refno: "",
  branchno: "",
  note: "",
  vehicletype: "",
  vehicletypeid: 1,
  parkingGroup: "",
  packageGroup: "",
  dateStart: "",
  dateExpiration: "",
  carbrand: "",
  doctype: "",
  issentmail: 0,
  taxname: "",
  postcode: "",
  switchtaxtype: "1",
  receiptaddr1: "",
  receiptaddr2: "",
  receiptaddr3: "",
  receiptaddr4: "",
  memberorder: {
    packagegroupid: 0,
    isfuture: 0,
    startdate: null,
    enddate: null,
    parkinggroupid: null,
    items: [],
  },
  v_membervehicleid: 0,
  v_licenseplate: "",
  v_licenseby: 0,
  v_provincename: "",
  v_vehiclemodel: "",
  v_vehicletypeid: 0,
  v_brand: "",
  v_brandname: "",
  v_vehiclecolor: "",
  v_licensetypeid: 0,
  v_remark: "",
  v_status: "Active",
  v_isprimary: 0,
  v_startdate: "",
  v_enddate: "",
  v_approveddt: "",
  v_approveid: 0,
  v_vehicleowner: "",
  v_vehicleowner_identity: "",
  v_createby: 0,
  doc: [],
  d_isapproval: 0,
  d_ispaperless: 1,
  d_isverifydoc: 0,
  d_docverifyby: 0,
  d_docverifybyname: "",
  d_createby: 0,
  d_approver: 0,
  d_remark: "",
};

const validationSchemaStep1 = Yup.object().shape({
  // Idcard: Yup.string()
  //   .required("ID card is required")
  //   .length(13, "ID card is invalid.")
  //   .matches(/^\d+$/, "Must contain only numbers"),
  gender: Yup.string().required("Gender is required"),
  firstname: Yup.string().required("Firstname is required"),
  lastname: Yup.string().required("Lastname is required"),
  affiliation: Yup.string().required(),
  telOffice: Yup.string()
    .required("Tel office is required")
    .matches(/^\d+$/, "Must contain only numbers"),
  email: Yup.string(),
  houseno: Yup.string().required("House no. is required"),
  officetype: Yup.object().shape({}).required("Office type is required"),
  nationality: Yup.object().shape({}).required("Nationality is required"),
});

const validationSchemaStep2 = Yup.object({
  v_licenseplate: Yup.string()
    .required("License plate is required")
    .matches(
      /^[a-zA-Zก-ฮ-0-9]+$/,
      "License plate must contain only letters, numbers, and Thai consonants"
    ),
});

const validationSchemaStep3 = Yup.object({});

const RegisterParking = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const { pmlsId } = useSelector((state) => state.airport);
  const { loading: loadingMasterData, nationality } = useSelector(
    (state) => state.masterData
  );
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialValues);
  const [activeStep, setActiveStep] = useState(1);

  const [processID, setProcesID] = useState(0);
  const [open, setOpen] = React.useState(false);
  const queryParams = new URLSearchParams(location.search);
  const currentAirportPmlsId = queryParams.get("pmlsId");

  useEffect(() => {
    if (!currentAirportPmlsId) {
      navigate("/");
    }
  }, [currentAirportPmlsId, user, navigate]);

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    navigate("/");
  };

  const formikStep1 = useFormik({
    isInitialValid: false,
    enableReinitialize: true,
    initialValues: formData,
    validateOnChange: true,
    validate: (values) => {
      const errors = {};
      const { issentmail, email, Idcard, nationality, passport } = values;
      if (
        issentmail === 1 &&
        (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
      ) {
        errors.email = "Invalid email address";
      }
      if (isThaiNationality(nationality?.id)) {
        if (!Idcard) {
          errors.Idcard = "ID card is required";
        } else if (!/^\d{13}$/.test(Idcard)) {
          errors.Idcard =
            Idcard.length !== 13
              ? "ID card must be exactly 13 digits long"
              : "Must contain only numbers";
        }
      } else {
        if (!passport) {
          errors.passport = "Passport is required";
        } else if (!passport || !/^[a-zA-Z0-9]{5,10}$/.test(passport)) {
          errors.passport = "Invalid passport number";
        }
      }
      return errors;
    },
    validationSchema: validationSchemaStep1,
    onSubmit: async (values) => {
      const data = {
        pmlsid: pmlsId,
        createsource: 3,
        membermasterid: null,
        webaccountid: "",
        membertypeid: 1, //lock ค่าเป็น member ไปเลย
        titleid: values?.title,
        fname: values.firstname,
        mname: values.middlename,
        lname: values.lastname,
        identityno:
          values?.nationality?.id === "TH" ? values.Idcard : values.passport,
        gender: values.gender,
        nationalitycode: values?.nationality?.id,
        identitytype: values?.nationality?.id === "TH" ? 1 : 2, //? id card = 1, passport = 2
        birthdate: values.birthdate,
        houseno: values.houseno,
        building: values.building,
        road: values.road,
        bypath: values.bypath,
        subdistrict: values.subdistrict,
        district: values.district,
        province: values.province,
        postcode: values.postcode,
        email: values.email,
        phone: values.telOffice,
        status: "Register",
        mobile: values.mobile,
        office: values.affiliation, //สังกัด
        branchno: values.branchno,
        officetype: values.officetype?.id, //หน่วยงาน
        refno: values.refno, //เลขที่อ้างอิง
        stickerno: values.stickerno,
        ismigrate: 0,
        deposit: 0,
        //ถ้าเลือกเบิกได้ให้ส่งค่า  receiptaddr1 -> receiptaddr4 : ไม่ได้ส่ง ""
        // receiptaddr1: values.switchtaxtype == 1 ? values.receiptaddr1 : "",
        // receiptaddr2: values.switchtaxtype == 1 ? values.receiptaddr2 : "",
        // receiptaddr3: values.switchtaxtype == 1 ? values.receiptaddr3 : "",
        // receiptaddr4: values.switchtaxtype == 1 ? values.receiptaddr4 : "",
        receiptaddr1: values.receiptaddr1,
        receiptaddr2: values.receiptaddr2,
        receiptaddr3: values.receiptaddr3,
        receiptaddr4: values.receiptaddr4,
        iswthdraw: 0,
        issentmail: values.issentmail, //รับใบกำกับภาษีผ่านอีเมล
        taxbranchno: values.taxbranchno, // สาขา
        taxid: values.taxid, //เลขประจำตัวผู้เสียภาษี ?
        taxname: values.taxname,
        memberorder: {
          vehicletypeid: values.vehicletypeid,
          packagegroupid: values.packageGroup,
          isfuture: 0,
          startdate: values.dateStart + " 00:00:00",
          enddate: values.dateExpiration + " 23:59:59",
          parkinggroupid: values.parkingGroup,
          items: values.memberorder?.items?.map((m) => ({
            chargetype: m.chargetype,
            packageid: m.packageid,
            qty: m.qty,
            unitprice: m.totalprice ? Number(m.totalprice) : 0,
            totalprice: m.totalprice ? Number(m.totalprice) * m.qty : 0,
          })),
        },
        personalremark: values.personalremark,
      };

      try {
        const resValidateIdCardNo = await registerService.validateIdentityNo(
          Number(pmlsId),
          values?.nationality?.id === "TH" ? values.Idcard : values.passport
        );

        if (resValidateIdCardNo.ouIsComplete === 1) {
          if (resValidateIdCardNo.data?.isValidId) {
            // can't register parking
            toast.warning(
              "ไม่สามารถสมัครสมาชิกลานจอดได้ เนื่องจากมีสมาชิกในระบบแล้ว",
              {
                position: "top-center",
                autoClose: 1000,
              }
            );
          } else {
            const res = await registerService.registerMemberStep1(data);
            if (res.ouIsComplete === 1) {
              if (values.memberid === 0) {
                setFormData({
                  ...values,
                  memberid: res.data?.memberid ? Number(res.data.memberid) : 0,
                  memberno: res.data.memberno,
                  processid: res.data.processid
                    ? Number(res.data.processid)
                    : 0,
                  memberorder: {
                    ...values.memberorder,
                    memberorderid: res.data.memberorder
                      ? res.data.memberorder
                      : 0,
                    //items: xItm
                  },
                });
                setProcesID(
                  res.data.processid ? Number(res.data.processid) : 0
                );
              } else {
                setFormData({ ...values });
              }
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
              toast.error(res.ouMessage, {
                position: "top-center",
                autoClose: 1000,
              });
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const formikStep2 = useFormik({
    enableReinitialize: true,
    initialValues: formData,
    onSubmit: async (values) => {
      const data = {
        pmlsid: pmlsId,
        processid: values.processid ? values.processid : processID,
        membervehicleid: 0,
        memberid: values.memberid,
        licenseplate: values.v_licenseplate,
        licenseby: values.v_licenseby,
        licenseprovince: values.v_provincename,
        vehiclemodel: values.v_vehiclemodel,
        vehicletypeid: formData.vehicletypeid,
        isprimary: 1,
        brand: values.v_brand,
        vehiclecolor: values.v_vehiclecolor,
        licensetypeid: values.v_licensetypeid,
        status: values.v_status, // ส่งอะไรไป
        startdate: null, // ส่งอะไรไป
        enddate: null, // ส่งอะไรไป
        vehicleowner: values.v_vehicleowner,
        vehicleowner_identity: values.v_vehicleowner_identity,
        vechicleremark: values.vechicleremark,
      };
      try {
        const res = await registerService.registerMemberStep2(data);
        if (res.ouIsComplete === 1) {
          if (values.v_membervehicleid === 0) {
            setFormData({
              ...values,
              membervehicleid: res.data?.membervehicleid
                ? res.data.membervehicleid
                : 0,
            });
          } else {
            setFormData({ ...values });
          }
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          toast.error(res.ouMessage, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } catch (error) {}
    },
    validationSchema: validationSchemaStep2,
  });

  const formikStep3 = useFormik({
    enableReinitialize: true,
    initialValues: formData,
    onSubmit: async (values) => {
      if (window.confirm("ยืนยันสมัครสมาชิกใช้ลานจอด?")) {
        const data = {
          pmlsid: pmlsId,
          processid: values.processid ? values.processid : processID,
          doc: values.doc.map((m) => ({
            docid: m.docid,
            memberid: m.memberid,
            doctypeid: m.doctypeid, //Fix map
            docname: m.docname,
            docsize: m.docsize,
            docext: m.docext,
            doctypegroupid: m.doctypeid, // เดิม fix  การสมัครสมาชิกใหม่ (1) ** 1395 ให้แก้เป็น ตาม doctypegroupid
            docgroupid: m.docgroupid,
            status: m.status,
            data_base64: m.data_base64,
          })),
          isapproval: values.d_isapproval,
          ispaperless: values.d_ispaperless,
          isverifydoc: values.d_isverifydoc,
          docverifyby: values.d_docverifyby,
          createby: values.d_createby,
          approver: values.d_approver,
          docremark: values.d_remark,
        };
        try {
          const res = await registerService.registerMemberStep3(data);
          if (res.ouIsComplete === 1) {
            if (!formData.memberid) return;
            await registerService
              .requestMappingPmlsMember({
                lang: "th",
                memberid: formData.memberid,
                identityno:
                  formData?.nationality?.id === "TH"
                    ? formData.Idcard
                    : formData.passport,
                pmlsid: pmlsId,
              })
              .then(async (response) => {
                if (response.ouIsComplete === 1) {
                  await registerService
                    .confirmMappingPmlsMember({
                      lang: "th",
                      memberid: formData.memberid,
                      identityno:
                        formData?.nationality?.id === "TH"
                          ? formData.Idcard
                          : formData.passport,
                      pmlsid: pmlsId,
                    })
                    .then((response) => {
                      if (response.ouIsComplete === 1) {
                        setFormData({ ...values });
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                      } else {
                        toast.error(
                          "ลงทะเบียนไม่สำเร็จ โปรดติดต่อเจ้าหน้าที่ !",
                          {
                            position: "top-center",
                            autoClose: 1000,
                          }
                        );
                        navigate("/");
                      }
                    })
                    .catch((error) => {
                      toast.error("Something went wrong!", {
                        position: "top-center",
                        autoClose: 1000,
                      });
                    });
                }
              })
              .catch((error) => {
                toast.error("Something went wrong!", {
                  position: "top-center",
                  autoClose: 1000,
                });
              });
          } else {
            toast.error(res.ouMessage, {
              position: "top-center",
              autoClose: 1000,
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    validationSchema: validationSchemaStep3,
  });

  useQuery({
    queryKey: ["GET_USER_INFO", user],
    queryFn: () =>
      userService.getSysUserInformation({ username: user?.username }),
    enabled: user?.username !== undefined,
    select: (data) => data,
    onSuccess: (response) => {
      if (response.ouIsComplete === 1) {
        const data = response?.data?.userProfile;
        const _nationality =
          nationality
            ?.filter((item) => item.code === data?.nationality)
            .map((item) => ({
              id: item.code,
              display: item.nationality,
            })) || [];
        formikStep1.setValues({
          ...formikStep1.values,
          mobile: data?.mobile,
          birthdate: data?.birthdate,
          nationality: _nationality[0] || initialValues.nationality,
          gender: data?.gender,
          title: data?.titleid,
          email: data?.email,
          firstname: data?.firstname,
          lastname: data?.lastname,
        });
      }
    },
  });

  //? for set middlename only  || BUG:1511 3. ชื่อกลาง >> ดึงค่าจาก /mapp/account/myprofile ที่ field middlename มาแสดง และปรับเป็น gray field
  useQuery({
    queryKey: ["GET_MYPROFILE", user],
    queryFn: () => userService.myprofile(),
    enabled: user?.username !== undefined,
    select: (data) => data,
    onSuccess: (response) => {
      if (response.ouIsComplete === 1) {
        formikStep1.setValues({
          ...formikStep1.values,
          middlename: response?.data?.userMenu?.middlename,
        });
      }
    },
  });

  return (
    <>
      <RegisterHeader />
      {activeStep === 1 ? (
        <div className="pt-10 px-10 mx-auto container">
          <BoxCondition
            setActiveStep={setActiveStep}
            loading={loadingMasterData}
          />
        </div>
      ) : activeStep <= 4 ? (
        <>
          <BoxGreyRegister>
            <div className="px-10 flex gap-2">
              <ImgRegCar src="/images/auth/ico_name_carpark.svg" />
              <Typography
                sx={{
                  font: "normal normal 600 20px/30px Noto Sans Thai",
                  letterSpacing: "0px",
                  color: "#2F2E2F",
                }}
              >
                {t("Apply for Parking Membership")}
              </Typography>
            </div>
          </BoxGreyRegister>
          <div className="mx-auto container px-10">
            <RegisterStepper
              sxa={{ transform: "translateY(-40px)" }}
              activeStep={activeStep}
            />
          </div>
        </>
      ) : null}
      <div className="mx-auto container px-10 py-10">
        {activeStep === 2 && (
          <RegisterParkingStep1
            setActiveStep={setActiveStep}
            formik={formikStep1}
            currentAirportPmlsId={currentAirportPmlsId}
          />
        )}
        {activeStep === 3 && (
          <RegisterParkingStep2
            setActiveStep={setActiveStep}
            formik={formikStep2}
            currentAirportPmlsId={currentAirportPmlsId}
          />
        )}{" "}
        {activeStep === 4 && (
          <RegisterParkingStep3
            setActiveStep={setActiveStep}
            formik={formikStep3}
            currentAirportPmlsId={currentAirportPmlsId}
          />
        )}{" "}
        {activeStep === 5 && <RegisterParkingSummary formik={formikStep3} />}
      </div>
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ width: "35%" }}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{
            width: "100%",
            font: "normal normal normal 22px/33px Noto Sans Thai",
            alignItems: "center",
          }}
        >
          ผู้ใช้งานได้สมัครลานจอดรถแล้ว
        </Alert>
      </Snackbar>
    </>
  );
};

export default RegisterParking;
