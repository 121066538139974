import React from "react";

const ResultWithContent = ({ status, content, image }) => {
  return (
    <div className="px-4 py-16 md:p-16 text-center flex flex-col items-center gap-11 w-full">
      <img
        src={
          image
            ? image
            : `/images/status/${
                status === "SUCCESS" ? "success" : "error"
              }_result.png`
        }
        className="w-[200px] h-auto"
        alt="img-result"
      />
      {content && content}
    </div>
  );
};

export default ResultWithContent;
