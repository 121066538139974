import { Box, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputAOT } from "./InputAOT";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const DropdownAOT = ({
  label,
  width,
  data,
  val,
  setVal = null,
  sxa = {},
  sxb,
  handleChange,
  name,
  size,
  required,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [display, setDisplay] = useState(
    data?.find((x) => x.id === val)?.id || ""
  );

  const handleClick = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleSelectAndClose = (e) => {
    setDisplay(e.display);
    if (setVal) {
      setVal(e.id);
    }
    setIsOpen(false);
    handleChange(e);
  };
  useEffect(() => {
    if (val === "") {
      setDisplay("");
    }
  }, [val]);
  return (
    <Box sx={{ position: "relative" }} onMouseLeave={handleClose}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          // border: "2px solid #3D9AEE",
          height: "64px",
          width,
          ...sxa,
        }}
        // onChange={handleChange}
      >
        <InputAOT
          sx={{
            flex: "1 1 auto",
            "& .MuiInputBase-root": {
              borderRadius: "10px",
              border: "none",
            },
          }}
          label={label}
          variant="filled"
          value={display}
          name={name}
          InputProps={{ disableUnderline: true }}
          onClick={handleClick}
          size={size}
          required={required}
        />
        <Box
          sx={{
            cursor: "pointer",
            paddingX: "10px",
            margin: 0,
            display: "flex",
          }}
        >
          <KeyboardArrowDownIcon
            alt=""
            onClick={handleClick}
            style={{ width: "26px" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#fafafa",
          width: "100%",
          // height: "200px",
          display: isOpen ? "block" : "none",
          position: "absolute",
          zIndex: "4000",
          overflowY: "auto",
          ...sxb,
        }}
        onMouseLeave={handleClose}
        // onChange={handleChange}
      >
        {data &&
          data.map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  handleSelectAndClose(item);
                }}
                disableRipple
              >
                {item.display}
              </MenuItem>
            );
          })}
      </Box>
    </Box>
  );
};

export default DropdownAOT;
