import { InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import FormControl from "@mui/material/FormControl";

const NewSelectAOT = ({
  options,
  value = {},
  onChange,
  label,
  name,
  disabled,
  loading,
  icon,
  fullWidth = false,
  error,
  sxa,
  required,
  size,
}) => {
  return (
    <FormControl
      variant="filled"
      // sx={{
      //   backgroundColor: "#FFFFFF",
      //   borderRadius: "10px",
      //   border: `2px solid ${error ? "red" : "#3D9AEE"}`,
      //   overflow: "hidden",
      //   ...sxa,
      // }}
      fullWidth={fullWidth}
    >
      <InputLabel
        id="demo-simple-select-filled-label"
        sx={{ overflow: "hidden", height: "100%" }}
        required={required}
      >
        {label}
      </InputLabel>
      <Select
        disableUnderline
        name={name}
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        // sx={{
        //   height: "100%",
        //   backgroundColor: "#FFFFFF",
        // }}
        IconComponent={icon}
        value={value || ""}
        onChange={onChange}
        size={size}
        disabled={disabled}
      >
        {loading && (
          <MenuItem value="">
            <em>Loading...</em>
          </MenuItem>
        )}
        {options?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.display}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default NewSelectAOT;
