import { styled, TextField } from "@mui/material";

export const InputAOT = styled(TextField)(({ theme }) => ({
  // "& .MuiInputLabel-root": { color: "#2F2E2F" },
  // "& .MuiFilledInput-root": {
  //   backgroundColor: "transparent",
  // },
  // "& .MuiFilledInput-root:focused": {
  //   backgroundColor: "transparent",
  // },
  // "& .Mui-disabled": {
  //   backgroundColor: "#D1D1D1", // This is the darker gray.
  // },
  // "& .Mui-disabled .MuiFilledInput-adornedEnd": {
  //   backgroundColor: "#D1D1D1", // This is the darker gray for the end adornment.
  // },
}));
