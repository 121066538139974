/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import ButtonAction from "../../Base/ButtonAction";
import * as Yup from "yup";
import Form from "./Form";
import DisplayInformationBox from "./DisplayInformationBox";
import { useMutation } from "@tanstack/react-query";
import RenewByLicensePlateService from "../../../services/renewby-licenseplate-service";
import { toast } from "react-toastify";
import { DATE_FORMATS, PROCESS, REGEX } from "../../../constants";
import registerService from "../../../services/registerService";
import dayjs from "dayjs";
import TablePackageDetail from "../../Base/TablePackageDetail";
import {
  NUMBER_OF_MONTH_RENEW_BY_LICENSE_PLATE,
  getTotalPackagePriceByNumberOfMonth,
} from "../utils";
import {
  INTERVAL_QR_QUERY,
  PROCESS_PAYMENT_STATUS,
  STATUS_PAYMENT,
  usePayment,
} from "../../../hooks/usePayment";
import { ChoosePaymentMethodStep, WaitPaymentStep } from "../../Payment";
import PaymentService from "../../../services/payment-service";
import ONLINE_PAYMENT_STATUS from "../../../constants/payment";
import {
  getAirportNameFormPmlsAndLang,
  getLangIdFormPMLSlang,
  getLicensePlateProvinceText,
  successRequest,
  toastErr,
  toastWarning,
} from "../../../utils";
import RenewSummary from "../RenewSummary";
import LoadingSpinner from "../../LoadingSpinner";
import MemberService from "../../../services/member-service";
import PageContent from "../../../layouts/PageContent";
import ContainerCenter from "../../../layouts/ContainerCenter";
import RenewStepper from "../RenewStepper";
import { STEP_FEE_PARKING } from "../../../pages/FeeParking";
import {
  generateProvinceLicenseOptions,
  generateVehicleTypeOptions,
} from "../../../utils/optionsUtils";
import PaymentExpired from "../../Payment/PaymentExpired";
import ConfigService from "../../../services/config-service";
import ParkingService from "../../../services/parking-service";
import useOnlineConfig, {
  isOnlineConfigStatusClose,
} from "../../../hooks/useOnlineConfig";
import ModalClosePaymentRenew from "../../Modal/ModalClosePaymentRenew";
import useModal from "../../../hooks/useModal";

const formikInitValues = {
  licensePlate: "",
  // province: {
  //   id: null,
  //   display: null,
  // },
  province: null,
  vehicleType: null,
  // vehicleType: {
  //   id: null,
  //   display: null,
  // },
};

const validationSchema = Yup.object().shape({
  licensePlate: Yup.string()
    .matches(REGEX.LICENSE_PLATE, "Only letters and numbers are allowed")
    .required("License Plate is required"),
  province: Yup.object().shape({}).required("Province is required"),
  vehicleType: Yup.object().shape({}).required("Vehicle Type is required"),
});

function findLatestRecordWithProcessType(
  records,
  processtypecode,
  selectedVehicleId
) {
  // Filter records based on processtypecode
  const filteredRecords = records.filter(
    (record) =>
      record.processtypecode === processtypecode &&
      record.vehicletypeid === selectedVehicleId
  );

  // If there are no matching records, return null
  if (filteredRecords.length === 0) {
    return null;
  }

  // Initialize variables to hold the latest date and the corresponding record
  let latestDate = null;
  let latestRecord = null;

  // Loop through each filtered record
  filteredRecords.forEach((record) => {
    // Extract the created date from the record
    const createdDate = new Date(record.createdt);

    // Check if the created date is later than the current latest date
    if (!latestDate || createdDate > latestDate) {
      // If so, update the latest date and corresponding record
      latestDate = createdDate;
      latestRecord = record;
    }
  });

  // Return the record with the latest created date
  return latestRecord;
}

const RENEW_STEP = {
  FORM: 1,
  SHOW_DETAIL: 2,
  CONFIRM_PAYMENT: 3,
  WAIT_PAYMENT: 4,
  COMPLETED_PAYMENT: 5,
  BANK_EXPIREDT: 6,
};

/**
 * Check if the given end date is before the current month.
 *
 * @param {Date} endDate - The end date to compare
 * @return {boolean} true if expired, false if not expired
 */
export const renewExpiredtIsBeforeCurrentMonth = (endDate) => {
  const currentDate = dayjs();
  const endDateTime = dayjs(endDate);

  // Compare years first
  if (endDateTime.year() < currentDate.year()) {
    return true; // Expired if the end year is less than the current year
  } else if (endDateTime.year() === currentDate.year()) {
    // If years are equal, compare months
    return endDateTime.month() < currentDate.month();
  } else {
    return false; // Not expired if the end year is greater than the current year
  }
};

const CONFIG_GLOBAL = {
  monthly: "TRUE",
  effective_date: "FALSE",
};

const isEffectiveDateConfig = (config) =>
  config === CONFIG_GLOBAL.effective_date;

const isSelectCurrentMonth = (selectRenewMonth) => selectRenewMonth?.id === "1";

const Index = () => {
  const { t, i18n } = useTranslation();
  const { search: searchLocation } = useLocation();
  const { pmlsId } = useSelector((state) => state.airport);
  const { vehicletype, provincelicense } = useSelector(
    (state) => state.masterData
  );

  const { getOnlineConfig, onlineConfigData, isCloseApp } = useOnlineConfig();

  const {
    isOpen: isOpenModalClosePayment,
    openModal: openModalClosePayment,
    closeModal: closeModalClosePayment,
  } = useModal();

  const {
    method,
    paymentStatus,
    completedDisplayData,
    onlinetransid,
    onChangePaymentMethod,
    onChangeIntervalId,
    onChangePaymentStatus,
    onSuccessPaymentHandler,
    onFailedPaymentHandler,
    setCompletedDisplayData,
    onExpiredPaymentHandler,
    setOnlinetransid,
  } = usePayment();

  const [step, setStep] = useState(RENEW_STEP.CONFIRM_PAYMENT);
  const [isPendingPayment, setIsPendingPayment] = useState(false); // รายการการชำระเงินที่ค้างไว้
  const [selectRenewMonth, setSelectRenewMonth] = useState(undefined);
  const [renewWithMonthlyCap, setRenewWithMonthlyCap] = useState(false);

  const [configGlobal, setConfigGlobal] = useState(CONFIG_GLOBAL.monthly);

  // tax invoice
  const [isReceiveTaxInvoice, setIsReceiveTaxInvoice] = useState(null); // "1" = รับใบกํากับภาษี , "0" = ไม่รับ, default = null
  const [taxInvoiceReceiveDt, setTaxInvoiceReceiveDt] = useState(null);
  const [isErrorDateTaxReceive, setIsErrorDateTaxReceive] = useState(null); //กรณีเลือกกรอกวันที่ไม่สามารถเลือกได้

  const onErrorDateReceiveTax = (err) => {
    setIsErrorDateTaxReceive(err);
  };

  const onChangeTaxInvoice = (isReceive) => {
    setIsReceiveTaxInvoice(isReceive);
  };

  const onChangeReceiveDt = (receivedt) => {
    setTaxInvoiceReceiveDt(receivedt);
  };

  const provinceLicenseOptions = useMemo(() => {
    return generateProvinceLicenseOptions(provincelicense);
  }, [provincelicense]);

  const vehicleTypeOptions = useMemo(() => {
    return generateVehicleTypeOptions(vehicletype);
  }, [vehicletype]);

  useEffect(() => {
    if (pmlsId) {
      getOnlineConfig(pmlsId);
    }
  }, [pmlsId]);

  const isCloseOnlinePayment = useMemo(() => {
    return isOnlineConfigStatusClose(onlineConfigData?.isonlinepayment);
  }, [onlineConfigData]);

  // close online payment
  useEffect(() => {
    if (isCloseApp) return;
    if (isCloseOnlinePayment) {
      openModalClosePayment();
    } else {
      closeModalClosePayment();
    }
  }, [isCloseOnlinePayment, isCloseApp]);

  const onChangeSelectRenewCurrentMonth = (value) => setSelectRenewMonth(value);

  const resetState = () => {
    setStep(RENEW_STEP.FORM);
    setIsPendingPayment(false);
    setSelectRenewMonth(undefined);
    setCompletedDisplayData({});
    setRenewWithMonthlyCap(false);
    setOnlinetransid(undefined);
    onChangeTaxInvoice(null);
    onChangeReceiveDt(null);
  };

  const formik = useFormik({
    initialValues: formikInitValues,
    initialErrors: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try {
        resetState();
        getConfigGlobalMutate.mutateAsync().then((response) => {
          if (successRequest(response)) {
            const data = response?.data?.config[0].monthly;
            setConfigGlobal(
              isEffectiveDateConfig(data)
                ? CONFIG_GLOBAL.effective_date
                : CONFIG_GLOBAL.monthly
            );
            getMemberInfoByLicensePlateMutate.mutate({
              pmlsid: pmlsId,
              licenseplate: values.licensePlate.trim(),
              province: values.province?.display,
              vehicletypeid: values.vehicleType?.id,
            });
          } else {
            toastErr("something went wrong with config global!");
          }
        });
      } catch (err) {
        toastErr(err);
      }
    },
  });

  const getConfigGlobalMutate = useMutation({
    mutationFn: () =>
      ConfigService.getEntryExitGlobalConfigFormPMLS({
        pmlsid: pmlsId,
        conf: "monthly",
        confgroup: "membercal",
      }),
    onError: (err) => {
      toastErr(String(err));
    },
  });

  // Check if the query parameters are present in the URL and if so, update the formik values with the query parameters values and submit the form
  useEffect(() => {
    if (!searchLocation || !vehicleTypeOptions || !provinceLicenseOptions)
      return;

    const searchParams = new URLSearchParams(searchLocation);
    const licenseplate = searchParams.get("licenseplate");
    const licenseprovince = searchParams.get("licenseprovince");
    const vehicletypeid = searchParams.get("vehicletypeid");
    const pmlsid = searchParams.get("pmlsid");

    if (!licenseplate || !licenseprovince || !vehicletypeid || !pmlsid) {
      toastErr("ไม่พบข้อมูลในระบบ");
      return;
    }

    const findVehicleTypeFormMasterData = vehicleTypeOptions?.find(
      (item) => Number(item.id) === Number(vehicletypeid)
    );

    const findLicenseProvinceFormMasterData = provinceLicenseOptions?.find(
      (item) => item.display === licenseprovince
    );

    if (!findVehicleTypeFormMasterData || !findLicenseProvinceFormMasterData) {
      toastErr('ไม่พบข้อมูล "ประเภทรถ" หรือ "จังหวัด" ในระบบ');
      return;
    }

    formik.setFieldValue("licensePlate", licenseplate);
    formik.setFieldValue("province", findLicenseProvinceFormMasterData);
    formik.setFieldValue("vehicleType", findVehicleTypeFormMasterData);

    getConfigGlobalMutate.mutateAsync().then((response) => {
      if (successRequest(response)) {
        const data = response?.data?.config[0].monthly;
        setConfigGlobal(
          isEffectiveDateConfig(data)
            ? CONFIG_GLOBAL.effective_date
            : CONFIG_GLOBAL.monthly
        );
        getMemberInfoByLicensePlateMutate.mutate({
          pmlsid,
          licenseplate,
          province: licenseprovince,
          vehicletypeid,
        });
      } else {
        toastErr("something went wrong with config global!");
      }
    });
  }, [searchLocation, vehicleTypeOptions, provinceLicenseOptions]);

  useEffect(() => {
    if (formik.values && STEP_FEE_PARKING.SHOW_DETAIL) {
      resetState();
    }
  }, [formik?.values]);

  const getMemberInfoByLicensePlateMutate = useMutation({
    mutationFn: (values) =>
      RenewByLicensePlateService.getMemberInfoByLicensePlate(values),
    onSuccess: async (response) => {
      try {
        const { data, ouMessage } = response;
        if (successRequest(response)) {
          if (Object.keys(data).length === 0) {
            toastWarning("ไม่พบข้อมูลสมาชิก");
          } else {
            getProcessHistoryMutate.mutate(data?.TbMember?.memberid); //# 1911-> เช็คเพิ่มว่ามี process เดิมค้างอยู่หรือไม่
          }
        } else {
          toastWarning(ouMessage);
        }
      } catch (err) {
        toastErr(err);
      }
    },
    onError: (res) => toastErr(res),
  });

  const getProcessHistoryMutate = useMutation({
    mutationFn: (memberid) => registerService.getHistory(pmlsId, memberid),
    onSuccess: async (response) => {
      try {
        const { data, ouMessage } = response || {};
        if (successRequest(response)) {
          const process = data?.process;

          // case : ถ้าไม่มี process ค้างอยู่
          if (process.length === 0) {
            setStep(RENEW_STEP.SHOW_DETAIL);
            return;
          }

          // case : ถ้ามี process ค้างอยู่
          const lastProcessWithProcessTypeRenew =
            findLatestRecordWithProcessType(
              data?.process,
              PROCESS.TYPE_CODE.RENEW,
              formik.values.vehicleType.id
            );

          if (!lastProcessWithProcessTypeRenew) {
            setStep(RENEW_STEP.SHOW_DETAIL);
            return;
          }

          const { stateid, processid, isfuture } =
            lastProcessWithProcessTypeRenew || {};

          if (stateid === PROCESS.RENEW_STATE.WAIT_PAYMENT) {
            getMemberRenewMutate.mutate({
              memberid: memberInfoData?.memberid,
              processid: processid,
              stateid: PROCESS.RENEW_STATE.WAIT_PAYMENT,
              isfuture: isfuture,
            });
          } else if (stateid === PROCESS.RENEW_STATE.SUCCESS_RENEW) {
            getMemberRenewMutate.mutate({
              memberid: memberInfoData?.memberid,
              processid: processid,
              stateid: PROCESS.RENEW_STATE.SUCCESS_RENEW,
              isfuture: isfuture,
            });
          } else {
            setStep(RENEW_STEP.SHOW_DETAIL);
          }
        } else {
          toastErr(ouMessage);
        }
      } catch (err) {
        toastErr(err);
      }
    },
    onError: (res) => toastErr(res),
  });

  const memberInfoData =
    getMemberInfoByLicensePlateMutate?.data?.data?.TbMember || {};

  const getMemberRenewMutate = useMutation({
    mutationFn: (values) => {
      const params = {
        pmlsid: pmlsId,
        memberid: values.memberid,
        processid: values.processid,
      };
      return RenewByLicensePlateService.getCaseMemberRenewByPmls(params);
    },
    onSuccess: async (response, variable) => {
      try {
        const { data, ouMessage } = response;
        if (successRequest(response)) {
          //? เมื่อผู้ใช้งานจ่ายเงินแล้วแต่ไม่กลับมาที่เว็บไซต์ ทำให้หลังจากเข้า (PROCESS.RENEW_STATE.WAIT_PAYMENT)
          //? จะไปยิง renew ใหม่แล้วส่ง variable ไปเพื่อเช็คว่าเป็นการต่ออายุในเดือนปัจจุบัน/หรืออนาคต แล้ว setSelectRenewMonth เพื่อไปแสดงผลวันที่ใน Summary Step
          if (variable?.isCallRenewAgain) {
            if (variable?.isfuture === 0) {
              //เลือกต่ออายุในเดือนปัจจุบัน
              setSelectRenewMonth({ id: "1" });
            } else if (variable?.isfuture === 1) {
              //เลือกต่ออายุในเดือนอนาคต
              setSelectRenewMonth({ id: "2" });
            }
            setStep(RENEW_STEP.COMPLETED_PAYMENT);
          } else {
            const dataMember = data?.Member || {};
            const memberProcessPayment = dataMember?.memberprocesspayment || [];

            if (memberProcessPayment.length === 0) {
              setStep(RENEW_STEP.SHOW_DETAIL);
              return;
            } else {
              if (variable?.stateid === PROCESS.RENEW_STATE.WAIT_PAYMENT) {
                // Check if there is no payment with status "Confirm"
                const isNoConfirmPayment = !memberProcessPayment.some(
                  (payment) => payment.status === PROCESS_PAYMENT_STATUS.CONFIRM
                );

                // Check if there is no payment with status "Confirm" and the last payment has status "Request"
                const lastPayment =
                  memberProcessPayment[memberProcessPayment.length - 1];
                const isLastPaymentRequest =
                  lastPayment &&
                  lastPayment.status === PROCESS_PAYMENT_STATUS.REQUEST;

                // Check if there is any payment with status "Confirm"
                const hasConfirmPayment = memberProcessPayment.some(
                  (payment) => payment.status === PROCESS_PAYMENT_STATUS.CONFIRM
                );

                // Check if there is any payment with status "Cancel"
                const hasCancelPayment = memberProcessPayment.some(
                  (payment) => payment.status === PROCESS_PAYMENT_STATUS.CANCEL
                );

                // Check conditions and take actions
                if (isNoConfirmPayment && isLastPaymentRequest) {
                  // Call 3 API
                  // 1. /mapp/payment/qr/query
                  // -> responsestatus: "100" ?? toast.error "เนื่องจากมีรายการชำระเงินค้างอยู่ กรุณารอ 15 นาทีหลังจากการทำรายการล่าสุด เพื่อดำเนินการต่ออายุสมาชิกใหม่อีกครั้ง"
                  // -> responsestatus: "200" ?? ให้เรียก 2 เส้นด้านล่างต่อ และขึ้นหน้าสรุปการต่ออายุสมาชิก
                  setIsPendingPayment(true);
                  const responsePaymentQRQueryStatus =
                    await PaymentService.queryQRStatus({
                      pmlsid: pmlsId,
                      onlinetransid: lastPayment.onlinetransid,
                    });
                  if (
                    successRequest(responsePaymentQRQueryStatus) &&
                    responsePaymentQRQueryStatus?.data?.tbonlinepayment !== null
                  ) {
                    const onlinePaymentData =
                      responsePaymentQRQueryStatus?.data?.tbonlinepayment;

                    if (!onlinePaymentData?.responsestatus) {
                      //case : ยังไม่จ่ายเงิน
                      toastErr(
                        "เนื่องจากมีรายการชำระเงินค้างอยู่ กรุณารอ 15 นาทีหลังจากการทำรายการล่าสุด เพื่อดำเนินการต่ออายุสมาชิกใหม่อีกครั้ง"
                      );
                    } else if (onlinePaymentData?.responsestatus === "200") {
                      setCompletedDisplayData({
                        updateddt: onlinePaymentData?.updatedt,
                        onlinetransid: onlinePaymentData?.onlinetransid,
                      });
                      // 2./mapp/payment/memberorder/online/renewbylicenseplate/saptrans
                      const responseSaptrans =
                        await RenewByLicensePlateService.createPaymentSaptransReceipt(
                          {
                            pmlsid: pmlsId,
                            paytype: "02",
                            transtype: "T03",
                            paysourcecode: "06",
                            onlineref: lastPayment?.onlinetransid,
                            processid: lastPayment?.processid,
                            amount: packagesTotalPrice,
                            createby: 366,
                            licenseplate: formik?.values?.licensePlate,
                            memberno: memberInfoData?.memberno,
                          }
                        );

                      if (responseSaptrans.ouIsComplete === 1) {
                        // 3./subsystem/pmls/payment/memberprocess/update
                        await PaymentService.updateMemberProcess({
                          pmlsid: pmlsId,
                          paymentid: lastPayment?.paymentid, //https://dev.azure.com/mmats/AOT-CARPARK/_workitems/edit/1994/
                          onlinetransid: lastPayment?.onlinetransid,
                          paytype: "02", // ส่ง 02 (fix)
                          ref1: lastPayment?.ref1,
                          ref2: lastPayment?.ref2,
                          ref3: lastPayment?.ref3,
                          status: "Confirm", // ส่ง Confirm (fix)
                          processid: lastPayment?.processid,
                          amount: packagesTotalPrice, //#1931
                          updateby: "366", // ส่ง 366 (fix)
                        })
                          .then((response) => {
                            if (response.ouIsComplete === 1) {
                              getMemberRenewMutate.mutate({
                                memberid: memberInfoData?.memberid,
                                processid: lastPayment?.processid,
                                isCallRenewAgain: true, // ส่งไปเพื่อเรียก renew อีกครั้งเพื่อใช้ค่าล่าสุดของ "วันที่เริ่มใช้งาน"
                                isfuture: variable?.isfuture,
                              });
                            } else if (response.ouMessage) {
                              toast.error(response.ouMessage, {
                                position: "top-center",
                                autoClose: 1500,
                              });
                            }
                          })
                          .catch((err) => toastErr(err));
                      }
                    } else {
                      toastErr("ไม่พบ responsestatus ที่ต้องการ");
                    }
                  }
                } else if (hasConfirmPayment) {
                  toastErr(
                    "เนื่องจากมีรายการชำระเงินค้างอยู่ กรุณารอ 15 นาทีหลังจากการทำรายการล่าสุด เพื่อดำเนินการต่ออายุสมาชิกใหม่อีกครั้ง"
                  );
                } else if (hasCancelPayment) {
                  setStep(RENEW_STEP.SHOW_DETAIL);
                }
              }
              if (variable?.stateid === PROCESS.RENEW_STATE.SUCCESS_RENEW) {
                const memberOrder = dataMember?.memberOrder[0] || {};
                if (!memberOrder) {
                  toastErr("memberOrder not found!");
                  return;
                }
                if (memberOrder?.status === "Pending") {
                  setRenewWithMonthlyCap(true);
                }
                setStep(RENEW_STEP.SHOW_DETAIL);
              }
            }
          }
        } else {
          toastErr(ouMessage);
        }
      } catch (err) {
        toastErr(err);
      }
    },
    onError: (response) => {
      toastErr(response);
    },
  });

  const getRenewMemberDataAtPmls = getMemberRenewMutate?.data?.data || {};

  const getPackageGroupMutate = useMutation({
    mutationFn: (packagegroupid) =>
      registerService.getPackagegroup({
        pmlsid: pmlsId,
        packagegroupid,
        langid: getLangIdFormPMLSlang(i18n?.language),
      }),
    select: (data) => data?.data?.PackageGroup,
  });

  const getParkingGroupMutate = useMutation({
    mutationFn: (parkinggroupid) =>
      ParkingService.getParkingGroupFormPMLS({
        pmlsid: pmlsId,
        parkinggroupid,
        langid: getLangIdFormPMLSlang(i18n?.language),
      }),
  });

  const parkingGroupData =
    getParkingGroupMutate?.data?.data?.data?.TbParkingGroup;

  const packageGroupData =
    getPackageGroupMutate?.data?.data?.PackageGroup || {};

  //? show package detail filter chargetype = 002
  const packageItem = useMemo(
    () => packageGroupData?.items?.filter((item) => item.chargetype === "002"),
    [packageGroupData]
  );

  const packagesTotalPrice = getTotalPackagePriceByNumberOfMonth(
    packageItem,
    NUMBER_OF_MONTH_RENEW_BY_LICENSE_PLATE
  );

  const selectVehicle = useMemo(() => {
    return memberInfoData?.vehicles?.find(
      (vehicle) => vehicle.vehicletypeid === formik?.values?.vehicleType?.id
    );
  }, [formik?.values, memberInfoData, vehicleTypeOptions]);

  const selectPackage = useMemo(() => {
    return memberInfoData?.packages?.find(
      (vehicle) => vehicle.vehicletypeid === formik?.values?.vehicleType?.id
    );
  }, [formik?.values, memberInfoData]);

  const vehicleTypeName = useMemo(() => {
    return vehicletype?.find(
      (item) => item.vehicletypeid === selectVehicle?.vehicletypeid
    )?.name_translate;
  }, [selectVehicle, vehicletype]);

  // หาอัตราค่าสมาชิกจาก packagegroupid
  useEffect(() => {
    if (selectPackage?.packagegroupid) {
      getPackageGroupMutate.mutate(selectPackage?.packagegroupid);
      getParkingGroupMutate.mutate(selectPackage.parkinggroupid);
    }
  }, [selectPackage?.packagegroupid, i18n.language]);

  const packageExpiredDate = selectPackage?.enddate;

  const findNewRenewStartAndEndDate = (expiredDate) => {
    let date = {
      start: null,
      end: null,
    };
    if (renewExpiredtIsBeforeCurrentMonth(expiredDate)) {
      // ถ้าวันที่หมดอายุเป็นเดือนในอดีต
      //เดือนปัจจุบัน
      if (isSelectCurrentMonth(selectRenewMonth)) {
        //ถ้า config เป็น effective date ให้แสดงวันที่ปัจจุบัน แต่ตอนส่งเส้น update (step = 2) ต้องส่งวันที่ 1 ของเดือน (dayjs().startOf("month")
        date = {
          start: isEffectiveDateConfig(configGlobal)
            ? dayjs()
            : dayjs().startOf("month"),
          end: dayjs().endOf("month"),
        };
        //เดื่อนอนาคต -> เดือนปัจจุบัน + 1
      } else if (selectRenewMonth?.id === "2") {
        date = {
          start: dayjs().add("1", "month").startOf("month"),
          end: dayjs().add("1", "month").endOf("month"),
        };
      } else {
        //ยังไม่ได้เลือกเดือนจะโชว์ dd/mm/yyyy
      }
    } else {
      // ถ้าวันที่หมดอายุเป็นเดือนในปัจจุบันและอนาคต ให้บวกเพิ่มไป 1 เดือน
      date = {
        start: dayjs(expiredDate).add("1", "month").startOf("month"),
        end: dayjs(expiredDate).add("1", "month").endOf("month"),
      };
    }
    return date;
  };

  const { start: newPackageStartDate, end: newPackageExpiredDate } =
    findNewRenewStartAndEndDate(packageExpiredDate);

  const memberRenewByPmlsMemberOrderOrderItem = useMemo(() => {
    if (getRenewMemberDataAtPmls?.Member?.memberOrder?.length > 0) {
      return getRenewMemberDataAtPmls?.Member?.memberOrder[0]?.OrderItem[0];
    } else {
      return {};
    }
  }, [getRenewMemberDataAtPmls]);

  //RENEW_STEP.SHOW_DETAIL
  const displayInformationData = {
    airport: getAirportNameFormPmlsAndLang(pmlsId, i18n?.language),
    memberType: "Member",
    vehicleTypeName: vehicleTypeName,
    licensePlate: selectVehicle?.licenseplate,
    licenseProvince: selectVehicle?.licenseprovince,
    packageExpiredDate: packageExpiredDate,
    parkingGroupName: parkingGroupData?.name_translate,
    packageGroupName: packageGroupData?.name_translate,
    numberOfMonth: NUMBER_OF_MONTH_RENEW_BY_LICENSE_PLATE,
    newPackageStartDate: renewWithMonthlyCap
      ? memberRenewByPmlsMemberOrderOrderItem?.startdate
      : isEffectiveDateConfig(configGlobal)
      ? newPackageStartDate
      : newPackageStartDate,
    newPackageExpiredDate: renewWithMonthlyCap
      ? memberRenewByPmlsMemberOrderOrderItem?.enddate
      : newPackageExpiredDate, // เคสที่มี gap เดือนจะดึงค่ามาจาก member renew by pmls
  };

  const confirmPaymentDetails = [
    {
      title: t("License Plate"),
      value: getLicensePlateProvinceText(selectVehicle),
    },
    {
      title: t("Renew Duration"),
      value: `${NUMBER_OF_MONTH_RENEW_BY_LICENSE_PLATE} ${t("Month")}`,
    },
    {
      title: t("Apply Start Date"),
      value: displayInformationData?.newPackageStartDate
        ? dayjs(displayInformationData?.newPackageStartDate)?.format(
            DATE_FORMATS.FORMAT_01
          )
        : "-",
      isShowAlert: isEffectiveDateConfig(configGlobal),
      valueAlert: (
        <p className="text-red-500 text-center font-semibold mt-4">
          {t("Remark renew parking fee")}
        </p>
      ),
    },

    {
      title: t("Expired Date"),
      value: displayInformationData?.newPackageExpiredDate
        ? dayjs(displayInformationData?.newPackageExpiredDate)?.format(
            DATE_FORMATS.FORMAT_01
          )
        : "-",
    },
  ];

  const memberRenewUpdateMutate = useMutation({
    mutationFn: (payload) => MemberService.mmapMemberRenewUpdate(payload),
    onSuccess: (response) => {
      if (successRequest(response)) {
        const processIdFormatted = String(
          response.data.memberprocessid //#1930
        ).padStart(9, "0");
        const memberNo = memberInfoData?.memberno;
        const currentDateFormatted = dayjs().format("DDMMYYYY");
        const paymentRef = processIdFormatted + memberNo + currentDateFormatted;
        createPaymentQR.mutate({
          pmlsid: pmlsId,
          amount: packagesTotalPrice, //#1930
          paymentsource: "06", //? FIX VALUE WEB QR CODE
          paymentref1: paymentRef,
        });
      } else {
        toastErr(response?.ouMessage);
      }
    },
    onError: (response) => {
      toastErr(response);
    },
  });

  const updateRenewByLicensePlateData =
    memberRenewUpdateMutate?.data?.data || {};

  const onClickNexButtonHandler = () => {
    setStep(RENEW_STEP.CONFIRM_PAYMENT);
  };

  useEffect(() => {
    const scrollToElement = (elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    if (step === RENEW_STEP.CONFIRM_PAYMENT) {
      scrollToElement("receiveTaxInvoice");
    } else if (step === RENEW_STEP.WAIT_PAYMENT) {
      scrollToElement("waitingPaymentBox");
    }
  }, [step]);

  const updateRenewHandler = () => {
    try {
      const currentDate = dayjs().format(DATE_FORMATS.DEFAULT);
      const packageStartDateFormatted = isSelectCurrentMonth(selectRenewMonth)
        ? dayjs().startOf("month").format(DATE_FORMATS.DEFAULT)
        : dayjs(newPackageStartDate).format(DATE_FORMATS.DEFAULT);
      const packageEndDateFormatted = dayjs(newPackageExpiredDate).format(
        DATE_FORMATS.DEFAULT
      );
      const isFuture = currentDate < packageStartDateFormatted ? 1 : 0;

      memberRenewUpdateMutate.mutate({
        createsource: 3, // fix WEB = 3
        pmlsid: pmlsId,
        processid: 0, // ส่ง 0 (fix)
        memberid: memberInfoData?.memberid,
        memberorder: {
          memberorderid: selectPackage?.orderid, // ส่ง orderid จากเส้น /subsystem/pmls/member/getbylicenseplate? (หาไม่เจอ)
          packagegroupid: selectPackage?.packagegroupid, // ส่ง packagegroupid จากเส้น /subsystem/pmls/member/getbylicenseplate?
          isfuture: isFuture, //ถ้าเป็นเวลาอนาคต = 1
          startdate: packageStartDateFormatted, // ส่ง วันเริ่มใช้งาน ที่ได้จากหน้าแสดงข้อมูลการต่ออายุสมาชิก (ที่ระยะการต่ออายุสมาชิก)
          enddate: packageEndDateFormatted, // ส่ง วันหมดอายุที่ได้จากหน้าแสดงข้อมูลการต่ออายุสมาชิก (ที่ระยะการต่ออายุสมาชิก
          parkinggroupid: selectPackage?.parkinggroupid, // ส่ง parkinggroupid จากเส้น /subsystem/pmls/member/getbylicenseplate?
          vehicletypeid: formik?.values?.vehicleType?.id,
          items: [
            {
              orderitemid: 0, // fix
              chargetype: "002", // fix "002"
              packageid: selectPackage?.packageid,
              qty: 1, // ส่ง 0 (fix)
              unitprice: packagesTotalPrice, // ส่ง totalprice จากเส้น /subsystem/pmls/sys/packagegroup? ที่ groupitems filter จาก packageid (packageid จากเส้น /subsystem/pmls/member/getbylicenseplate?)
              totalprice: packagesTotalPrice, // ส่ง totalprice จากเส้น /subsystem/pmls/sys/packagegroup? ที่ groupitems filter จาก packageid (packageid จากเส้น /subsystem/pmls/member/getbylicenseplate?)
            },
          ],
        },
        isreceive: Number(isReceiveTaxInvoice),
        receivedt:
          isReceiveTaxInvoice === "1"
            ? dayjs(taxInvoiceReceiveDt)
                .hour(0)
                .minute(0)
                .second(0)
                .format(DATE_FORMATS.DEFAULT)
            : null,
      });
    } catch (err) {
      toastErr(String(err));
    }
  };

  // 2. create payment
  const onClickPaymentButtonHandler = () => {
    updateRenewHandler();
  };

  const updatePaymentMemberProcessMutate = useMutation({
    mutationFn: async (payload) => PaymentService.updateMemberProcess(payload),
    onSuccess: (response) => {
      if (successRequest(response)) {
        setStep(RENEW_STEP.WAIT_PAYMENT);
        onChangePaymentStatus(STATUS_PAYMENT.WAIT);
      } else if (response?.ouMessage) {
        toast.error(response?.ouMessage, {
          position: "top-center",
          autoClose: 2000,
        });
      } else {
        toastErr("Error occurred!");
      }
    },
    onError: (err) => {
      toastErr(err);
    },
  });

  const updatePaymentMemberProcessData =
    updatePaymentMemberProcessMutate?.data?.data || {}; // { paymentid: number }

  const createPaymentQR = useMutation({
    mutationFn: async (payload) =>
      PaymentService.mmapMemberCreateSecurePaymentQrCode(payload),
    onSuccess: async (response) => {
      try {
        if (successRequest(response)) {
          if (!response?.data?.onlinetransid) {
            toastErr("Error occurred onlinetransid not found!");
            setStep(RENEW_STEP.FORM);
            return;
          }
          const createPaymentQRData = response?.data;
          setOnlinetransid(createPaymentQRData?.onlinetransid);
          updatePaymentMemberProcessMutate.mutate({
            pmlsid: pmlsId,
            paymentid: 0, // ส่ง 0 (fix)
            onlinetransid: createPaymentQRData?.onlinetransid, // ส่ง onlinetransid ที่ได้จาก /mapp/payment/secure/qr/member/request
            paytype: "02", // ส่ง 02 (fix)
            ref1: createPaymentQRData?.paymentref1, // ส่ง paymentref1 ที่ได้จาก /mapp/payment/secure/qr/member/request
            ref2: createPaymentQRData?.paymentref2, // ส่ง paymentref2 ที่ได้จาก /mapp/payment/secure/qr/member/request
            ref3: createPaymentQRData?.paymentref3, // ส่ง paymentref3 ที่ได้จาก /mapp/payment/secure/qr/member/request
            status: PROCESS_PAYMENT_STATUS.REQUEST, // ส่ง Request (fix)
            processid: updateRenewByLicensePlateData?.memberprocessid, //# 1931
            amount: packagesTotalPrice, //# 1931
            updateby: "366", // ส่ง 366 (fix)
          });
        } else {
          toastErr(response?.ouMessage);
        }
      } catch (err) {
        toastErr(err);
      }
    },
    onError: (response) => {
      onFailedPaymentHandler();
      toastErr(response);
    },
    select: (data) => data?.data,
  });

  const paymentQRData = createPaymentQR?.data?.data;

  // 3. query qr status
  const qrQueryStatus = useMutation({
    mutationFn: async (payload) => PaymentService.queryQRStatus(payload),
    onSuccess: async (response) => {
      const onlinePaymentData = response?.data?.tbonlinepayment;

      if (
        response.ouIsComplete === 0 &&
        response.ouReasonCode !== "100000" &&
        response?.ouMessage &&
        !onlinePaymentData
      ) {
        // error case
        onFailedPaymentHandler();
        setStep(RENEW_STEP.FORM);
        toast.error(response?.ouMessage, {
          position: "top-center",
          autoClose: 2000,
        });
      } else {
        if (
          response.ouIsComplete === 1 &&
          onlinePaymentData?.responsestatus === "200" &&
          onlinePaymentData?.responsedt !== null
        ) {
          try {
            //success case
            setCompletedDisplayData({
              updateddt: onlinePaymentData?.updatedt,
              onlinetransid: onlinePaymentData?.onlinetransid,
            });
            onSuccessPaymentHandler();

            //# ref  https://dev.azure.com/mmats/AOT-CARPARK/_workitems/edit/1938
            createReceiptSaptransRenewMember.mutate({
              pmlsid: pmlsId,
              paytype: "02",
              transtype: "T03",
              paysourcecode: "06",
              onlineref: onlinetransid,
              processid: updateRenewByLicensePlateData?.memberprocessid,
              amount: packagesTotalPrice,
              createby: 366,
              licenseplate: formik?.values?.licensePlate,
              memberno: memberInfoData?.memberno,
            });
          } catch (err) {
            toast.error(err, {
              position: "top-center",
              autoClose: 2000,
            });
          }
        } else if (onlinePaymentData?.responsestatus !== null) {
          //error case with payment response
          const paymentStatusDesc =
            ONLINE_PAYMENT_STATUS[Number(onlinePaymentData?.responsestatus)]
              ?.description;
          toast.error(paymentStatusDesc, {
            position: "top-center",
            autoClose: 2000,
          });
          onFailedPaymentHandler();
          setStep(RENEW_STEP.FORM);
        }
      }
    },
    onError: (response) => {
      toastErr(response);
      onFailedPaymentHandler();
      setStep(RENEW_STEP.FORM);
    },
  });

  const createReceiptSaptransRenewMember = useMutation({
    mutationFn: (payload) =>
      RenewByLicensePlateService.createPaymentSaptransReceipt(payload),
    onSuccess: async (response) => {
      try {
        if (successRequest(response)) {
          //# https://dev.azure.com/mmats/AOT-CARPARK/_workitems/edit/1946 - เรียกเพิ่มหลังจ่ายสำเร็จ
          const payloadUpdateMemberProcess = {
            pmlsid: pmlsId,
            paymentid: updatePaymentMemberProcessData?.paymentid, //https://dev.azure.com/mmats/AOT-CARPARK/_workitems/edit/1994/
            onlinetransid: paymentQRData?.onlinetransid, // ส่ง onlinetransid ที่ได้จาก /mapp/payment/secure/qr/member/request
            paytype: "02", // ส่ง 02 (fix)
            ref1: paymentQRData?.paymentref1, // ส่ง paymentref1 ที่ได้จาก /mapp/payment/secure/qr/member/request
            ref2: paymentQRData?.paymentref2, // ส่ง paymentref2 ที่ได้จาก /mapp/payment/secure/qr/member/request
            ref3: paymentQRData?.paymentref3, // ส่ง paymentref3 ที่ได้จาก /mapp/payment/secure/qr/member/request
            status: PROCESS_PAYMENT_STATUS.CONFIRM, // ส่ง Confirm (fix)
            processid: updateRenewByLicensePlateData?.memberprocessid, // ส่ง memberprocessid ที่ได้จาก /mapp/member/renew/update
            amount: packagesTotalPrice, //#1931
            updateby: "366", // ส่ง 366 (fix)
          };
          const responseUpdateMemberProcess =
            await PaymentService.updateMemberProcess(
              payloadUpdateMemberProcess
            );

          if (successRequest(responseUpdateMemberProcess)) {
            getMemberRenewMutate.mutate({
              pmlsid: pmlsId,
              memberid: memberInfoData?.memberid,
              processid: updateRenewByLicensePlateData?.memberprocessid,
            });
            setStep(RENEW_STEP.COMPLETED_PAYMENT);
          } else if (responseUpdateMemberProcess?.ouMessage) {
            toastErr(responseUpdateMemberProcess?.ouMessage);
          }
        } else {
          setStep(RENEW_STEP.FORM);
          toastErr(response?.ouMessage);
        }
      } catch (err) {
        toastErr(err);
      }
    },
    onError: (res) => {
      setStep(RENEW_STEP.FORM);
      toast.error("Error occurred!, Please contact admin.", {
        position: "top-center",
        autoClose: 2000,
      });
    },
  });

  useEffect(() => {
    let newIntervalId = null;
    if (onlinetransid && paymentStatus === STATUS_PAYMENT.WAIT && pmlsId) {
      newIntervalId = setInterval(() => {
        const payloadQRQueryStatus = {
          pmlsid: pmlsId,
          onlinetransid,
        };
        qrQueryStatus.mutate(payloadQRQueryStatus);
      }, INTERVAL_QR_QUERY);
      onChangeIntervalId(newIntervalId);
    }

    return () => {
      clearInterval(newIntervalId);
    };
  }, [onlinetransid, paymentStatus, pmlsId]);

  //กลับมาที่ดูรายละเอียดกาต่ออายุสมาชิก
  const onClickSeeDetailBtnHandler = () => {
    formik.handleSubmit();
  };

  const onClickMakeNewTransactionBtnHandler = () => {
    setStep(RENEW_STEP.SHOW_DETAIL);
  };

  //ref - https://dev.azure.com/mmats/AOT-CARPARK/_workitems/edit/1993/ QR Expired (callback when expired)
  const callBackWhenPaymentExpired = async () => {
    try {
      onExpiredPaymentHandler();
      const payloadUpdateMemberProcess = {
        pmlsid: pmlsId, // ส่ง 1 หรือ 2 ตามท่าอากาศยานจากเว็บ
        paymentid: updatePaymentMemberProcessData?.paymentid, // ส่ง paymentid ที่ได้จาก /subsystem/pmls/payment/memberprocess/update ที่เรียกตอนแรก (ตอนแรกที่ส่ง status=Request)
        onlinetransid: paymentQRData?.onlinetransid, // ส่ง onlinetransid ที่ได้จาก /mapp/payment/secure/qr/member/request
        paytype: "02", // ส่ง 02 (fix)
        ref1: paymentQRData?.paymentref1, // ส่ง paymentref1 ที่ได้จาก /mapp/payment/secure/qr/member/request
        ref2: paymentQRData?.paymentref2, // ส่ง paymentref2 ที่ได้จาก /mapp/payment/secure/qr/member/request
        ref3: paymentQRData?.paymentref3, // ส่ง paymentref3 ที่ได้จาก /mapp/payment/secure/qr/member/request
        status: PROCESS_PAYMENT_STATUS.CANCEL, // ส่ง Cancel (fix)
        processid: updateRenewByLicensePlateData?.memberprocessid, // ส่ง memberprocessid ที่ได้จาก /mapp/member/renew/update
        amount: packagesTotalPrice, // ส่ง totalprice จากเส้น /subsystem/pmls/sys/packagegroup? ที่ groupitems filter จาก packageid (packageid จากเส้น /subsystem/pmls/member/getbylicenseplate?)
        updateby: "366", // ส่ง 366 (fix)
      };

      await PaymentService.updateMemberProcess(payloadUpdateMemberProcess)
        .then((response) => {
          if (successRequest(response)) {
            setStep(RENEW_STEP.BANK_EXPIREDT);
          } else if (response.ouMessage) {
            toastErr(response?.ouMessage);
          }
        })
        .catch((err) => toastErr(err));
    } catch (err) {
      toastErr(err);
    }
  };

  const loadingForm =
    getMemberInfoByLicensePlateMutate.isLoading ||
    getProcessHistoryMutate.isLoading ||
    getMemberRenewMutate.isLoading ||
    getConfigGlobalMutate.isLoading;

  const loadingConfirmPayment =
    memberRenewUpdateMutate.isLoading || createPaymentQR.isLoading;

  const waitPaymentTopHeaderDisplay = (
    <div className="border-b border-solid w-full pb-3 text-center">
      <p className="text-sm md:text-base text-red-500">
        {t("QR code for membership renewal payment")}{" "}
        {displayInformationData?.licensePlate}{" "}
        {displayInformationData?.licenseProvince} <br />
        {t("Period")}{" "}
        {(displayInformationData?.newPackageStartDate &&
          dayjs(displayInformationData?.newPackageStartDate).format(
            DATE_FORMATS.FORMAT_01
          )) ||
          ""}{" "}
        -{" "}
        {(displayInformationData?.newPackageExpiredDate &&
          dayjs(displayInformationData?.newPackageExpiredDate).format(
            DATE_FORMATS.FORMAT_01
          )) ||
          ""}{" "}
        <br />
        {t("and can be used to pay only 1 time")}
      </p>
    </div>
  );

  const isDisabledNextButton = () => {
    if (isCloseOnlinePayment) {
      // if close online payment disabled next button
      return true;
    } else {
      return renewWithMonthlyCap ||
        renewExpiredtIsBeforeCurrentMonth(
          displayInformationData.packageExpiredDate
        )
        ? selectRenewMonth?.id === undefined
        : false;
    }
  };

  return (
    <>
      <div className="bg-[#E9F0F5] pt-20 md:pt-[150px]">
        <ContainerCenter>
          <RenewStepper
            sxa={{
              transform: "translateY(-20px)",
            }}
            step={step}
          />
        </ContainerCenter>
      </div>
      <PageContent
        title={t("Renew Membership")}
        icon={<img src="/images/renew/ico_renew_bl.svg" alt="renew-icon" />}
        breadcrumb={
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              {t("Home")}
            </Link>
            <Typography color="#245AB1">{t("Renew Membership")}</Typography>
          </Breadcrumbs>
        }
      >
        <section className="w-full space-y-12">
          {[RENEW_STEP.FORM, RENEW_STEP.SHOW_DETAIL].includes(step) && (
            <Form
              formik={formik}
              isLoading={getMemberInfoByLicensePlateMutate?.isLoading}
            />
          )}
          {loadingForm ? (
            <LoadingSpinner />
          ) : (
            memberInfoData &&
            step === RENEW_STEP.SHOW_DETAIL && (
              <div className="space-y-8">
                <DisplayInformationBox
                  {...displayInformationData}
                  selectRenewMonth={selectRenewMonth}
                  onChangeSelectRenewCurrentMonth={
                    onChangeSelectRenewCurrentMonth
                  }
                  renewWithMonthlyCap={renewWithMonthlyCap}
                  isEffectiveDateConfig={isEffectiveDateConfig(configGlobal)}
                />
                <TablePackageDetail
                  isRenew
                  data={packageItem}
                  totalPrice={packagesTotalPrice}
                />
                {isCloseOnlinePayment && (
                  <p className=" text-center text-red-500 font-semibold text-lg">
                    {t("noti close renew detail")}
                  </p>
                )}
                <ButtonAction
                  onClick={onClickNexButtonHandler}
                  disabled={isDisabledNextButton()}
                >
                  {t("Next")}
                </ButtonAction>
              </div>
            )
          )}
          {loadingConfirmPayment ? (
            <LoadingSpinner />
          ) : (
            step === RENEW_STEP.CONFIRM_PAYMENT && (
              <div className="space-y-8">
                <ChoosePaymentMethodStep
                  boxTitleText={t("Renew Membership Payment Summary")}
                  onChangePaymentMethod={onChangePaymentMethod}
                  amount={packagesTotalPrice}
                  airportText={displayInformationData?.airport}
                  method={method}
                  details={confirmPaymentDetails}
                  boxFooterExtra={
                    <p className="text-red-500 text-center text-sm lg:text-base mt-3 font-semibold">
                      {t(
                        "Please check the correctness of your license plate and expire date"
                      )}
                    </p>
                  }
                  isShowReceiveTaxInvoice
                  onChangeTaxInvoice={onChangeTaxInvoice}
                  isReceiveTaxInvoice={isReceiveTaxInvoice}
                  onChangeReceiveDt={onChangeReceiveDt}
                  taxInvoiceReceivedt={taxInvoiceReceiveDt}
                  isErrorDateTaxReceive={isErrorDateTaxReceive}
                  onErrorDateReceiveTax={onErrorDateReceiveTax}
                />
                <div className="flex flex-col lg:flex-row gap-4 lg:gap-10">
                  <ButtonAction
                    variant="secondary"
                    onClick={() => {
                      setStep(RENEW_STEP.SHOW_DETAIL);
                    }}
                  >
                    {t("Back")}
                  </ButtonAction>
                  <ButtonAction
                    onClick={onClickPaymentButtonHandler}
                    disabled={
                      updateRenewByLicensePlateData?.amount === 0 ||
                      (isReceiveTaxInvoice === "1" && !taxInvoiceReceiveDt) ||
                      isReceiveTaxInvoice === null ||
                      isErrorDateTaxReceive !== null
                    }
                  >
                    {t("Confirm Payment")}
                  </ButtonAction>
                </div>
              </div>
            )
          )}
          {step === RENEW_STEP.WAIT_PAYMENT && (
            <WaitPaymentStep
              requestData={paymentQRData}
              callBackWhenPaymentExpired={callBackWhenPaymentExpired}
              waitPaymentTopHeaderDisplay={waitPaymentTopHeaderDisplay}
              isRenew
            />
          )}
          {step === RENEW_STEP.COMPLETED_PAYMENT && (
            <RenewSummary
              isPendingPayment={isPendingPayment}
              onClickSeeDetailBtn={onClickSeeDetailBtnHandler}
              previewBoxData={{
                updateddt: completedDisplayData?.updateddt,
                onlinetransid: completedDisplayData?.onlinetransid,
                airport: displayInformationData.airport,
                licenseplate:
                  displayInformationData?.licensePlate +
                  " " +
                  displayInformationData?.licenseProvince,
                numberOfMonths: NUMBER_OF_MONTH_RENEW_BY_LICENSE_PLATE,
                startdate: isEffectiveDateConfig(configGlobal)
                  ? isSelectCurrentMonth(selectRenewMonth)
                    ? memberRenewByPmlsMemberOrderOrderItem?.effectivedate
                    : memberRenewByPmlsMemberOrderOrderItem?.startdate
                  : memberRenewByPmlsMemberOrderOrderItem?.startdate,
                enddate: memberRenewByPmlsMemberOrderOrderItem?.enddate,
                paymentType: method,
                totalPrice: packagesTotalPrice,
                parkingGroupName: displayInformationData?.parkingGroupName,
              }}
              isEffectiveDateConfig={isEffectiveDateConfig(configGlobal)}
            />
          )}
          {step === RENEW_STEP.BANK_EXPIREDT && (
            <PaymentExpired
              onClickMakeNewTransactionBtnHandler={
                onClickMakeNewTransactionBtnHandler
              }
              showBackToHomeBtn
            />
          )}
        </section>
      </PageContent>
      <ModalClosePaymentRenew
        open={isOpenModalClosePayment}
        showCloseIcon
        handleClose={closeModalClosePayment}
      />
    </>
  );
};

export default Index;
