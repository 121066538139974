import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LoginService from "../services/login-service";
import MemberService from "../services/member-service";
import AccountService from "../services/account-service";
import { AIRPORT } from "../constants";

//Get user from localstorage
const user = JSON.parse(localStorage.getItem("pmsuser"));
const returl = JSON.parse(localStorage.getItem("pmsreturl"));
const retdata = JSON.parse(localStorage.getItem("pmsretdata"));

const initialState = {
  user: user ? user : null,
  isLogin: false,
  returl: returl ? returl : "/",
  retdata: retdata ? retdata : null,
  retCounter: 0,
  profile: null, //mapp/account/myprofile
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  currentPmls: null, // ถ้ามีค่า เป็นสมาชิกลานจอด : ไม่เป็นสมาชิกลานจอด
  isMemberParking: false, //เช็คว่่าเป็นสมาชิกลาดจอดหรือไม่
  memberInfo: null,
  memberVehicles: null,
};

//Login user
export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  try {
    return await LoginService.login(user);
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      return thunkAPI.rejectWithValue("Login Failed");
    }
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    if (message) {
      alert(message);
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const logout = createAsyncThunk("auth/logout", async () => {
  LoginService.logout();
});

export const myprofile = createAsyncThunk(
  "auth/myprofile",
  async (user, thunkAPI) => {
    try {
      return await AccountService.getMyProfile(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const memberInfo = createAsyncThunk(
  "/mapp/member/info",
  async (params, thunkAPI) => {
    try {
      return await MemberService.getMMapMemberInfo(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.retCounter = 0;
    },
    setRetUrl: (state, action) => {
      state.returl = action.payload;
      localStorage.setItem("pmsreturl", JSON.stringify(action.payload));
    },
    setRetCounter: (state, action) => {
      state.retCounter = action.payload;
    },
    setRetData: (state, action) => {
      const retdata = { user: action.payload?.user, url: action.payload?.url };
      state.retdata = retdata;
      localStorage.setItem("pmsretdata", JSON.stringify(retdata));
    },
    clearRetData: (state) => {
      state.retdata = null;
      localStorage.removeItem("pmsretdata");
    },
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    setCurrentPmlsMemberData: (state, action) => {
      state.currentPmls = action.payload;
      state.isMemberParking = action.payload ? true : false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.isLogin = false;
        state.profile = null;
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.user = action.payload;
        state.isLogin = true;
        state.isLogin = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(myprofile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(myprofile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        const { data: payloadData } = action.payload;

        const data = {
          passwordstatus: payloadData.passwordstatus,
          passwordstatusmessage: payloadData.passwordstatusmessage,
          userMenu: payloadData.userMenu,
          usernotify: payloadData.usernotify,
          airport_1:
            payloadData?.pmlsMember.find(
              (item) => item.airportid === AIRPORT.ID.SUVARNABHUMI
            ) || null,
          airport_2:
            payloadData?.pmlsMember.find(
              (item) => item.airportid === AIRPORT.ID.DONMUANG
            ) || null,
        };

        state.currentPmls = data?.airport_1 ?? data?.airport_2 ?? null;

        if (state.currentPmls) {
          state.isMemberParking = true;
        } else {
          state.isMemberParking = false;
        }

        state.profile = {
          passwordstatus: payloadData.passwordstatus,
          passwordstatusmessage: payloadData.passwordstatusmessage,
          userMenu: payloadData.userMenu,
          usernotify: payloadData.usernotify,
          airport_1: data.airport_1,
          airport_2: data.airport_2,
        };
      })
      .addCase(myprofile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.profile = null;
      })
      //mmap/member/info
      .addCase(memberInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(memberInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const newMemberInfo = action.payload?.data?.tbMember;
        state.memberInfo = newMemberInfo;
        state.memberVehicles = newMemberInfo?.membervehicle;
      })
      .addCase(memberInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.profile = null;
      });
  },
});

export const {
  reset,
  setRetUrl,
  setRetCounter,
  setRetData,
  clearRetData,
  setIsLogin,
  setCurrentPmlsMemberData
} = authSlice.actions;
export default authSlice.reducer;
