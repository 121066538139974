// optionsUtils.js

const generateAirportOptions = (airports) => {
  return airports?.map((airport) => ({
    id: airport.airportid,
    display: airport.name_translate,
  }));
};

const generateVehicleParkingOptions = (parkings) => {
  return parkings
    ?.filter((d) => d.isactive)
    .map((item) => ({
      id: item.parkingid,
      display: item.name_translate,
    }));
};

const generateNationalityOptions = (nationality) => {
  return nationality?.map((nat) => ({
    id: nat.code,
    display: nat.nationality,
  }));
};

const generateTitleOptions = (title) => {
  return title
    ?.filter((title) => title?.isactive === 1)
    ?.map((title) => ({
      id: title?.titleid,
      display: title?.title,
    }));
};

const generateProvinceOptions = (province) => {
  return province?.map((item) => ({
    id: item.provinceid,
    display: item.nameth,
  }));
};

const generateOfficeTypeOptions = (officeType) => {
  return officeType
    ?.filter((item) => item?.isactive === 1)
    .map((item) => ({
      id: item.officeid,
      display: item.name,
    }));
};

const generateVehicleTypeOptions = (vehicleType) => {
  return vehicleType
    ?.filter((item) => item.isactive === 1)
    ?.map((item) => ({
      id: item.vehicletypeid,
      display: item.name_translate,
    }));
};

const generateMemberTypeOptions = (memberType) => {
  if (!memberType) return [];
  return memberType
    ?.filter((data) => data.membertypeid === 1)
    .map((item) => ({
      id: item.membertypeid,
      display: item.displayth,
    }));
};

// provinceLicense, license, ownerVehicle, carBrand

const generateProvinceLicenseOptions = (provinceLicense) => {
  if (!provinceLicense) return [];
  return provinceLicense?.map((item) => ({
    id: item.provinceid,
    display: item.name,
  }));
};

const generateLicenseOptions = (license) => {
  return license?.map((item) => ({
    id: item.licenseid,
    display: item.name,
  }));
};

const generateOwnerVehicleOptions = (ownerVehicle) => {
  return ownerVehicle?.map((item) => ({
    id: item.ownervehicleid,
    display: item.descripition,
  }));
};

const generateCarBrandOptions = (carBrand) => {
  return carBrand?.map((item) => ({
    id: item.carbrandid,
    display: item.name,
  }));
};

const generateDocTypeGroupOptions = (doctypeGroup) => {
  return doctypeGroup
    ?.filter((item) => item.isactive === 1 && item.docgroupid === 1)
    .map((item) => ({
      id: item.doctypeid,
      display: item.name,
    }));
};

export {
  generateAirportOptions,
  generateVehicleParkingOptions,
  generateNationalityOptions,
  generateTitleOptions,
  generateProvinceOptions,
  generateOfficeTypeOptions,
  generateVehicleTypeOptions,
  generateMemberTypeOptions,
  generateProvinceLicenseOptions,
  generateLicenseOptions,
  generateOwnerVehicleOptions,
  generateCarBrandOptions,
  generateDocTypeGroupOptions,
};
