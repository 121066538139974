import React from "react";
import Webcam from "react-webcam";
import { isMobile } from "react-device-detect";

const WebcamModal = ({
  showScanner,
  webcamRef,
  videoConstraints,
  handleScannerClose,
  handleSwitchCamera,
}) => {
  if (!showScanner) return null;

  return (
    <div className="fixed top-0 left-0 z-[3000] flex justify-center items-center w-full h-full">
      <div>
        <Webcam
          audio={false}
          videoConstraints={videoConstraints}
          ref={webcamRef}
          className="mx-auto"
          style={{
            maxWidth: "100%",
            maxHeight: "calc(100vh - 4rem)", // Adjust as needed
            width: "auto",
            height: "auto",
          }}
        />
        <div className="flex flex-col mt-2 items-center gap-2">
          <button
            onClick={handleScannerClose}
            className="w-full bg-slate-400 font-bold text-white"
          >
            Close Camera
          </button>
          {isMobile && (
            <button
              onClick={handleSwitchCamera}
              className="w-full bg-slate-400 font-bold text-white"
            >
              Switch Camera
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WebcamModal;
