import React from "react";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  getPaymentTranslationKeyByPaymentMethod,
  shouldDisableDateReceiveInvoice,
} from "../../utils";
import { METHOD_PAYMENT } from "../../hooks/usePayment";
import ReceivePaymentConfirmCheckbox from "./ReceivePaymentConfirmCheckbox";
import PaymentDetailBoxDefault from "./PaymentDetailBoxDefault";
import PaymentDetailBoxPaidFee from "./PaymentDetailBoxPaidFee";
import { RadioButtonUncheckedOutlined } from "@mui/icons-material";
import { DatePickerAOT } from "../Controls";
import dayjs from "dayjs";
import { DATE_FORMATS } from "../../constants";

// isPaidMode -> fee parking, qr ticket fee parking
// feeParkingCalculateData -> fee parking, qr ticket fee parking
// isEffectiveDateConfig  -> renew by license plate config global for render text message
// isShowReceiveTaxInvoice -> now use in renew only
const ChoosePaymentMethodStep = ({
  isShowReceiveTaxInvoice = false,
  isPaidMode,
  parkingFeeCalculateData = {},
  boxTitleText,
  method,
  onChangePaymentMethod,
  airportText,
  showAirport = true,
  details,
  amount,
  boxFooterExtra,
  receivePaymentConfirm = false, // true must send  receivePaymentConfirmFn
  receivePaymentConfirmFn = {
    emailIsValid: false,
    checkedSendEmail: false,
    sendEmailValue: "",
    setCheckedSendEmail: () => {},
    setSendEmailValue: () => {},
  },
  isReceiveTaxInvoice,
  isErrorDateTaxReceive,
  onErrorDateReceiveTax = (err) => {},
  onChangeTaxInvoice = (isReceive) => {},
  taxInvoiceReceivedt,
  onChangeReceiveDt = (receivedt) => {},
}) => {
  const { t } = useTranslation();

  return (
    <section className="w-full grid grid-cols-1 md:grid-cols-2 gap-10">
      <div className="col-span-1 space-y-3">
        <p className="font-medium text-xl">{t("Payment Method")}</p>
        <div className="space-y-5">
          <MethodBox
            label={t("Transfer Payment via QR Code")}
            onClick={() => {
              onChangePaymentMethod(METHOD_PAYMENT.QR_CODE);
            }}
            isSelected={method === METHOD_PAYMENT.QR_CODE}
          />
          {/* ? comment code for other payment methods in the future */}
          {/* <MethodBox
            onClick={() => {
              onClickMethodBoxHandler(METHOD_PAYMENT.CREDIT_CARD);
            }}
            isSelected={method === METHOD_PAYMENT.CREDIT_CARD}
          /> */}
        </div>
        <div
          id="receiveTaxInvoice"
          className={`${isShowReceiveTaxInvoice ? "block" : "hidden"}`}
        >
          <p className="font-medium text-xl pt-10">
            {t("Specify to receive tax invoice")}{" "}
            <span className="text-red-500">*</span>
          </p>
          <div className="space-y-5">
            <MethodBox
              label={t("Not receive tax invoice")}
              onClick={() => {
                onChangeTaxInvoice("0");
              }}
              isSelected={isReceiveTaxInvoice === "0"}
            />
            <MethodBox
              label={t("Receive tax invoice")}
              onClick={() => {
                onChangeTaxInvoice("1");
              }}
              isSelected={isReceiveTaxInvoice === "1"}
            />
            {isReceiveTaxInvoice === "1" && (
              <div>
                <DatePickerAOT
                  required
                  placeholder="dd/mm/yyyy"
                  label={t("Select Date")}
                  shouldDisableDate={shouldDisableDateReceiveInvoice}
                  handleChange={(e) => {
                    const date =
                      e !== null ? dayjs(e).format(DATE_FORMATS.DEFAULT) : "";
                    onChangeReceiveDt(date);
                  }}
                  val={taxInvoiceReceivedt}
                  onError={onErrorDateReceiveTax}
                />
                {isErrorDateTaxReceive !== null && (
                  <p className="text-red-500 text-sm pt-1">Unavailable date</p>
                )}
                <p className="pt-2">{t("Remark date receive tax invoice")}</p>
              </div>
            )}
          </div>
        </div>

        {receivePaymentConfirm && (
          <ReceivePaymentConfirmCheckbox
            emailIsValid={receivePaymentConfirmFn?.emailIsValid}
            checkedSendEmail={receivePaymentConfirmFn?.checkedSendEmail}
            sendEmailValue={receivePaymentConfirmFn?.sendEmailValue}
            setCheckedSendEmail={receivePaymentConfirmFn?.setCheckedSendEmail}
            setSendEmailValue={receivePaymentConfirmFn?.setSendEmailValue}
          />
        )}
      </div>
      <div className="col-span-1">
        {isPaidMode ? (
          <PaymentDetailBoxPaidFee
            titleIcon={"/images/feeparking/ico_name_carpark.svg"}
            titleText={boxTitleText}
            methodText={getPaymentTranslationKeyByPaymentMethod(method)}
            airportText={airportText}
            details={details}
            parkingFeeCalculateData={parkingFeeCalculateData}
            footerExtra={boxFooterExtra}
          />
        ) : (
          <PaymentDetailBoxDefault
            titleIcon={"/images/feeparking/ico_name_carpark.svg"}
            titleText={boxTitleText}
            methodText={getPaymentTranslationKeyByPaymentMethod(method)}
            showAirport={showAirport}
            airportText={airportText}
            details={details}
            amount={amount}
            footerExtra={boxFooterExtra}
          />
        )}
      </div>
    </section>
  );
};

const MethodBox = ({ onClick, isSelected, label }) => {
  return (
    <div
      className={`w-full border-[2px] ${
        isSelected
          ? "border-primaryBlue_500 bg-primaryBlue_500 bg-opacity-10"
          : "border-[#B3BBC2]"
      } h-[70px] rounded-lg cursor-pointer transition-all`}
      onClick={onClick}
    >
      <div className="flex justify-between items-center p-3 h-full">
        <p
          style={{
            color: isSelected ? "#3D9AEE" : "black",
          }}
        >
          {label}
        </p>
        <div className={`text-primaryBlue_500`}>
          {isSelected ? <CheckCircleIcon /> : <RadioButtonUncheckedOutlined />}
        </div>
      </div>
    </div>
  );
};

export default ChoosePaymentMethodStep;
