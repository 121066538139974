import React from "react";
import BoxNews from "../components/HomeAOT/BoxNews";
import { Box, Button, FormGroup, styled } from "@mui/material";
import DropdownAOT from "../components/Controls/DropdownAOT";
import InputTextFieldAOT from "../components/Controls/InputTextFieldAOT";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { useQueries } from "@tanstack/react-query";
import { fetchContentPreviewFilter } from "./HomeAOT";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { CONTENT_TYPE_CODE } from "../constants";
import { useState } from "react";
import { isUndefined } from "lodash-es";

const BoxSearch = styled(Box)(({ theme }) => ({
  background: "#E9F0F5 0% 0% no-repeat padding-box",
  height: "17vh",
  padding: "0 15.688rem",
  display: "flex",
  alignItems: "center",
  transform: "translate(0, -7px)",
  [theme.breakpoints.down("lg")]: {
    padding: "0 10rem",
    height: "15vh",
  },
  [theme.breakpoints.down("md")]: {
    padding: "0 5rem",
    height: "13vh",
  },
}));

const BoxInputSearch = styled(Box)(() => ({
  height: "88px",
  display: "flex",
  border: "1px solid #707070",
  alignItems: "center",
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  width: "60%",
}));

const MenuButton = styled((props) => <Box component="img" {...props} />)(
  ({ theme }) => ({
    width: "100%",
    height: "300px",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      height: "150px",
    },
  })
);

const IconSearch = styled(SearchIcon)(() => ({
  color: "#898A8F",
  width: "25px",
  height: "26px",
  margin: "0 1rem",
}));

const ButtonSearch = styled(Button)(() => ({
  background: "#245AB1 0% 0% no-repeat padding-box",
  width: "20%",
  height: "100%",
  font: "normal normal 600 22px/33px Noto Sans Thai",
  letterSpacing: 0,
  color: "#FFFFFF",
  borderRadius: "0px 39px 39px 0px",
  "&:hover": {
    background: "#245AB1 0% 0% no-repeat padding-box",
  },
}));

const AllNews = () => {
  const { t, i18n } = useTranslation();
  const { pmlsId } = useSelector((state) => state.airport);
  const [filterName, setFilterName] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  const contentPreviewFilterQueries = useQueries({
    queries: [
      {
        queryKey: [
          "CONTENT_PREVIEW_FILTER_INFO",
          isSearch,
          i18n.language,
          pmlsId,
        ],
        queryFn: () =>
          fetchContentPreviewFilter(
            pmlsId,
            CONTENT_TYPE_CODE.INFO,
            filterName,
            undefined,
            i18n.language
          ),
        enabled: !isUndefined(pmlsId),
        select: (data) => data?.data?.allTbContentHdr,
      },
      {
        queryKey: [
          "CONTENT_PREVIEW_FILTER_PROMO",
          isSearch,
          i18n.language,
          pmlsId,
        ],
        queryFn: () =>
          fetchContentPreviewFilter(
            pmlsId,
            CONTENT_TYPE_CODE.PROMO,
            filterName,
            undefined,
            i18n.language
          ),
        enabled: !isUndefined(pmlsId),
        select: (data) => data?.data?.allTbContentHdr,
      },
      {
        queryKey: [
          "CONTENT_PREVIEW_FILTER_NEWS",
          isSearch,
          i18n.language,
          pmlsId,
        ],
        queryFn: () =>
          fetchContentPreviewFilter(
            pmlsId,
            CONTENT_TYPE_CODE.NEWS,
            filterName,
            undefined,
            i18n.language
          ),
        enabled: !isUndefined(pmlsId),
        select: (data) => data?.data?.allTbContentHdr,
      },
    ],
  });

  const contentPreviewInformationData = useMemo(() => {
    return contentPreviewFilterQueries[0]?.data?.map((item) => ({
      contentid: item.contentid,
      previewcontenttext:
        item.detail?.length > 0 ? item.detail[0].previewcontenttext : "",
      previewimage:
        item.detail?.length > 0
          ? `data:image/${item.detail[0].previewimage_data.imageext};base64,${item.detail[0].previewimage_data.data_base64}`
          : "",
    }));
  }, [contentPreviewFilterQueries]);

  const contentPreviewPromoData = useMemo(() => {
    return contentPreviewFilterQueries[1]?.data?.map((item) => ({
      contentid: item.contentid,
      previewcontenttext:
        item.detail?.length > 0 ? item.detail[0].previewcontenttext : "",
      previewimage:
        item.detail?.length > 0
          ? `data:image/${item.detail[0].previewimage_data.imageext};base64,${item.detail[0].previewimage_data.data_base64}`
          : "",
    }));
  }, [contentPreviewFilterQueries]);

  const contentPreviewNewsData = useMemo(() => {
    return contentPreviewFilterQueries[2]?.data?.map((item) => ({
      contentid: item.contentid,
      previewcontenttext:
        item.detail?.length > 0 ? item.detail[0].previewcontenttext : "",
      previewimage:
        item.detail?.length > 0
          ? `data:image/${item.detail[0].previewimage_data.imageext};base64,${item.detail[0].previewimage_data.data_base64}`
          : "",
    }));
  }, [contentPreviewFilterQueries]);

  return (
    <>
      <MenuButton src="/images/allnews/img_bgnews.png" />
      <BoxSearch>
        <FormGroup
          sx={{
            width: "100%",
            height: "88px",
            display: "flex",
            alignContent: "stretch",
          }}
        >
          <Box sx={{ width: "25%" }}>
            <DropdownAOT
              label="สิทธิพิเศษ"
              sxa={{
                height: "88px",
                borderTop: "1px solid #707070",
                borderLeft: "1px solid #707070",
                borderBottom: "1px solid #707070",
                borderRight: "none",
                paddingLeft: "0.2rem",
                borderRadius: "39px 0px 0px 39px",
              }}
            ></DropdownAOT>
          </Box>
          <BoxInputSearch>
            <IconSearch />
            <InputTextFieldAOT
              label="พิมพ์คำที่ค้นหา"
              sxa={{
                // height: "85px",
                width: "100%",
                border: "none",
                margin: "1px 0",
              }}
              val={filterName}
              handleChange={(e) => setFilterName(e.target.value)}
              sxb={{
                "& .MuiInputBase-root": {
                  borderRadius: "10px",
                  border: "none",
                },
              }}
            />
          </BoxInputSearch>
          <ButtonSearch
            onClick={() => {
              setIsSearch((prev) => !prev);
            }}
          >
            ค้นหา
          </ButtonSearch>
        </FormGroup>
      </BoxSearch>
      <BoxNews
        header={t("Campaign and Promotion")}
        data={contentPreviewPromoData}
        type={CONTENT_TYPE_CODE.PROMO}
        isLoading={contentPreviewFilterQueries[1].isLoading}
      />
      <BoxNews
        header={t("Press Release")}
        data={contentPreviewInformationData}
        type={CONTENT_TYPE_CODE.INFO}
        isLoading={contentPreviewFilterQueries[0].isLoading}
      />
      <BoxNews
        header={"AOT News"}
        data={contentPreviewNewsData}
        type={CONTENT_TYPE_CODE.NEWS}
        isLoading={contentPreviewFilterQueries[2].isLoading}
      />
    </>
  );
};

export default AllNews;
