/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Container, CssBaseline, ThemeProvider } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { themeApp } from "./theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { setPmlsId } from "./features/airportSlice";
import {
  logout,
  memberInfo,
  myprofile,
  setCurrentPmlsMemberData,
} from "./features/authSlice";
import { AIRPORT, LANGUAGE } from "./constants";
// import ChangeVehiclePage from "./pages/Auth/ChangeVehicle";
// import FindMyCarPage from "./pages/FindMyCarPage";
import AllNews from "./pages/AllNews";
import Checking from "./pages/Auth/Checking";
import ContentDetail from "./pages/ContentDetail";
import PaymentHistoryPage from "./pages/Auth/PaymentHistory";
import ContentCampaign from "./pages/ContentCampaign";
import ContentInformation from "./pages/ContentInformation";
import ContentAOTNews from "./pages/ContentAOTNews";
import QRTicketFeeParkingPage from "./pages/QRTicketFeeParkingPage";
import HomeAOT from "./pages/HomeAOT";
import FindParking from "./pages/FindParking";
import CalculatorParking from "./pages/CalculatorParking";
import FeeParking from "./pages/FeeParking";
import ReservedParking from "./pages/ReservedParking";

import LoginUser from "./pages/Auth/LoginUser";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import RegisterUser from "./pages/Auth/RegisterUser";
import RegisterParking from "./pages/Auth/RegisterParking";

import MyProfile from "./components/MyProfile/MyProfile";
import Renew from "./pages/Renew";
import History from "./components/History/History";
import PaymentDetail from "./components/Payment/PaymentDetail";
import PaymentTaxInvoice from "./components/Payment/PaymentTaxInvoice";
import MyProfileChangPassword from "./components/MyProfile/MyProfileChangPassword";

// import PrepaidLayout from "./layouts/PrepaidLayout";
// import LoginParking from "./pages/Auth/LoginParking";
// import ChangePassword from "./pages/Auth/ChangePassword";
// import Payment from "./components/Payment/Payment";
// import DetailCard from "./components/Prepaid/DetailCard";
// import HistoryPrepaid from "./components/Prepaid/HistoryPrepaid";
// import CancelCard from "./components/Prepaid/CancelCard";
// import TopUpCard from "./components/Prepaid/TopUpCard";
// import CardPrepaid from "./components/Prepaid/CardPrepaid";

// import Notified from "./components/Notified/Notified";
import RequestReceiptPage from "./pages/RequestReceiptPage";

import Cookies from "js-cookie";
import CookiePolicyPopup from "./components/CookiePolicyPopup";
import CookiePolicyPage from "./pages/CookiePolicyPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import SettingNotification from "./pages/Auth/SettingNotification";

import { ToastContainer } from "react-toastify";
import PinMyCarPage from "./pages/PinMyCarPage";
import RenewVisitor from "./components/RenewVisitor/RenewVisitor";
import { fetchMasterData } from "./services/fetchMasterData";
import MyTicketPage from "./pages/Auth/MyTicketPage";
import MyTicketDetail from "./components/MyTicket/MyTicketDetail";
import { useTranslation } from "react-i18next";
import { getLangIdFormPMLSlang } from "./utils";
import ParkingDensityWebView from "./pages/ParkingDensityWebView";

import CloseFeeParking from "./pages/close/CloseFeeParking.jsx";
import CloseQRTicketFeeParkingPage from "./pages/close/CloseQRTicketFeeParkingPage.jsx";
import CloseRequestReceiptPage from "./pages/close/CloseRequestReceiptPage.jsx";

import {
  LocalStorageKey,
  setItemLocalStorage,
} from "./utils/localStorageUtil.js";
import useOnlineConfig from "./hooks/useOnlineConfig.js";
import ModalCloseWebsite from "./components/Modal/ModalCloseWebsite.jsx";

import "dayjs/locale/th";
import "dayjs/locale/en";
import dayjs from "dayjs";

const HomeLayout = React.lazy(() => import("./layouts/HomeLayout"));
const LoginLayout = React.lazy(() => import("./layouts/LoginLayout"));
const MemberLayout = React.lazy(() => import("./layouts/MemberLayout"));
// const ProtectedLayout = React.lazy(() => import("./layouts/ProtectedLayout"));

//? when payment close
export const DISABLED_PAGE = {
  FEE_PARKING: false, // show image banner
  QR_TICKET_FEE_PARKING: false, // show image banner
  REQUEST_RECEIPT: false, // show modal
  LOGIN: true, //https://dev.azure.com/mmats/AOT-CARPARK/_workitems/edit/2074/
  REGISTER: true, // https`://dev.azure.com/mmats/AOT-CARPARK/_workitems/edit/2074/
  MEMBER_PROFILE: true, // https://dev.azure.com/mmats/AOT-CARPARK/_workitems/edit/2074/
  REGISTER_MEMBER_PARKING: true, // https://dev.azure.com/mmats/AOT-CARPARK/_workitems/edit/2074/
  MEMBER_INBOX: true, // https://dev.azure.com/mmats/AOT-CARPARK/_workitems/edit/2074/
};

function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { getOnlineConfig, isCloseApp } = useOnlineConfig();

  const { user, currentPmls, profile, isError, message } = useSelector(
    (state) => state.auth
  );
  const { pmlsId } = useSelector((state) => state.airport);

  const [showCookiePopup, setShowCookiePopup] = useState(false);

  useEffect(() => {
    if (pmlsId) {
      setItemLocalStorage(LocalStorageKey.PMLSID, pmlsId);
      getOnlineConfig(pmlsId);
    }
  }, [pmlsId]);

  useEffect(() => {
    const setDayjsLocale = () => {
      dayjs.locale(
        i18n.language === LANGUAGE.CODE.TH ? LANGUAGE.CODE.TH : LANGUAGE.CODE.EN
      );
    };
    setDayjsLocale();
  }, [i18n.language]);

  const handleCloseCookiePopup = () => {
    setShowCookiePopup(false);
  };

  //TODO: hardcode path /dmk/policy or /bkk/policy not show popup accept cookie
  useEffect(() => {
    const cookieAccepted = Cookies.get("cookieAccepted");
    const isPolicyPath = ["/dmk/policy", "/bkk/policy"].includes(
      location.pathname
    );
    if (!cookieAccepted && !isPolicyPath) setShowCookiePopup(true);
  }, [location.pathname]);

  //? set pmlsid form params
  useEffect(() => {
    if (AIRPORT.IS_ALL_AVAILABLE) {
      const searchParams = new URLSearchParams(location.search);
      const pmlsid = searchParams.get("pmlsid");
      if (pmlsid) dispatch(setPmlsId(pmlsid));
    }
  }, [location, dispatch]);

  useEffect(() => {
    if (currentPmls && pmlsId) {
      dispatch(
        memberInfo({ pmlsid: pmlsId, memberid: currentPmls?.pmlsmemberid })
      );
    }
  }, [currentPmls, pmlsId]);

  useEffect(() => {
    if (profile) {
      const memberData =
        pmlsId === AIRPORT.ID.SUVARNABHUMI
          ? profile?.airport_1
          : profile?.airport_2;
      dispatch(setCurrentPmlsMemberData(memberData));
    }
  }, [pmlsId, profile]);

  //? fetch all master data
  useEffect(() => {
    if (!AIRPORT.IS_ALL_AVAILABLE) return;
    const excludedPaths = ["/parking-density", "/ticket"];
    if (!excludedPaths.includes(location.pathname)) {
      dispatch(fetchMasterData(getLangIdFormPMLSlang(i18n.language)));
    }
  }, [i18n.language]);

  //? dispatch myprofie if auth
  useEffect(() => {
    if (!isError) return;
    if (message === "Unauthorized") dispatch(logout());
    if (user) dispatch(myprofile({ username: user.username }));
  }, [isError, message, user, dispatch]);

  return (
    <ThemeProvider theme={themeApp}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <Container maxWidth={false} disableGutters>
          <Routes>
            <Route
              path="/"
              element={<Navigate to={AIRPORT.CODE_PATH.BKK} replace={true} />}
            />
            <Route
              path="requestreceipt"
              element={
                DISABLED_PAGE.REQUEST_RECEIPT ? (
                  <CloseRequestReceiptPage />
                ) : (
                  <RequestReceiptPage />
                )
              }
            />
            <Route
              path="ticket"
              element={
                DISABLED_PAGE.QR_TICKET_FEE_PARKING ? (
                  <CloseQRTicketFeeParkingPage />
                ) : (
                  <QRTicketFeeParkingPage />
                )
              }
            />
            <Route
              path="/parking-density"
              element={<ParkingDensityWebView />}
            />
            <Route path="/:airport" element={<HomeLayout />}>
              {/* Nested Routes */}
              <Route index element={<HomeAOT />} />
              <Route path="policy" element={<CookiePolicyPage />} />
              <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="findparking" element={<FindParking />} />
              <Route
                path="feeparking"
                element={
                  DISABLED_PAGE.FEE_PARKING ? (
                    <CloseFeeParking />
                  ) : (
                    <FeeParking />
                  )
                }
              />
              <Route path="calparking" element={<CalculatorParking />} />
              <Route path="pinmycar" element={<PinMyCarPage />} />
              <Route path="reservedparking" element={<ReservedParking />} />
              <Route path="renew" element={<Renew />} />
              <Route path="allnews" element={<AllNews />} />

              {/* Content Routes */}
              <Route path="content">
                <Route path="" element={<AllNews />} />
                <Route path=":id" element={<ContentDetail />} />
                <Route path="campaign" element={<ContentCampaign />} />
                <Route path="information" element={<ContentInformation />} />
                <Route path="aot-news" element={<ContentAOTNews />} />
              </Route>
            </Route>

            {!DISABLED_PAGE.LOGIN ||
            !DISABLED_PAGE.REGISTER ||
            !DISABLED_PAGE.REGISTER_MEMBER_PARKING ? (
              <Route path="/auth" element={<LoginLayout />}>
                <Route path="user/login" element={<LoginUser />} />
                <Route path="user/forgot" element={<ForgotPassword />} />
                <Route path="user/resetpassword" element={<ResetPassword />} />
                <Route path="user/register" element={<RegisterUser />} />
                <Route path="parking/register" element={<RegisterParking />} />
                {/* <Route path="parking/login" element={<LoginParking />} /> */}
                {/* ? /auth/user/resetpassword */}
                {/* <Route path="changepassword" element={<ChangePassword />} />  */}
              </Route>
            ) : null}

            {!DISABLED_PAGE.MEMBER_PROFILE && (
              <Route
                path="/members"
                element={
                  user ? (
                    <MemberLayout />
                  ) : (
                    <Navigate to="/auth/login" replace />
                  )
                }
              >
                <Route path="myprofile" element={<MyProfile />} />
                <Route
                  path="myprofile/changpassword/"
                  element={<MyProfileChangPassword />}
                />
                <Route path="renew" element={<Renew />} />
                <Route path="history" element={<History />} />
                <Route path="payment" element={<PaymentHistoryPage />} />
                <Route path="payment/detail/" element={<PaymentDetail />} />
                <Route
                  path="payment/taxinvoice/"
                  element={<PaymentTaxInvoice />}
                />
                <Route path="checking" element={<Checking />} />
                <Route
                  path="setting-notification"
                  element={<SettingNotification />}
                />
                <Route path="myticket">
                  <Route path="" element={<MyTicketPage />} />
                  <Route path=":id" element={<MyTicketDetail />} />
                </Route>
              </Route>
            )}

            {/* hide ก่อน */}
            {/* <Route
              path="/prepaid"
              element={
                <PrepaidLayout
                  user={user}
                  airport={airport}
                  setAirport={setAirport}
                />
              }
            >
              <Route path="cardprepaid" element={<CardPrepaid />} />
              <Route path="cardprepaid/detail" element={<DetailCard />} />
              <Route path="cardprepaid/history" element={<HistoryPrepaid />} />
              <Route path="cardprepaid/cancel" element={<CancelCard />} />
              <Route path="cardprepaid/topupcard" element={<TopUpCard />} />
            </Route> */}

            <Route path="/renewbycard" element={<RenewVisitor />} />
            <Route path="*" element={<Navigate to="/" replace={true} />} />
          </Routes>
          <CookiePolicyPopup
            open={showCookiePopup}
            onClose={handleCloseCookiePopup}
          />
          <ModalCloseWebsite
            open={isCloseApp}
            handleClose={() => {}}
            showCloseIcon={false}
          />
        </Container>
      </LocalizationProvider>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
