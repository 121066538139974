import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const ModalClosePaymentQRFeeParking = ({
  handleClose,
  open,
  showCloseIcon = true,
}) => {
  const { t } = useTranslation();
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: 650,
          bgcolor: "white",
          boxShadow: 24,
          p: 1,
        }}
      >
        <div className="w-full relative">
          {showCloseIcon && (
            <div className="absolute -right-4 -top-4">
              <div className="w-6 h-6 rounded-full bg-white hover:bg-primaryBlue_500">
                <button onClick={handleClose}>
                  <CloseIcon className="w-6 h-6 hover:text-white" />
                </button>
              </div>
            </div>
          )}
          <img className="w-full h-11" src="/images/modal/bar.png" alt="bar" />
          <div className="absolute right-0 top-14">
            <img
              src="/images/modal/aotsmartcarpark_2.png"
              alt="aotsmartcarpark_img"
              className="w-[100px] h-auto"
            />
          </div>
          <div className="px-6 py-10 pb-10">
            <div className="space-y-3 font-medium">
              <p>{t("noti greeting", { lng: "th" })}</p>
              <p>{t("noti close parkingfee detail", { lng: "th" })}</p>
              <p>{t("noti ending", { lng: "th" })}</p>
            </div>
            <hr className="my-5" />
            <div className="space-y-3 font-medium">
              <p>{t("noti greeting", { lng: "en" })}</p>
              <p>
              {t("noti close parkingfee detail", { lng: "en" })}
              </p>
              <p>
              {t("noti ending", { lng: "en" })}
              </p>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalClosePaymentQRFeeParking;
