/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { DATE_FORMATS, REGEX } from "../constants";
import { useMutation } from "@tanstack/react-query";
import PaymentService from "../services/payment-service";
import ONLINE_PAYMENT_STATUS from "../constants/payment";
import { toast } from "react-toastify";
import ParkingFeeService from "../services/parkingfee-service";
import {
  getAirportNameFormPmlsAndLang,
  getLicensePlateProvinceText,
  getPaymentTranslationKeyByPaymentMethod,
  successRequest,
  toastErr,
  toastWarning,
} from "../utils";
import QRTicketFeeParkingPreviewPaymentDetail from "../components/QRTicket/QRTicketFeeParkingPreviewPaymentDetail";
import {
  INTERVAL_QR_QUERY,
  PROCESS_PAYMENT_STATUS,
  STATUS_PAYMENT,
  usePayment,
} from "../hooks/usePayment";
import ButtonAction from "../components/Base/ButtonAction";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../components/LoadingSpinner";
import { WaitPaymentStep } from "../components/Payment";
import dayjs from "dayjs";
import BoxFeeParking4 from "../components/FeeParking/BoxFeeParking4";
import LanguagesDropdown from "../components/LanguagesDropdown";
import PaymentPendingVerifyBox from "../components/Payment/PaymentPendingVerifyBox";
import PaymentCancelPendingSummary from "../components/Payment/PaymentCancelPendingSummary";
import DialogPaymentConfirmCancel from "../components/Payment/DialogPaymentConfirmCancel";
import { FEE_PARKING_RESPONSE_CODE } from "../constants/response-code";
import NotFoundTicket from "../components/FeeParking/NotFoundTicket";
import ErrorCalculate from "../components/FeeParking/ErrorCalculate";
import { STEP_FEE_PARKING } from "./FeeParking";
import PaymentExpired from "../components/Payment/PaymentExpired";
import { useDispatch, useSelector } from "react-redux";
import { setPmlsId } from "../features/airportSlice";
import { convertGmtToClientTimeZone } from "../utils/format";
import NotFoundEntryExit from "../components/FeeParking/NotFoundEntryExit";
import useOnlineConfig, {
  isOnlineConfigStatusClose,
} from "../hooks/useOnlineConfig";
import ModalClosePaymentQRFeeParking from "../components/Modal/ModalClosePaymentQRFeeParking";
import useModal from "../hooks/useModal";

export const isTimeoutTransactionByBankExpiredt = (
  parkingpaymentstatus,
  bankexpiredt
) => {
  const _bankexpiredt = convertGmtToClientTimeZone(bankexpiredt);
  if (
    parkingpaymentstatus === PROCESS_PAYMENT_STATUS.REQUEST &&
    _bankexpiredt &&
    dayjs(_bankexpiredt).isBefore(dayjs())
  ) {
    return true;
  } else {
    return false;
  }
};

const findDataFromQRCode = (qrCode) => {
  const split = qrCode.split("|");
  const pmlsId = split[0];
  const ticketNo = split[8];
  return { pmlsId, ticketNo };
};

const QRTicketFeeParkingPage = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { pmlsId } = useSelector((state) => state.airport);
  const dispatch = useDispatch();

  const { getOnlineConfig, onlineConfigData, isCloseApp } = useOnlineConfig();

  const {
    isOpen: isOpenModalClosePayment,
    openModal: openModalClosePayment,
    closeModal: closeModalClosePayment,
  } = useModal();

  const isCloseOnlinePayment = useMemo(() => {
    return isOnlineConfigStatusClose(onlineConfigData?.isonlinepayment);
  }, [onlineConfigData]);

  const {
    method,
    checkedSendEmail,
    paymentStatus,
    receivePaymentConfirmEmail,
    isPaymentPendingTransaction,
    onlinetransid,
    openCancelTransactionDialog,
    receiptData,
    setReceiptData,
    setOnlinetransid,
    onChangeIntervalId,
    onChangePaymentStatus,
    onSuccessPaymentHandler,
    onFailedPaymentHandler,
    onStopPaymentHandler,
    onChangeReceivePaymentConfirmViaEmail,
    onChangeCheckedBoxSendEmailHandler,
    setIsPaymentPendingTransaction,
    setOpenCancelTransactionDialog,
  } = usePayment();

  const [step, setStep] = useState(STEP_FEE_PARKING.SHOW_DETAIL);
  const [ticketNo, setTicketNo] = useState("");

  const resetStateToInitial = () => {
    onStopPaymentHandler();
    setIsPaymentPendingTransaction(false);
    setReceiptData(undefined);
    setOpenCancelTransactionDialog(false);
  };

  useEffect(() => {
    if (isCloseApp) return;
    if (isCloseOnlinePayment) {
      openModalClosePayment();
    } else {
      closeModalClosePayment();
    }
  }, [isCloseOnlinePayment, isCloseApp]);

  useEffect(() => {
    if (step === STEP_FEE_PARKING.SHOW_DETAIL) {
      const searchParams = new URLSearchParams(location.search);
      const qrCode = searchParams.get("id");
      if (qrCode) {
        const { ticketNo, pmlsId } = findDataFromQRCode(qrCode);
        if (ticketNo && pmlsId) {
          getOnlineConfig(pmlsId);
          resetStateToInitial();
          setTicketNo(ticketNo.trim());
          dispatch(setPmlsId(Number(pmlsId)));
          parkingFeeCheckPendingPaymentMutate.mutate({
            pmlsid: pmlsId,
            ticketno: ticketNo,
          });
        } else {
          toast.error("QR code is invalid or missing data!");
        }
      } else {
        toast.error("No QR code found!");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, step]);

  const parkingFeeCheckPendingPaymentMutate = useMutation({
    mutationFn: (body) => PaymentService.parkingFeeCheckPendingPayment(body),
    onSuccess: async (res) => {
      const { ouMessage, ouReasonCode, data } = res;
      const parkingPayment = data?.parkingpayment[0] || {};

      //? 2534 - จ่ายค่าจอดรถ: ค้นตั๋วหรือสร้างใบเสร็จ แล้วเจอ error Entry Exit Not Found
      if (
        parkingPayment?.createreceipterrormessage === "Entry Exit Not Found"
      ) {
        setStep(STEP_FEE_PARKING.ENTRY_EXIT_NOT_FOUND);
        return;
      }

      const isNoPendingOrFinalized =
        (ouMessage === "Parking Pay Hdr Req Not Found" &&
          ouReasonCode === "100207") ||
        parkingPayment.parkingpaymentstatus === PROCESS_PAYMENT_STATUS.CANCEL ||
        parkingPayment.parkingpaymentstatus === PROCESS_PAYMENT_STATUS.CONFIRM;

      //? เลยเวลาจ่ายเงิน ให้ cancel payment transactions ล่าสุดทิ่งแล้วยิง calculate ใหม่
      const isRequestWithExpiredDate = isTimeoutTransactionByBankExpiredt(
        parkingPayment.parkingpaymentstatus,
        parkingPayment.bankexpiredt
      );

      if (isNoPendingOrFinalized) {
        await calculateParkingFee();
      } else if (isRequestWithExpiredDate) {
        await cancelAndRecalculateFee(parkingPayment?.onlinetransid);
      } else if (
        parkingPayment.parkingpaymentstatus === PROCESS_PAYMENT_STATUS.REQUEST
      ) {
        if (
          !parkingPayment?.bankexpiredt &&
          parkingPayment.createreceipterrormessage !== ""
        ) {
          toastErr(parkingPayment.createreceipterrormessage);
        }
        setOnlinetransid(parkingPayment?.onlinetransid);
        setIsPaymentPendingTransaction(true);
        setStep(STEP_FEE_PARKING.PENDING_TRANSACTION);
      } else {
        toastErr(ouMessage);
      }
    },
    onError: (res) => {
      toastErr(res?.message);
    },
  });

  const parkingFeeCalculateMutate = useMutation({
    mutationFn: (body) => ParkingFeeService.parkingFeeCalculate(body),
    onSuccess: (response) => {
      const { responsecode, issuccess } = response || {};
      if (issuccess && responsecode) {
        if (
          [
            FEE_PARKING_RESPONSE_CODE.CAR_GOT_EXIT_PARKING_ALREADY,
            FEE_PARKING_RESPONSE_CODE.NOT_FOUND,
          ].includes(responsecode)
        ) {
          setStep(STEP_FEE_PARKING.NOT_FOUND);
        } else if (responsecode === FEE_PARKING_RESPONSE_CODE.CALCULATE_ERROR) {
          setStep(STEP_FEE_PARKING.ERROR_CALCULATE);
        }
      } else {
        toastErr(response?.ouMessage);
      }
    },
    onError: (response) => {
      toastErr(response?.message);
    },
  });

  const parkingFeeCancelTransactionMutate = useMutation({
    mutationFn: (body) => PaymentService.parkingFeeCheckCancelTransaction(body),
    onSuccess: (res) => {
      if (!successRequest(res)) {
        //? ชำระเงินแล้วแต่กด Cancel รายการชำระเงินที่ค้างไว้
        if (res?.ouReasonCode === "100210") {
          checkPaymentResult(onlinetransid, true);
        } else {
          toastErr(res?.ouMessage);
        }
      } else if (successRequest(res)) {
        setStep(STEP_FEE_PARKING.CANCELED_TRANSACTION);
      }
    },
    onError: (res) => {
      toastErr(res?.message);
    },
  });

  const createPaymentQRMutate = useMutation({
    mutationFn: async (payload) =>
      PaymentService.createParkingFeePaymentQR(payload),
    onSuccess: (response) => {
      if (successRequest(response) && response?.data?.onlinetransid) {
        setOnlinetransid(response?.data?.onlinetransid);
        setStep(STEP_FEE_PARKING.WAIT_PAYMENT);
        onChangePaymentStatus(STATUS_PAYMENT.WAIT);
      } else {
        if (
          response?.ouReasonCode === "100134" &&
          response?.ouMessage === "Entry Exit Not Found"
        ) {
          toast.error(t("Parkingfee changed"), {
            position: "top-center",
            autoClose: 1200,
            onClose: () => window.location.reload(),
          });
        } else {
          toastErr(response?.ouMessage);
          onFailedPaymentHandler();
        }
      }
    },
    onError: (response) => {
      toastErr(response);
      onFailedPaymentHandler();
    },
    select: (data) => data?.data,
  });

  //loop payment result
  const checkPaymentResultMutate = useMutation({
    mutationFn: async (payload) =>
      PaymentService.parkingFeeCheckResultPayment({
        pmlsid: pmlsId,
        onlinetransid: payload.onlinetransid,
      }),
    onSuccess: async (response, variable) => {
      if (variable?.skipResponseLogic) {
        return;
      }
      const resultPaymentData = response?.data?.TbParkingPayHdrReq || {};
      //? เลยเวลาชำระเงิน -> cancelAndRecalculateFee() -> first step show detail
      if (
        isTimeoutTransactionByBankExpiredt(
          parkingFeeCheckPendingData?.parkingpaymentstatus,
          parkingFeeCheckPendingData?.bankexpiredt
        )
      ) {
        callBackWhenPaymentExpired(resultPaymentData?.onlinetransid);
      } else {
        //? ถ้าเป็นการเรียกมาจากการกด Cancel Transaction ที่มีการชำระเงินแล้ว จะเข้าเงื่อนไขนี้ได้เลย
        if (
          (successRequest(response) && resultPaymentData) ||
          variable.isCancelTransaction
        ) {
          if (
            resultPaymentData?.status === PROCESS_PAYMENT_STATUS.CONFIRM ||
            variable?.isCancelTransaction
          ) {
            createReceipt(resultPaymentData).then(() => {
              if (variable?.isCancelTransaction) {
                toastErr(t("Your payment has already been paid"));
              }
            });
          }
        }
      }
    },
    onError: (response) => {
      toastErr("Error occurred!, Please contact admin :", response?.message);
      onFailedPaymentHandler();
      setStep(STEP_FEE_PARKING.SHOW_DETAIL);
    },
  });

  const qrQueryStatusMutate = useMutation({
    mutationFn: async (payload) => PaymentService.queryQRStatus(payload),
    onSuccess: async (response) => {
      const onlinePaymentData = response?.data?.tbonlinepayment;
      if (
        response?.ouIsComplete === 1 &&
        onlinePaymentData?.responsestatus === "200" &&
        onlinePaymentData?.responsedt !== null
      ) {
        setOnlinetransid(onlinePaymentData?.onlinetransid);
        checkPaymentResultMutate
          .mutateAsync({
            onlinetransid: onlinePaymentData?.onlinetransid,
            skipResponseLogic: true,
          })
          .then((res) => {
            if (successRequest(res)) {
              createReceipt(res?.data?.TbParkingPayHdrReq);
            }
          });
      } else if (onlinePaymentData?.responsestatus !== null) {
        const paymentStatusDesc =
          ONLINE_PAYMENT_STATUS[Number(onlinePaymentData?.responsestatus)]
            ?.description;
        toastErr(paymentStatusDesc);
        onFailedPaymentHandler();
        setStep(STEP_FEE_PARKING.SHOW_DETAIL);
      } else if (onlinePaymentData?.responsestatus === null) {
        //ยังไม่จ่าย เรียก /mapp/payment/online/parkingfee/checkpaymentresult (loop) ต่อ
        toastWarning(t("Not found your payment"));
        onChangePaymentStatus(STATUS_PAYMENT.WAIT); //? useEffect for loop
      }
    },
    onError: () => {
      toastErr("Error occurred!, Please contact admin.");
      onFailedPaymentHandler();
      setStep(STEP_FEE_PARKING.SHOW_DETAIL);
    },
  });

  const createReceiptParkingFee = useMutation({
    mutationFn: (payload) => PaymentService.createReceiptParkingFee(payload),
    onSuccess: async (response) => {
      if (successRequest(response)) {
        onSuccessPaymentHandler();
        setReceiptData(response?.data);
        await calculateParkingFee().then(() => {
          setStep(STEP_FEE_PARKING.RESULT_PAYMENT);
        });
      } else {
        //? 2534 - จ่ายค่าจอดรถ: ค้นตั๋วหรือสร้างใบเสร็จ แล้วเจอ error Entry Exit Not Found
        if (
          response?.ouReasonCode === "100134" &&
          response?.ouMessage === "Entry Exit Not Found"
        ) {
          setStep(STEP_FEE_PARKING.ENTRY_EXIT_NOT_FOUND);
          return;
        } else if (!response?.ouReasonCode === "800005") {
          toastErr(response?.ouMessage);
        }
      }
    },
    onError: (response) => {
      toastErr(response);
    },
  });

  const parkingFeeCalculateData = parkingFeeCalculateMutate?.data?.data || {};
  const parkingFeeCheckPendingData =
    parkingFeeCheckPendingPaymentMutate?.data?.data?.parkingpayment[0] || {};
  const checkPaymentResultData =
    checkPaymentResultMutate?.data?.data?.TbParkingPayHdrReq || {};
  const createReceiptParkingFeeData = createReceiptParkingFee?.data?.data || {};

  useEffect(() => {
    let newIntervalId = null;
    if (paymentStatus === STATUS_PAYMENT.WAIT && onlinetransid) {
      newIntervalId = setInterval(() => {
        checkPaymentResult(onlinetransid);
      }, INTERVAL_QR_QUERY);
      onChangeIntervalId(newIntervalId);
    }

    return () => {
      clearInterval(newIntervalId);
    };
  }, [paymentStatus, onlinetransid]);

  const checkPaymentResult = async (
    _onlinetransid,
    isCancelTransaction = false
  ) => {
    try {
      const response = await checkPaymentResultMutate.mutateAsync({
        onlinetransid: _onlinetransid,
        isCancelTransaction,
      });
      return response;
    } catch (error) {
      toastErr(error);
    }
  };

  const qrQueryStatus = (_onlinetransid) => {
    if (!_onlinetransid) {
      toastErr("onlinetransid not found!");
      return;
    }
    onStopPaymentHandler();
    const payloadQRQueryStatus = {
      pmlsid: pmlsId,
      onlinetransid: _onlinetransid,
    };
    qrQueryStatusMutate.mutate(payloadQRQueryStatus);
  };

  const createReceipt = async (resultPaymentData) => {
    if (!resultPaymentData) {
      toastErr("result payment data not found!");
      return;
    }

    //2094 - ก่อนสร้างใบเสร็จ เพิ่มเรียก checkpending -> เรียกเฉยๆ ไม่ต้องเช็คอะไร เมื่อเรียกเส้นนี้แล้ว หลังบ้านจะมีไปอัพเดทข้อมูล
    await PaymentService.parkingFeeCheckPendingPayment({
      pmlsid: pmlsId,
      ticketno: ticketNo,
    });

    if (resultPaymentData?.parkingpaydtlreq?.length > 0) {
      const parkingpaydtlreqData = resultPaymentData?.parkingpaydtlreq[0] || {};
      const createReceiptPayload = {
        pmlsid: pmlsId,
        transtype: resultPaymentData?.transtype,
        paytype: resultPaymentData?.paytype,
        totalamount: Number(resultPaymentData?.totalamount),
        paysourcecode: resultPaymentData?.paysourcecode,
        ticketno: resultPaymentData?.ticketno,
        memberno: resultPaymentData?.memberno,
        remark: resultPaymentData?.remark,
        refreceiptno: resultPaymentData?.refreceiptno,
        receipttype: resultPaymentData?.receipttype,
        detail: [
          {
            itemno: parkingpaydtlreqData?.itemno,
            unitamount: Number(parkingpaydtlreqData?.unitamount),
            qty: parkingpaydtlreqData?.qty,
            itemdesc1: parkingpaydtlreqData?.itemdesc1,
            itemdesc2: parkingpaydtlreqData?.itemdesc2,
            itemdesc3: parkingpaydtlreqData?.itemdesc3,
            parkingfeeid: parkingpaydtlreqData?.parkingfeeid,
          },
        ],
        entrydt: resultPaymentData?.entrydt,
        exitdt: resultPaymentData?.exitdt,
        exitbefore: resultPaymentData?.exitbefore,
        duration_day: resultPaymentData?.duration_day,
        duration_hour: resultPaymentData?.duration_hour,
        duration_minute: resultPaymentData?.duration_minute,
        productcode: resultPaymentData?.productcode,
        refid: resultPaymentData?.refid,
        displaydelaytime: resultPaymentData?.displaydelaytime,
        printcount: resultPaymentData?.printcount,
        info1: resultPaymentData?.info1,
        info2: resultPaymentData?.info2,
        email: checkedSendEmail ? receivePaymentConfirmEmail : null,
      };

      return await createReceiptParkingFee.mutateAsync(createReceiptPayload);
    }
  };

  const onClickVerifyPaymentBtnHandler = async () => {
    const onlinetransidToVerify = isPaymentPendingTransaction
      ? parkingFeeCheckPendingData.onlinetransid
      : onlinetransid;

    qrQueryStatus(onlinetransidToVerify);
  };

  const onClickCancelTransactionBtnHandler = async () => {
    setOpenCancelTransactionDialog(true);
  };

  //? Confirm cancel transaction -> "YES" click!
  const onClickYesDialogCancelTransactionBtnHandler = () => {
    try {
      setOpenCancelTransactionDialog(false);
      onChangePaymentStatus(STATUS_PAYMENT.STOP_PAYMENT);
      cancelParkingFee(parkingFeeCheckPendingData?.onlinetransid);
    } catch (err) {
      toastErr(err);
    }
  };
  //?Confirm cancel transaction -> "NO" click!
  const onClickNoDialogCancelTransactionBtnHandler = () => {
    setOpenCancelTransactionDialog(false);
  };

  const onClickMakeNewTransactionBtnHandler = () => {
    setStep(STEP_FEE_PARKING.SHOW_DETAIL);
  };

  const callBackWhenPaymentExpired = async (_onlinetransid) => {
    onStopPaymentHandler();
    cancelParkingFee(_onlinetransid).then((res) => {
      setStep(STEP_FEE_PARKING.BANK_EXPIREDT);
    });
  };

  const calculateParkingFee = async () => {
    if (!pmlsId || !ticketNo) {
      return;
    }
    await parkingFeeCalculateMutate.mutateAsync({
      pmlsid: pmlsId,
      ticketno: ticketNo,
    });
  };

  const cancelParkingFee = async (onlinetransid) => {
    if (!onlinetransid) {
      return;
    }
    return await parkingFeeCancelTransactionMutate.mutateAsync({
      pmlsid: pmlsId,
      onlinetransid: onlinetransid,
    });
  };

  const cancelAndRecalculateFee = async (onlinetransid) => {
    await cancelParkingFee(onlinetransid);
    await calculateParkingFee();
  };

  const onClickPaymentBtnHandler = () => {
    const payload = {
      pmlsid: pmlsId,
      amount: parkingFeeCalculateData.fee,
      paymentsource: "09",
      paymentref1: `${String(parkingFeeCalculateData.feeid).padStart(
        9,
        "0"
      )}${String(parkingFeeCalculateData.ticketno).padStart(9, "0")}`,
      clientid: "",
      refsource: 0,
      refip: "",
      transtype: "T01",
      paytype: "02",
      ticketno: ticketNo,
      memberno: "",
      remark: "",
      refreceiptno: "",
      receipttype: "01",
      productcode: "",
      refid: "",
      info1: parkingFeeCalculateData.licenseplate,
      info2: "",
      duration_day: parkingFeeCalculateData.durationday,
      duration_hour: parkingFeeCalculateData.durationhour,
      duration_minute: parkingFeeCalculateData.durationminute,
      printcount: 0,
      entrydt: parkingFeeCalculateData.start,
      exitdt: parkingFeeCalculateData.end,
      exitbefore: parkingFeeCalculateData.exitbefore,
      displaydelaytime: parkingFeeCalculateData.displaydelaytime,
      items: [
        {
          itemdesc1: parkingFeeCalculateData.itemdesc1,
          itemdesc2: parkingFeeCalculateData.itemdesc2,
          itemdesc3: parkingFeeCalculateData.itemdesc3,
          itemno: 1,
          qty: 1,
          unitamount: parkingFeeCalculateData.fee,
          parkingfeeid: parkingFeeCalculateData.feeid,
        },
      ],
      email: checkedSendEmail ? receivePaymentConfirmEmail : null,
    };
    createPaymentQRMutate.mutate(payload);
  };

  //? show data in result payment step
  const resultPaymentReceiptDisplay = useMemo(() => {
    const isPending = isPaymentPendingTransaction && checkPaymentResultData;
    const paymentData = isPending
      ? checkPaymentResultData
      : createReceiptParkingFeeData?.payment;
    const parkingData = checkPaymentResultData;

    // Helper function to format the total parking time
    const formatDuration = (days, hours, minutes) => {
      return `${days ? days + ` ${t("Day")} ` : ""}${
        hours ? hours + ` ${t("Hour")} ` : ""
      }${minutes ? minutes + ` ${t("Minute")}` : ""}`.trim();
    };

    const totalParkingTime = formatDuration(
      parkingData?.duration_day || parkingData?.durationday,
      parkingData?.duration_hour || parkingData?.durationhour,
      parkingData?.duration_minute || parkingData?.durationminute
    );

    const airportName = getAirportNameFormPmlsAndLang(pmlsId, i18n?.language);

    return {
      airport: airportName,
      licensePlate: isPending
        ? parkingData?.info1
        : getLicensePlateProvinceText(parkingFeeCalculateData),
      start: parkingData?.entrydt || parkingFeeCalculateData?.start,
      totalParkingTime,
      paymentMethod: getPaymentTranslationKeyByPaymentMethod(method),
      fee: parkingFeeCalculateData?.fee,
      totalamount: paymentData?.totalamount,
      displaydelaytime: parkingData?.displaydelaytime,
    };
  }, [
    isPaymentPendingTransaction,
    checkPaymentResultData,
    createReceiptParkingFeeData,
    parkingFeeCalculateData,
    method,
    pmlsId,
    i18n?.language,
  ]);

  const waitPaymentTopHeaderDisplay = (
    <div className="border-b border-solid w-full pb-3 text-center">
      <p className="text-sm md:text-base text-red-500">
        {t("QR code for parking fee payment")}{" "}
        {resultPaymentReceiptDisplay?.licensePlate}
        <br />
        {t("Entry Date Time")}{" "}
        {(resultPaymentReceiptDisplay?.start &&
          dayjs(resultPaymentReceiptDisplay?.start).format(
            DATE_FORMATS.FORMAT_03
          )) ||
          ""}{" "}
        <br />
        {t("and can be used to pay only 1 time")}
      </p>
    </div>
  );

  const loadingConfirmPayment = createPaymentQRMutate.isLoading;
  const loadingCheckPendingAndCalculate =
    parkingFeeCheckPendingPaymentMutate?.isLoading ||
    parkingFeeCalculateMutate?.isLoading;

  return (
    <div className="w-full relative h-screen flex flex-col gap-y-16  items-center justify-items-center">
      <DialogPaymentConfirmCancel
        open={openCancelTransactionDialog}
        setOpen={setOpenCancelTransactionDialog}
        onClickYesBtn={onClickYesDialogCancelTransactionBtnHandler}
        onClickNoBtn={onClickNoDialogCancelTransactionBtnHandler}
      />
      <div className="absolute right-4 top-5">
        <LanguagesDropdown />
      </div>
      <div className="flex items-center justify-center flex-col gap-10 py-20 w-full">
        <div className="w-full flex flex-col items-center gap-2">
          <img
            src="/images/aot_logo.png"
            alt="aot-logo"
            className="w-full max-w-[150px] h-auto "
          />
          <span className="text-xs font-bold">
            {pmlsId &&
              i18n?.language &&
              getAirportNameFormPmlsAndLang(pmlsId, i18n?.language)}
          </span>
        </div>
        <div className="w-full max-w-[600px] px-4 pb-10">
          {loadingConfirmPayment || loadingCheckPendingAndCalculate ? (
            <LoadingSpinner />
          ) : (
            step === STEP_FEE_PARKING.SHOW_DETAIL && (
              <div className="space-y-4 w-full">
                <QRTicketFeeParkingPreviewPaymentDetail
                  parkingFeeCalculateData={parkingFeeCalculateData}
                  method={method}
                  receivePaymentConfirmFn={{
                    emailIsValid: REGEX.EMAIL.test(receivePaymentConfirmEmail),
                    checkedSendEmail: checkedSendEmail,
                    sendEmailValue: receivePaymentConfirmEmail,
                    setCheckedSendEmail: (value) => {
                      onChangeCheckedBoxSendEmailHandler(value);
                    },
                    setSendEmailValue: (value) => {
                      onChangeReceivePaymentConfirmViaEmail(value);
                    },
                  }}
                />
                {isCloseOnlinePayment && (
                  <p className="py-1 text-center text-red-500 font-semibold text-lg">
                    {t("noti close parkingfee detail")}
                  </p>
                )}
                <ButtonAction
                  onClick={onClickPaymentBtnHandler}
                  disabled={
                    (checkedSendEmail &&
                      !REGEX.EMAIL.test(receivePaymentConfirmEmail)) ||
                    parkingFeeCalculateData?.fee === 0 ||
                    !parkingFeeCalculateData ||
                    isCloseOnlinePayment
                  }
                >
                  {t("Confirm Payment")}
                </ButtonAction>
              </div>
            )
          )}
          {step === STEP_FEE_PARKING.WAIT_PAYMENT && (
            <WaitPaymentStep
              waitPaymentTopHeaderDisplay={waitPaymentTopHeaderDisplay}
              requestData={createPaymentQRMutate?.data?.data}
              showCheckPaymentButton
              onClickCheckPaymentBtnHandler={onClickVerifyPaymentBtnHandler}
              callBackWhenPaymentExpired={() => {
                //จากหน้าชำระเงินปกติ ใช้ interval เช็ค bankexpiredt
                callBackWhenPaymentExpired(onlinetransid);
              }}
            />
          )}
          {step === STEP_FEE_PARKING.RESULT_PAYMENT && (
            <BoxFeeParking4
              receiptNo={receiptData?.payment?.receiptno}
              completedTransactionDate={receiptData?.payment?.createdt}
              paymentMethod={method}
              resultPaymentReceiptDisplay={resultPaymentReceiptDisplay}
              isPaymentPendingTransaction={isPaymentPendingTransaction}
              onClickAdditionalPaymentBtnHandler={
                onClickMakeNewTransactionBtnHandler
              }
              isQRTicket
            />
          )}
          {step === STEP_FEE_PARKING.PENDING_TRANSACTION && (
            <PaymentPendingVerifyBox
              onlinetransid={parkingFeeCheckPendingData?.onlinetransid}
              bankexpiredt={convertGmtToClientTimeZone(
                parkingFeeCheckPendingData?.bankexpiredt
              )}
              onClickVerifyPaymentBtnHandler={onClickVerifyPaymentBtnHandler}
              onClickCancelTransactionBtnHandler={
                onClickCancelTransactionBtnHandler
              }
            />
          )}
          {step === STEP_FEE_PARKING.CANCELED_TRANSACTION && (
            <PaymentCancelPendingSummary
              onClickMakeNewTransactionBtnHandler={
                onClickMakeNewTransactionBtnHandler
              }
              isQRTicket
            />
          )}
          {(step === STEP_FEE_PARKING.NOT_FOUND ||
            step === STEP_FEE_PARKING.CAR_ALREADY_EXIT) && <NotFoundTicket />}
          {step === STEP_FEE_PARKING.ENTRY_EXIT_NOT_FOUND && (
            <NotFoundEntryExit />
          )}
          {step === STEP_FEE_PARKING.ERROR_CALCULATE && <ErrorCalculate />}
          {step === STEP_FEE_PARKING.BANK_EXPIREDT && (
            <PaymentExpired
              onClickMakeNewTransactionBtnHandler={
                onClickMakeNewTransactionBtnHandler
              }
            />
          )}
        </div>
      </div>
      <ModalClosePaymentQRFeeParking
        open={isOpenModalClosePayment}
        showCloseIcon
        handleClose={closeModalClosePayment}
      />
    </div>
  );
};

export default QRTicketFeeParkingPage;
