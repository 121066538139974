import { DesktopDatePicker } from "@mui/x-date-pickers";
import React from "react";
import { InputAOT } from "./InputAOT";

const DatePickerAOT = ({
  label,
  width = "100%",
  val,
  handleChange = () => {},
  sxa,
  minDate,
  disabled = false,
  required = false,
  size = "medium",
  shouldDisableDate,
  placeholder = "dd/mm/yyyy", // Default placeholder
  onError = () => {}, // Default no-op function
}) => {
  // const disabledStyles = disabled
  //   ? {
  //       backgroundColor: "#D1D1D1",
  //       color: "rgba(0, 0, 0, 0.66)",
  //     }
  //   : {};

  return (
    // <Box
    //   sx={{
    //     width,
    //     // borderRadius: "10px",
    //     // border: "2px solid #3D9AEE",
    //     // overflow: "hidden",
    //     ...sxa,
    //   }}
    // >
    <DesktopDatePicker
      label={label}
      disabled={disabled}
      value={val}
      onChange={handleChange}
      shouldDisableDate={shouldDisableDate}
      minDate={minDate}
      onError={onError}
      renderInput={(params) => (
        <InputAOT
          size={size}
          {...params}
          variant="filled"
          required={required}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            // endAdornment: React.cloneElement(params.InputProps.endAdornment, {
            //   style: disabledStyles,
            // }),
            // style: disabledStyles,
          }}
          inputProps={{
            ...params.inputProps,
            placeholder, // Set custom placeholder here
          }}
          sx={{
            // svg: {
            //   color: disabled ? "rgba(0, 0, 0, 0.66)" : "#245AB1",
            // },
            // button: {
            //   borderRadius: 0,
            //   backgroundColor: "transparent",
            //   "&:hover": { backgroundColor: "transparent" },
            //   ...(disabled && { backgroundColor: "#D1D1D1" }),
            // },
            input: {
              // width: "100%",
              // ...(disabled && { backgroundColor: "#D1D1D1" }),
            },
            width: "100%",
          }}
        />
      )}
    />
    // </Box>
  );
};

export default DatePickerAOT;
