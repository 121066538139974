/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import AccountService from "../../services/account-service";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SettingNotification = () => {
  const { t } = useTranslation();
  const { profile } = useSelector((select) => select.auth);

  const formik = useFormik({
    initialValues: {
      allnotification: false,
      ismemberexpired: false,
      ismemberrenew: false,
      ischangevehicle: false,
      news: false,
      campaign: false,
    },  
  });

  const getFormikValuePayload = useMemo(() => {
    return {
      usernotifid: profile?.usernotify?.usernotifid,
      userid: profile?.userMenu.userid,
      ismemberexpired: formik.values.ismemberexpired ? 1 : 0,
      ischangevehicle: formik.values.ischangevehicle ? 1 : 0,
      news: formik.values.news ? 1 : 0,
      campaign: formik.values.campaign ? 1 : 0,
      ismemberrenew: formik.values.ismemberrenew ? 1 : 0,
    };
  }, [formik.values, profile]);

  useEffect(() => {
    if (profile?.usernotify) {
      const profileUserNotify = profile?.usernotify;

      if (
        profileUserNotify.ismemberexpired &&
        profileUserNotify.ismemberrenew &&
        profileUserNotify.ischangevehicle &&
        profileUserNotify.news &&
        profileUserNotify.campaign
      ) {
        formik.setValues({
          allnotification: true,
          ismemberexpired:
            profileUserNotify.ismemberexpired === 1 ? true : false,
          ismemberrenew: profileUserNotify.ismemberrenew === 1 ? true : false,
          ischangevehicle:
            profileUserNotify.ischangevehicle === 1 ? true : false,
          news: profileUserNotify.news === 1 ? true : false,
          campaign: profileUserNotify.campaign === 1 ? true : false,
        });
      } else if (
        profileUserNotify.ismemberexpired === false &&
        profileUserNotify.ismemberrenew === false &&
        profileUserNotify.ischangevehicle === false &&
        profileUserNotify.news === false &&
        profileUserNotify.campaign === false
      ) {
        formik.setValues({
          allnotification: false,
          ismemberexpired:
            profileUserNotify.ismemberexpired === 1 ? true : false,
          ismemberrenew: profileUserNotify.ismemberrenew === 1 ? true : false,
          ischangevehicle:
            profileUserNotify.ischangevehicle === 1 ? true : false,
          news: profileUserNotify.news === 1 ? true : false,
          campaign: profileUserNotify.campaign === 1 ? true : false,
        });
      } else {
        formik.setValues({
          ...formik.values,
          ismemberexpired:
            profileUserNotify.ismemberexpired === 1 ? true : false,
          ismemberrenew: profileUserNotify.ismemberrenew === 1 ? true : false,
          ischangevehicle:
            profileUserNotify.ischangevehicle === 1 ? true : false,
          news: profileUserNotify.news === 1 ? true : false,
          campaign: profileUserNotify.campaign === 1 ? true : false,
        });
      }
    }
  }, [profile?.usernotify]);

  const updateUserNotificationMutate = useMutation({
    mutationFn: (value) => AccountService.updateUserNotification(value),
    onSuccess: (value) => {
      if (value.ouIsComplete === 1) {
        toast.success("Update success", {
          position: "top-center",
          autoClose: 500,
        });
      }
    },
    onError: (value) => {
      toast.error(value.ouMessage, {
        position: "top-center",
        autoClose: 1000,
      });
    },
  });

  const handleAllNotificationChange = (event) => {
    const allValue = event.target.checked;

    formik.setValues({
      ...formik.values,
      allnotification: allValue,
      ismemberexpired: allValue,
      ismemberrenew: allValue,
      ischangevehicle: allValue,
      news: allValue,
      campaign: allValue,
    });

    const payload = {
      ...getFormikValuePayload,
      ismemberexpired: allValue ? 1 : 0,
      ismemberrenew: allValue ? 1 : 0,
      ischangevehicle: allValue ? 1 : 0,
      news: allValue ? 1 : 0,
      campaign: allValue ? 1 : 0,
    };
    updateUserNotificationMutate.mutate(payload);
  };

  return (
    <div className="w-full p-20 text-[#2F2E2F]">
      <div className="flex justify-between items-center">
        <div>
          <div className="inline-flex items-center mb-4">
            <div className="inline-flex gap-2 items-center">
              <NotificationsIcon className="text-blue-800" fontSize="large" />
              <p className="text-xl md:text-3xl text-[#2F2E2F] font-bold">
                {/* {t("Payment and Payment History")} */}
                ตั้งค่าการแจ้งเตือน
              </p>
            </div>
          </div>
        </div>
        <div className="text-sm">
          {t("Home")} /
          <span className={`${"text-[#245AB1] font-semibold"} `}>
            {" "}
            {/* {t(["Payment"])} */}
            ตั้งค่าการแจ้งเตือน
          </span>
        </div>
      </div>

      <div className="py-8 px-4 md:px-14 w-full">
        <div className="flex flex-col">
          <ItemWithSwitch
            text="แจ้งเตือนทั้งหมด"
            name="allnotification"
            value={formik.values.allnotification}
            onChange={handleAllNotificationChange}
          />
          <hr />
          <ItemWithSwitch
            text="แจ้งเตือนการหมดอายุของสมาชิก"
            name="ismemberexpired"
            value={formik.values.ismemberexpired}
            onChange={(event) => {
              formik.handleChange(event);
              const payload = {
                ...getFormikValuePayload,
                ismemberexpired: event.target.checked ? 1 : 0,
              };
              updateUserNotificationMutate.mutate(payload);
            }}
          />
          <hr />
          <ItemWithSwitch
            text="แจ้งเตือนการต่ออายุของสมาชิก"
            name="ismemberrenew"
            value={formik.values.ismemberrenew}
            onChange={(event) => {
              formik.handleChange(event);
              const payload = {
                ...getFormikValuePayload,
                ismemberrenew: event.target.checked ? 1 : 0,
              };
              updateUserNotificationMutate.mutate(payload);
            }}
          />
          <hr />
          <ItemWithSwitch
            text="แจ้งเตือนการเปลี่ยนรถยนต์ที่ใช้งาน"
            name="ischangevehicle"
            value={formik.values.ischangevehicle}
            onChange={(event) => {
              formik.handleChange(event);
              const payload = {
                ...getFormikValuePayload,
                ischangevehicle: event.target.checked ? 1 : 0,
              };
              updateUserNotificationMutate.mutate(payload);
            }}
          />
          <hr />
          <ItemWithSwitch
            text="แจ้งเตือนการรับข่าวสาร AOT"
            name="news"
            value={formik.values.news}
            onChange={(event) => {
              formik.handleChange(event);
              const payload = {
                ...getFormikValuePayload,
                news: event.target.checked ? 1 : 0,
              };
              updateUserNotificationMutate.mutate(payload);
            }}
          />
          <hr />
          <ItemWithSwitch
            text="แจ้งเตือนการรับแคมเปญและโปรโมชั่น"
            name="campaign"
            value={formik.values.campaign}
            onChange={(event) => {
              formik.handleChange(event);
              const payload = {
                ...getFormikValuePayload,
                campaign: event.target.checked ? 1 : 0,
              };
              updateUserNotificationMutate.mutate(payload);
            }}
          />
          <hr />
        </div>
      </div>
    </div>
  );
};

const ItemWithSwitch = ({ text, name, value, onChange }) => {
  return (
    <div className="flex items-center h-12 justify-between">
      <span className="text-base md:text-lg">{text}</span>
      <Switch
        inputProps={{
          "aria-label": "controlled",
        }}
        name={name}
        checked={value}
        onChange={onChange}
        sx={{
          width: 60, // Set the desired width
          height: 40, // Set the desired height
          "& .MuiSwitch-thumb": {
            width: 26, // Set the desired thumb width
            height: 26, // Set the desired thumb height
          },
          "& .MuiSwitch-track": {
            width: 56, // Set the desired track width
            height: 22, // Set the desired track height
            borderRadius: 11, // Adjust the border-radius for the track
          },
        }}
      />
    </div>
  );
};

export default SettingNotification;
