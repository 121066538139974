import React from "react";
import BoxNews from "../components/HomeAOT/BoxNews";
import { Box, InputAdornment, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { fetchContentPreviewFilter } from "./HomeAOT";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { CONTENT_TYPE_CODE } from "../constants";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { formattedBase64Image } from "../utils/format";
import { InputTextFieldAOT } from "../components/Controls";
const BoxSearch = styled(Box)(({ theme }) => ({
  background: "#E9F0F5 0% 0% no-repeat padding-box",
  height: "12vh",
  padding: "0 15.688rem",
  display: "flex",
  alignItems: "center",
  transform: "translate(0, -7px)",
  [theme.breakpoints.down("lg")]: {
    padding: "0 10rem",
    height: "15vh",
  },
  [theme.breakpoints.down("md")]: {
    padding: "0 5rem",
    height: "13vh",
  },
}));

const MenuButton = styled((props) => <Box component="img" {...props} />)(
  ({ theme }) => ({
    width: "100%",
    height: "300px",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      height: "150px",
    },
  })
);

const ContentCampaign = () => {
  const { t, i18n } = useTranslation();
  const { pmlsId } = useSelector((state) => state.airport);
  const [filterName, setFilterName] = useState("");

  const campaignPromoQuery = useQuery({
    queryKey: ["CONTENT_PREVIEW_FILTER_PROMO", filterName, i18n, pmlsId],
    queryFn: () =>
      fetchContentPreviewFilter(
        pmlsId,
        CONTENT_TYPE_CODE.PROMO,
        filterName,
        9,
        i18n?.language
      ),
    enabled: pmlsId !== "0",
    select: (data) => data?.data?.allTbContentHdr,
  });

  const contentPreviewPromoData = useMemo(() => {
    return campaignPromoQuery.data?.map((item) => ({
      contentid: item.contentid,
      previewcontenttext:
        item.detail?.length > 0 ? item.detail[0].previewcontenttext : "",
      previewimage:
        item.detail?.length > 0
          ? formattedBase64Image(
              item.detail[0].previewimage_data.imageext,
              item.detail[0].previewimage_data.data_base64
            )
          : "",
    }));
  }, [campaignPromoQuery]);

  const debounce = useDebouncedCallback(setFilterName, 500);

  return (
    <>
      <MenuButton src="/images/allnews/img_bgnews.png" />
      <BoxSearch>
        <InputTextFieldAOT
          label="ค้นหาข่าวสาร"
          // val={filterName}
          handleChange={(e) => debounce(e.target.value)}
          type="search"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </BoxSearch>
      <BoxNews
        type={CONTENT_TYPE_CODE.PROMO}
        header={t("Campaign and Promotion")}
        data={contentPreviewPromoData}
        hideSeeAllContent
        isLoading={campaignPromoQuery?.isLoading}
      />
    </>
  );
};

export default ContentCampaign;
