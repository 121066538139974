import { Box, styled, Typography, useMediaQuery } from "@mui/material";
import React from "react";

const ChartTitleText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 24px/54px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal 600 22px/33px Noto Sans Thai",
  },
}));

const ChartTitleBlueText = styled(Typography)(({ theme }) => ({
  font: "normal normal bold 36px/54px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2969BE",
  marginLeft: "1rem",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal bold 22px/33px Noto Sans Thai",
  },
}));

const ChartRightTitleText = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 16px/27px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  marginLeft: "1rem",
}));

const ChartRightTitleBlueText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 16px/27px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#3C5AAD",
  marginLeft: "0.2rem",
}));

const BoxPageContent = ({
  headerBlack,
  headerBlue,
  breadcrumbRoot,
  breadcrumbBranch,
  align,
  children,
  showBreadcrumb = true,
}) => {
  const isLGScr = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  return (
    <div className="mx-auto container">
      <Box sx={{ paddingX: isLGScr ? "1rem" : "10rem", paddingY: "2rem" }}>
        {isLGScr ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ChartTitleText>{headerBlack}</ChartTitleText>
            <ChartTitleBlueText>{headerBlue}</ChartTitleBlueText>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ChartTitleText>{headerBlack}</ChartTitleText>
              <ChartTitleBlueText>{headerBlue}</ChartTitleBlueText>
            </Box>
            {showBreadcrumb && (
              <Box
                sx={{
                  display: "flex",
                  flex: "1 1 auto",
                  justifyContent: "flex-end",
                }}
              >
                <ChartRightTitleText>{breadcrumbRoot} / </ChartRightTitleText>
                <ChartRightTitleBlueText>
                  {breadcrumbBranch}
                </ChartRightTitleBlueText>
              </Box>
            )}
          </Box>
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: align ? align : "center",
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        >
          {children}
        </Box>
      </Box>
    </div>
  );
};

export default BoxPageContent;
