import { Box, Grid, styled, Typography } from "@mui/material";
import React from "react";
import DropdownAOT from "../Controls/DropdownAOT";
import InputTextFieldAOT from "../Controls/InputTextFieldAOT";
import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import AutocompleteAOT from "../Controls/AutoCompleteAOT";
import { useTranslation } from "react-i18next";
import { ButtonSave } from "./RegisterParkingStep1";
import { useSelector } from "react-redux";

import {
  generateProvinceLicenseOptions,
  generateLicenseOptions,
  generateOwnerVehicleOptions,
  generateCarBrandOptions,
} from "../../utils/optionsUtils";

const BoxOuter = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "1rem",
  },
}));

const BoxHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "1rem",
  },
}));

const ImgPlane = styled((props) => <Box component="img" {...props} />)(
  ({ theme }) => ({
    width: "3rem",
    height: "1.8rem",
    marginRight: "2rem",
    [theme.breakpoints.down("lg")]: {
      //display: 'none'
    },
  })
);

const ImgUser = styled((props) => <Box component="img" {...props} />)(
  ({ theme }) => ({
    width: "2.6rem",
    height: "2.6rem",
    marginRight: "2rem",
    [theme.breakpoints.down("lg")]: {
      //display: 'none'
    },
  })
);

const AirportText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 24px/45px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "1rem",
  },
}));

const GridContainer = styled((props) => (
  <Grid container spacing={2} {...props} />
))(({ theme }) => ({
  marginTop: "1rem",
  [theme.breakpoints.down("lg")]: {
    //display: 'none'
  },
}));

const GridItem = styled((props) => <Grid item lg={6} xs={12} {...props} />)(
  ({ theme }) => ({
    paddingTop: "1rem",
    paddingBottom: "1rem",
    [theme.breakpoints.down("lg")]: {
      //display: 'none'
    },
  })
);

const BoxButton = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  paddingTop: "5.233rem",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const BoxMessErr = styled(Box)(() => ({
  color: "red",
  marginRight: "auto",
  paddingLeft: "1rem",
}));

const RegisterParkingStep2 = ({ formik, currentAirportPmlsId }) => {
  const { t } = useTranslation();
  const { provincelicense, license, ownerVehicle, carBrand } = useSelector(
    (state) => state.masterData
  );
  const [valueProvinceLicense, setValueProvinceLicense] = useState("");
  const [valueCarBrand, setValueCarBrand] = useState("");
  const [valueLicense, setValueLicense] = useState("");
  const [valueVehicleowner, setValueVehicleowner] = useState("");
  const [ErrForm, setErrForm] = useState({});

  const provinceLicenseOptions = useMemo(() => {
    return generateProvinceLicenseOptions(provincelicense);
  }, [provincelicense]);

  const licenseOptions = useMemo(() => {
    return generateLicenseOptions(license);
  }, [license]);

  const ownerVehicleOptions = useMemo(() => {
    return generateOwnerVehicleOptions(ownerVehicle);
  }, [ownerVehicle]);

  const carBrandOptions = useMemo(() => {
    return generateCarBrandOptions(carBrand);
  }, [carBrand]);

  const handleNext = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1)
  };

  const handleCarbrand = (e) => {
    setValueCarBrand(e);
    formik.setFieldValue("v_brand", e?.id);
    formik.setFieldValue("v_brandname", e?.display);
  };
  const handleProvince = (e, newValue) => {
    setValueProvinceLicense(newValue);
    formik.setFieldValue("v_licenseby", newValue?.id);
    formik.setFieldValue("v_provincename", newValue?.display);
  };
  const handleLicenseplate = (e) => {
    formik.setFieldValue("v_licenseplate", e.target.value);
  };
  // const handleLicenseType = (e) => {
  //   formik.setFieldValue("v_vehicletypeid", e?.id);
  // };
  const handleVehiclemodel = (e) => {
    formik.setFieldValue("v_vehiclemodel", e.target.value);
  };
  const handleVehiclecolor = (e) => {
    formik.setFieldValue("v_vehiclecolor", e.target.value);
  };
  const handleRemark = (e) => {
    formik.setFieldValue("vechicleremark", e.target.value);
  };
  const handleVehicleowner = (e) => {
    formik.setFieldValue("v_vehicleowner", e.id);
  };
  const handleVehicleownerIdentity = (e) => {
    formik.setFieldValue("v_vehicleowner_identity", e.target.value);
  };

  useEffect(() => {
    let result = {};

    if (formik.values.v_licenseby === 0) {
      result = { ...result, v_licenseby: "Province License is required" };
    }
    if (formik.values.v_brand === "") {
      result = { ...result, v_brand: "Brand is required" };
    }
    if (formik.values.v_vehicleowner === "") {
      result = { ...result, v_vehicleowner: "Vehicle owner is required" };
    }
    if (
      formik.values?.v_vehicleowner !== 1 &&
      formik.values?.v_vehicleowner_identity === ""
    ) {
      result = {
        ...result,
        v_vehicleowner_identity: "Vehicle owner identity is required",
      };
    }
    // if (formik.values.v_vehicletypeid === 0) {
    //   result = { ...result, v_vehicletypeid: "Vehicletypeid is required" };
    // }
    setErrForm(result);
  }, [
    formik.values.v_brand,
    formik.values.v_licenseby,
    formik.values.v_licenseplate,
    formik.values.v_vehicleowner,
    formik.values.v_vehicletypeid,
    formik.values.v_vehicleowner_identity,
  ]);

  return (
    <>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <BoxOuter>
          <BoxHeader>
            <ImgPlane src="/images/auth/ico_plane.svg" />
            {currentAirportPmlsId === "1" ? (
              <AirportText>{t("Suvarnabhumi airport")}</AirportText>
            ) : (
              <AirportText>
                {t("Don Mueang International Airport")}{" "}
              </AirportText>
            )}
          </BoxHeader>
          <BoxHeader>
            <ImgUser src="/images/register/ico_car_1.svg" />
            <AirportText>{t("Vehicle Information")}</AirportText>
          </BoxHeader>
          <GridContainer>
            <GridItem>
              <InputTextFieldAOT
                label={t("License Plate")}
                name="v_licenseplate"
                required
                handleChange={handleLicenseplate}
                val={formik.values.v_licenseplate}
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {formik.errors.v_licenseplate && formik.touched
                    ? formik.errors.v_licenseplate
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <AutocompleteAOT
                required
                label={t("Province")}
                name="v_licenseby"
                options={provinceLicenseOptions}
                val={valueProvinceLicense}
                handleChange={handleProvince}
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {ErrForm.v_licenseby && formik.touched
                    ? ErrForm.v_licenseby
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <AutocompleteAOT
                required
                label={t("Brand")}
                name="v_brand"
                options={carBrandOptions}
                val={valueCarBrand}
                handleChange={(e, newvalue) => {
                  handleCarbrand(newvalue);
                }}
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {ErrForm.v_brand && formik.touched ? ErrForm.v_brand : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <DropdownAOT
                label={t("License Plate Type")}
                // name="v_vehicletypeid"
                data={licenseOptions}
                val={valueLicense}
                setVal={setValueLicense}
                handleChange={(e) => {
                  formik.setFieldValue("v_licensetypeid", e.id);
                }}
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {ErrForm.v_vehicletypeid && formik.touched
                    ? ErrForm.v_vehicletypeid
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("Model")}
                name="v_vehiclemodel"
                handleChange={handleVehiclemodel}
                val={formik?.values?.v_vehiclemodel}
              />
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("Vehicle Color")}
                name="v_vehiclecolor"
                handleChange={handleVehiclecolor}
                val={formik?.values?.v_vehiclecolor}
              />
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("Remark")}
                name="vechicleremark"
                handleChange={handleRemark}
                val={formik?.values?.vechicleremark}
              />
            </GridItem>
            <GridItem>
              <DropdownAOT
                required
                label={t("Owner Type")}
                name="v_vehicleowner"
                data={ownerVehicleOptions}
                val={valueVehicleowner}
                setVal={setValueVehicleowner}
                handleChange={handleVehicleowner}
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {ErrForm.v_vehicleowner && formik.touched
                    ? ErrForm.v_vehicleowner
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("ID No in case you are not a vehicle owner")}
                name="v_vehicleowner_identity"
                handleChange={handleVehicleownerIdentity}
                val={formik?.values?.v_vehicleowner_identity}
                required={formik.values.v_vehicleowner !== 1}
                disabled={formik.values.v_vehicleowner === 1}
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {ErrForm.v_vehicleowner_identity && formik.touched
                    ? ErrForm.v_vehicleowner_identity
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
          </GridContainer>
        </BoxOuter>
        <BoxButton>
          {/* <ButtonCancel onClick={handleBack}>ยกเลิก</ButtonCancel> */}
          <ButtonSave
            onClick={handleNext}
            type="submit"
            disabled={JSON.stringify(ErrForm) !== "{}"}
          >
            {t("SaveNext")}
          </ButtonSave>
        </BoxButton>
      </form>
    </>
  );
};

export default RegisterParkingStep2;
