import React from "react";

const ErrorResultWithText = ({ text }) => {
  return (
    <div className="p-16 text-center flex flex-col items-center gap-11">
      <img src="/images/status/error_result.png" className="w-[200px] h-auto" alt="error_result"/>
      {text}
    </div>
  );
};

export default ErrorResultWithText;
