import { classNames } from "../../utils";

export const TitleWithValue = ({
  title,
  value,
  titleStyles,
  valueStyles,
  boxStyles,
}) => {
  return (
    <div
      className={classNames(
        "items-center flex justify-between break-words",
        boxStyles
      )}
    >
      <div
        className={classNames("text-sm lg:text-base text-left", titleStyles)}
      >
        {title}
      </div>
      <div
        className={classNames(
          "text-sm lg:text-base text-right min-w-0",
          valueStyles
        )}
      >
        {value}
      </div>
    </div>
  );
};
