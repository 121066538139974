/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import BoxPageContent from "../components/BoxPageContent";
import ReservedParkingStepper from "../components/ReservedParking/ReservedParkingStepper";
import ReservedParking1 from "../components/ReservedParking/ReservedParking1";
import ReservedParking3 from "../components/ReservedParking/ReservedParking3";
import ReservedParkingSummary from "../components/ReservedParking/ReservedParkingSummary";
import ReservedParkingWait from "../components/ReservedParking/ReservedParkingWait";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";
import ParkingService from "../services/parking-service";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { DATE_FORMATS, LANGUAGE } from "../constants";
import ONLINE_PAYMENT_STATUS from "../constants/payment";
import { useEffect } from "react";
import TicketService from "../services/ticket-service";
// import MasterService from "../services/master-service";
import * as Yup from "yup";
import { generateProvinceLicenseOptions } from "../utils/optionsUtils";
import { usePayment } from "../hooks/usePayment";
import ReservationService from "../services/reservation-service";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  licenseplate: Yup.string()
    .required("License plate is required")
    .matches(
      /^[a-zA-Zก-ฮ-0-9]+$/,
      "License plate must contain only letters, numbers, and Thai consonants"
    ),
  licenseprovince: Yup.object()
    .shape({})
    .required("Province License is required"),
});

const ReservedParking = () => {
  const isLGScr = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const { method, onChangePaymentMethod } = usePayment();
  const { profile } = useSelector((state) => state.auth);
  const { t, i18n } = useTranslation();
  const { provincelicense, paymentType } = useSelector(
    (select) => select.masterData
  );

  const [activeStep, setActiveStep] = useState(1);
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  const provincesLicenseOptions = useMemo(() => {
    return generateProvinceLicenseOptions(provincelicense);
  }, [provincelicense]);

  const formik = useFormik({
    initialValues: {
      pmlsid: null,
      parking: null,
      zone: null,
      licenseplate: null,
      licenseprovince: null,
    },
    isInitialValid: false,
    validationSchema: validationSchema,
    onSubmit: (values) => {},
  });

  const { data: parking, isLoading: parkingLoading } = useQuery({
    queryKey: ["GET_PARKING"],
    queryFn: async () => {
      const res = await ParkingService.getParkingAll(1, "en");
      const data = res.data.allTbParking;
      return data
        .filter((d) => d.isactive)
        .map((d) => ({ id: d.parkingid, display: d.name }));
    },
    retry: false,
  });

  const { data: parkingZoneData, isLoading: parkingZoneLoading } = useQuery({
    queryKey: ["GET_PARKING_ZONE", formik.values.parking],
    queryFn: () =>
      ParkingService.getParkingAreaByParkingPMLS({
        pmlsid: "1", //suvanaphumi
        parkingid: formik.values.parking,
      }),

    enabled: formik.values.parking !== null,
  });

  // const getMasterPaymentTypeQuery = useQuery({
  //   queryKey: ["GET_MASTER_PAYMENT_TYPE"],
  //   queryFn: () => MasterService.paymentType(),
  //   select: (data) => data?.data?.data?.allMsPaymentType,
  // });

  const parkingZoneOption = useMemo(() => {
    return parkingZoneData?.data?.data?.tbParkingArea
      ?.filter((item) => item?.areatype === "Z02")
      ?.map((item) => ({
        id: item?.parkingareaid,
        display: item?.areaname,
      }));
  }, [parkingZoneData]);

  const checkParkingAvailableMutate = useMutation({
    mutationFn: (payload) => ReservationService.checkAvailable(payload),
    onSuccess: (response) => {
      if (response.data?.issuccess) {
        setActiveStep(2);
      } else {
        toast.error(response?.data?.ouMessage, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    },
    onError: () => {
      alert("Something went wrong");
    },
  });

  //จองที่จอดรถ
  const bookingMutate = useMutation({
    mutationFn: (payload) => ReservationService.booking(payload),
    onSuccess: (response) => {
      if (response.status === 200) {
        //error case
        if (!response?.data?.issuccess) {
          if (
            response?.data?.message ===
            "Cannot Reservation caused by car is in park"
          ) {
            alert(
              "ไม่สามารถทำการจองที่จอดรถได้ เนื่องจากขณะนี้เลขทะเบียนรถนี้อยู่ในลานจอด"
            );
          } else if (
            response?.data?.message ===
            "Cannot Reservation caused by Booking in progress"
          ) {
            alert(
              "ไม่สามารถทำการจองที่จอดรถได้ เนื่องจากมีรายการจองที่จอดรถค้างอยู่"
            );
          } else {
            alert(response?.data?.ouMessage);
          }
        } else {
          //success case
          setActiveStep(3);
        }
      }
    },
    onError: (response) => {
      alert(response?.message);
    },
  });

  const parkingIsAvailable =
    checkParkingAvailableMutate?.data &&
    checkParkingAvailableMutate.data?.data?.data?.isAvailable;

  const onClickCheckAvailableButton = () => {
    try {
      const find = parkingZoneData?.data?.data?.tbParkingArea?.find(
        (item) => item?.parkingareaid === formik.values.zone
      );
      if (!find) return;
      const payload = {
        pmlsid: "1",
        parkingid: find?.parkingid,
        floorid: find?.parkingfloorid,
        areaid: find?.parkingareaid,
      };
      checkParkingAvailableMutate.mutate(payload);
    } catch (err) {
      console.log(err);
    }
  };

  const onClickBookingButton = () => {
    const find = parkingZoneData?.data?.data?.tbParkingArea.find(
      (item) => item?.parkingareaid === formik.values.zone
    );
    if (!find) return;
    const payload = {
      pmlsid: 1,
      parkingid: find?.parkingid,
      floorid: find?.parkingfloorid,
      areaid: find?.parkingareaid,
      bookingby: profile?.userMenu?.userid, //userid
      email: profile?.userMenu?.email, // # 1523  เพิ่มส่ง request field: email
      reserveddate: dayjs().format(DATE_FORMATS.DEFAULT),
      licenseplate: formik.values?.licenseplate, //# 1619 จองที่จอดรถ: ปรับให้คีย์ทะเบียนรถ
      licenseprovince: formik.values?.licenseprovince?.display, //# 1619 จองที่จอดรถ: ปรับให้คีย์ทะเบียนรถ
    };
    bookingMutate.mutate(payload);
  };

  const displayInformation = {
    parking: parking?.find((item) => item.id === formik.values.parking)
      ?.display,
    zone: parkingZoneOption?.find((item) => item.id === formik.values.zone)
      ?.display,
  };

  const bookingData = bookingMutate?.data?.data?.data;

  const onClickPaymentButton = () => {
    const payload = {
      pmlsid: 1,
      amount: bookingData?.feeamount,
      bookingid: bookingData?.bookingid,
    };
    createPaymentQR.mutate(payload);
  };

  const createPaymentQR = useMutation({
    mutationFn: async (payload) => ReservationService.paymentQRRequest(payload),
    onSuccess: (response) => {
      if (response.issuccess) {
        if (!response?.data?.onlinetransid) {
          alert("Error occurred onlinetransid not found!");
          setActiveStep(1);
          return;
        }
        setActiveStep(4);
      } else {
        setIsPaymentComplete(false);
      }
    },
    onError: (response) => {
      alert("Error occurred!");
      setIsPaymentComplete(false);
    },
  });

  const addTicketMutate = useMutation({
    mutationFn: (payload) => TicketService.addTicket(payload),
    onError: (response) => {
      alert(response?.message || "Something went wrong with add ticket");
    },
  });

  const qrQueryStatus = useMutation({
    mutationFn: async (payload) => ReservationService.paymentQRQuery(payload),
    onSuccess: async (response) => {
      //success qr payment
      if (response.issuccess && response?.reserve?.ticketno) {
        clearInterval(intervalId);
        setActiveStep(5);
        const payload = {
          ticketno: bookingData?.ticketno,
          tickettype: "reserve",
          parkingid: bookingData?.parkingid,
          pmlsid: 1, // 1
          userid: profile?.userMenu?.userid,
          createby: profile?.userMenu?.userid,
        };
        addTicketMutate.mutate(payload);
      } else if (response?.message) {
        clearInterval(intervalId);
        const paymentStatusDesc =
          ONLINE_PAYMENT_STATUS[Number(response?.messsage)]?.description;
        alert(paymentStatusDesc);
        setIsPaymentComplete(false);
        setActiveStep(1);
      }
    },
    onError: (response) => {
      clearInterval(intervalId);
      alert("Error occurred!");
      setIsPaymentComplete(false);
      setActiveStep(1);
    },
  });

  // interval loop after createPaymentQR success && !isPaymentComplete
  // clear interval after return or qrQueryStatus completed
  useEffect(() => {
    let newIntervalId = null;
    if (createPaymentQR?.data?.issuccess && !isPaymentComplete) {
      newIntervalId = setInterval(() => {
        const payloadQRQueryStatus = {
          pmlsid: 1,
          onlinetransid: createPaymentQR?.data?.data?.onlinetransid,
          ticketno: bookingData?.ticketno,
          amount: bookingData?.feeamount,
          bookingid: bookingData?.bookingid,
        };
        qrQueryStatus.mutate(payloadQRQueryStatus);
      }, 3000);
      setIntervalId(newIntervalId);
    }

    return () => {
      clearInterval(newIntervalId);
    };
  }, [createPaymentQR?.data, isPaymentComplete, bookingData]);

  const onClickCancelConfirmPaymentButton = () => {
    setActiveStep(1);
    formik.resetForm();
  };

  // const paymentTypeMasterData = getMasterPaymentTypeQuery?.data;
  const paymentQueryStatusData = qrQueryStatus?.data;

  // map payment code
  // const paymentNameByPaymentCode = useMemo(() => {
  //   const find = paymentTypeMasterData?.find(
  //     (type) =>
  //       type.paymentcode ===
  //       paymentQueryStatusData?.receipt?.data?.payment?.paytype
  //   );

  //   if (find) {
  //     return i18n?.language === LANGUAGE.CODE.TH ? find?.descth : find?.descen;
  //   } else {
  //     return "";
  //   }
  // }, [paymentQueryStatusData, paymentTypeMasterData]);

  const paymentNameByPaymentCode = useMemo(() => {
    const find = paymentType?.find(
      (type) =>
        type.paymentcode ===
        paymentQueryStatusData?.receipt?.data?.payment?.paytype
    );

    if (find) {
      return i18n?.language === LANGUAGE.CODE.TH ? find?.descth : find?.descen;
    } else {
      return "";
    }
  }, [paymentQueryStatusData, paymentType, i18n?.language]);

  return (
    <>
      <div className="bg-[#E9F0F5] md:px-12 pt-[50px]  md:pt-[200px]">
        <ReservedParkingStepper
          sxa={{
            transform: isLGScr ? "translateY(-25px)" : "translateY(-38px)",
          }}
          activeStep={activeStep}
        />
      </div>
      {activeStep === 1 || activeStep === 2 ? (
        <BoxPageContent
          headerBlack={`${t("Fill in the Details ")} ${t(
            "Reserve a Parking Lot"
          )}`}
          breadcrumbRoot={t("Home")}
          breadcrumbBranch={t("Reserve a Parking Lot")}
        >
          <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <ReservedParking1
                formik={formik}
                parking={parking}
                parkingLoading={parkingLoading}
                parkingZoneOption={parkingZoneOption}
                parkingZoneLoading={parkingZoneLoading}
                provincesLicenseOptions={provincesLicenseOptions}
                parkingIsAvailable={parkingIsAvailable}
                checkParkingAvailableMutate={checkParkingAvailableMutate}
                activeStep={activeStep}
                onClickBookingButton={onClickBookingButton}
                onClickCheckAvailableButton={onClickCheckAvailableButton}
                setActiveStep={setActiveStep}
              />
            </form>
          </Box>
        </BoxPageContent>
      ) : activeStep === 3 ? (
        <BoxPageContent
          headerBlack="เลือกช่องทางการชำระเงิน"
          breadcrumbRoot={t("Home")}
          breadcrumbBranch={t("Reserve a Parking Lot")}
        >
          <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
            <ReservedParking3
              setActiveStep={setActiveStep}
              displayInformation={displayInformation}
              bookingData={bookingData}
              onClickCancelConfirmPaymentButton={
                onClickCancelConfirmPaymentButton
              }
              onClickPaymentButton={onClickPaymentButton}
              formik={formik}
              method={method}
              onChangePaymentMethod={onChangePaymentMethod}
            />
          </Box>
        </BoxPageContent>
      ) : activeStep === 4 ? (
        <BoxPageContent
          headerBlack={t("QR PromptPay")}
          breadcrumbRoot={t("Home")}
          breadcrumbBranch={t("Reserve a Parking Lot")}
        >
          <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
            <ReservedParkingWait paymentData={createPaymentQR?.data?.data} />
          </Box>
        </BoxPageContent>
      ) : activeStep === 5 ? (
        <BoxPageContent
          breadcrumbRoot={t("Home")}
          breadcrumbBranch={t("Reserve a Parking Lot")}
        >
          <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
            <ReservedParkingSummary
              responsePayment={paymentQueryStatusData}
              displayInformation={displayInformation}
              paymentNameByPaymentCode={paymentNameByPaymentCode}
              formik={formik}
            />
          </Box>
        </BoxPageContent>
      ) : null}
    </>
  );
};

export default ReservedParking;
