import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import DatePickerAOT from "../../components/Controls/DatePickerAOT";
import dayjs from "dayjs";
import ContainerCenter from "../../layouts/ContainerCenter";
import ButtonAction from "../../components/Base/ButtonAction";
import PageContent from "../../layouts/PageContent";
import { useFormik } from "formik";
import { InputTextFieldAOT } from "../../components/Controls";

const CloseParkingFee = () => {
  const { t } = useTranslation();
  // const [mode, setMode] = useState(1); //! set to 1 if feature is enable
  const formik = useFormik({
    initialValues: {
      ticketno: "",
      licenseplate: "",
      entrydate: "",
    },
    isInitialValid: false,
    onSubmit: (values) => {},
  });

  return (
    <>
      <div className="bg-[#E9F0F5] pt-5 md:pt-[150px]">
        <ContainerCenter>
          <form onSubmit={formik.handleSubmit} className="w-full">
            {true ? (
              <div>
                <p className="font-bold text-xl mb-2">
                  {t("Check a Parking Fee")} -{" "}
                  <span className="font-normal text-lg">
                    {t("Please specify entry date and license plate")}
                  </span>
                </p>
                <div className="flex flex-col md:flex-row items-center w-full gap-6 pb-4">
                  <div className="w-full md:w-[50%]">
                    <DatePickerAOT
                      label={t("Apply Start Date")}
                      name="entrydate"
                      val={formik.values.entrydate}
                      handleChange={(e) => {
                        let selectedDate = dayjs(e).format("YYYY-MM-DD");
                        formik.setFieldValue("entrydate", selectedDate);
                      }}
                      required
                      sxa={{ background: "white" }}
                    />
                  </div>
                  <div className="flex items-center gap-x-4 w-full">
                    <div className="w-[80%]">
                      <InputTextFieldAOT
                        label={t("License Plate")}
                        name="licenseplate"
                        val={formik.values.licenseplate}
                        handleChange={formik.handleChange}
                      />
                    </div>

                    <ButtonAction
                      type="submit"
                      color="primary"
                      disabled={true}
                      sx={{
                        width: 200,
                      }}
                    >
                      {t("Search")}
                    </ButtonAction>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full">
                <p className="font-bold text-xl mb-2">
                  {t("Check a Parking Fee")} -{" "}
                  <span className="font-normal text-lg">
                    {t(
                      "Calculate the parking fee by scanning the QR Code or typing the Parking Card ID"
                    )}
                  </span>
                </p>
                <div className="flex items-center gap-8 pb-5 ">
                  <div className="w-full">
                    <InputTextFieldAOT
                      label={t("Parking Card No")}
                      name="ticketno"
                      val={formik.values.ticketno}
                      handleChange={formik.handleChange}
                      sxb={{
                        endAdornment: (
                          <QrCodeScannerIcon
                            onClick={() => {}}
                            edge="end"
                            className="cursor-pointer"
                          />
                        ),
                      }}
                    />
                  </div>
                  <div className="w-full md:w-[40%] lg:w-[20%]">
                    <ButtonAction type="submit" disabled={true}>
                      {t("Search")}
                    </ButtonAction>
                  </div>
                </div>
              </div>
            )}
          </form>
        </ContainerCenter>
      </div>

      <PageContent
        breadcrumb={
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              {t("Home")}
            </Link>
            <Typography color="#245AB1">{t("Check a Parking Fee")}</Typography>
          </Breadcrumbs>
        }
      >
        <div className="flex justify-center">
          <img
            src="/images/carpark-banner-payment-not-ready.jpg"
            className="w-[400px] h-auto border"
            alt="carpark-banner-payment-not-ready"
          />
        </div>
      </PageContent>
    </>
  );
};

export default CloseParkingFee;
