/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import DateAndTimePickerAOT from "../components/Controls/DateAndTimePickerAOT";
import BoxCalParking from "../components/CalculatorParking/BoxCalParking";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { RESPONSE_CODE } from "../constants";
import { useMemo } from "react";

//? for test purposes
import NewSelectAOT from "../components/Controls/NewSelectAOT";
import { GpsFixedOutlined } from "@mui/icons-material";
import ContainerCenter from "../layouts/ContainerCenter";
import {
  generateAirportOptions,
  generateVehicleParkingOptions,
} from "../utils/optionsUtils";
import { getLangIdFormPMLSlang } from "../utils";
import PageContent from "../layouts/PageContent";
import ButtonAction from "../components/Base/ButtonAction";
import ParkingService from "../services/parking-service";
import ParkingFeeService from "../services/parkingfee-service";

const validationSchema = Yup.object().shape({
  pmlsid: Yup.number().required(),
  zone: Yup.number().required(),
  startdate: Yup.date().required(),
  enddate: Yup.date().required(),
  starttime: Yup.date().required(),
  endtime: Yup.date().required(),
});

function areDatesEqual(date1, date2) {
  return dayjs(date1).isSame(date2, "day");
}

const CalculatorParking = () => {
  const { lang } = useSelector((state) => state.auth);
  const { airports, loading: loadingMaster } = useSelector(
    (state) => state.masterData
  );
  const { t, i18n } = useTranslation();
  const [showParkingFeeCheckBox, setShowParkingFeeCheckBox] = useState(false);

  const airportOptions = useMemo(() => {
    return generateAirportOptions(airports);
  }, [airports]);

  const formik = useFormik({
    initialValues: {
      pmlsid: null,
      zone: null,
      startdate: "",
      starttime: "",
      enddate: "",
      endtime: "",
    },
    validationSchema,
    // isInitialValid: false,
    onSubmit: (values) => {
      try {
        const startDateFormatted = dayjs(values.startdate).format("YYYY-MM-DD");
        const startTimeFormatted = dayjs(values.starttime).format("HH:mm:ss");
        const endDateFormatted = dayjs(values.enddate).format("YYYY-MM-DD");
        const endTimeFormatted = dayjs(values.endtime).format("HH:mm:ss");
        const payload = {
          pmlsid: values.pmlsid,
          zone: values.zone,
          vehicletypeid: 1,
          startdate: startDateFormatted + " " + startTimeFormatted,
          enddate: endDateFormatted + " " + endTimeFormatted,
        };
        parkingFeeCheck.mutate(payload);
      } catch (err) {
        console.log(err);
      }
    },
  });

  // hide parking fee check box when form changes
  useEffect(() => {
    if (showParkingFeeCheckBox) {
      setShowParkingFeeCheckBox(false);
    }
  }, [formik.values]);

  useEffect(() => {
    formik?.resetForm();
  }, [i18n?.language]);

  useEffect(() => {
    formik.setValues({
      pmlsid: null,
      zone: null,
      startdate: "",
      starttime: "",
      enddate: "",
      endtime: "",
    });
  }, [lang]);

  const parkingFeeCheck = useMutation({
    mutationFn: async (payload) => ParkingFeeService.parkingFeeCheck(payload),
    onSuccess: (response) => {
      if (response.responsecode === RESPONSE_CODE.SUCCESS) {
        setShowParkingFeeCheckBox(true);
      } else {
        alert(response?.message);
      }
    },
    onError: (response) => {
      alert("Error occurred!");
      setShowParkingFeeCheckBox(false);
    },
  });

  const { data: parking, isLoading: parkingLoading } = useQuery({
    queryKey: ["GET_PARKING", formik.values.pmlsid, i18n?.language],
    queryFn: async () => {
      const res = await ParkingService.getParkingAll(
        formik.values.pmlsid,
        getLangIdFormPMLSlang(i18n?.language)
      );
      return res.data.allTbParking;
    },
    retry: false,
    enabled: formik.values.pmlsid !== null,
  });

  const parkingOptions = useMemo(() => {
    return generateVehicleParkingOptions(parking);
  }, [parking]);

  const selectedAirportText = useMemo(() => {
    const selectedAirport = airportOptions?.find(
      (d) => d.id === formik.values.pmlsid
    );
    return selectedAirport?.display || "";
  }, [airportOptions, formik.values.pmlsid]);

  const selectedParkingText = useMemo(() => {
    const selectedParking = parking?.find((d) => d.id === formik.values.zone);
    return selectedParking?.display || "";
  }, [parking, formik.values.zone]);

  return (
    <>
      <div className="bg-[#E9F0F5] pt-5 md:pt-[150px]">
        <ContainerCenter>
          <form onSubmit={formik.handleSubmit}>
            <div className="grid gap-10 grid-cols-1 lg:grid-cols-4">
              <NewSelectAOT
                name="pmlsid"
                label={t("Select the Airport")}
                options={airportOptions}
                value={formik.values.pmlsid}
                onChange={formik.handleChange}
                disabled={loadingMaster}
                loading={loadingMaster}
                icon={GpsFixedOutlined}
              />
              <NewSelectAOT
                name="zone"
                label={t("Select Parking Location")}
                options={parkingOptions}
                value={formik.values.zone}
                onChange={formik.handleChange}
                disabled={parkingLoading}
                icon={GpsFixedOutlined}
              />

              <DateAndTimePickerAOT
                labelDate={t("Entry Date")}
                labelTime={t("Time")}
                dateVal={formik.values.startdate}
                handleChangeDate={(e) => {
                  const selectedDate = dayjs(e);
                  const endDate = dayjs(formik.values.enddate);

                  if (endDate < selectedDate) {
                    alert("วันที่นำรถเข้า จะเลือกวันที่หลังนำรถออกไม่ได้");
                    return;
                  }

                  formik.setFieldValue("startdate", e);
                }}
                timeVal={formik.values.starttime}
                handleChangeTime={(e) => {
                  const entryDate = dayjs(formik.values.startdate);
                  const exitDate = dayjs(formik.values.enddate);
                  const datesAreEqual = areDatesEqual(entryDate, exitDate);
                  if (datesAreEqual) {
                    const entryDateTime = dayjs(e);
                    const exitDateTime = dayjs(formik.values.endtime);
                    if (entryDateTime.isAfter(exitDateTime)) {
                      alert("เวลานำรถเข้าจะเป็นเวลาหลังนำรถออกไมได้");
                      return;
                    }
                  }
                  formik.setFieldValue("starttime", e);
                }}
                sxa={{ width: "100%" }}
              />
              <DateAndTimePickerAOT
                labelDate={t("Exit Date")}
                labelTime={t("Time")}
                dateVal={formik.values.enddate}
                handleChangeDate={(e) => {
                  const selectedDate = dayjs(e);
                  const entryDate = dayjs(formik.values.startdate);

                  if (selectedDate < entryDate) {
                    alert("วันที่นำรถออก จะเลือกย้อนหลังวันที่เข้าจอดไม่ได้");
                    return;
                  }
                  formik.setFieldValue("enddate", e);
                }}
                timeVal={formik.values.endtime}
                handleChangeTime={(e) => {
                  const entryDate = dayjs(formik.values.startdate);
                  const exitDate = dayjs(formik.values.enddate);
                  const datesAreEqual = areDatesEqual(entryDate, exitDate);
                  if (datesAreEqual) {
                    const entryDateTime = dayjs(formik.values.starttime);
                    const exitDateTime = dayjs(e);
                    if (entryDateTime.isAfter(exitDateTime)) {
                      alert("เวลาของนำรถออกจะเป็นเวลาก่อนเข้าจอดไมได้");
                      return;
                    }
                  }
                  formik.setFieldValue("endtime", e);
                }}
                sxa={{ width: "100%" }}
              />
            </div>
            <div className="mt-4 pb-4 w-full md:w-[250px]">
              <ButtonAction type="submit" disabled={!formik.isValid}>
                {t("Calculate a Parking Fee")}
              </ButtonAction>
            </div>
          </form>
        </ContainerCenter>
      </div>

      <PageContent
        breadcrumb={
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              {t("Home")}
            </Link>
            <Typography color="#245AB1">
              {t("Calculate a Parking Fee")}
            </Typography>
          </Breadcrumbs>
        }
      >
        <BoxCalParking
          data={parkingFeeCheck?.data?.data}
          airportText={selectedAirportText}
          parkingZoneText={selectedParkingText}
        />
      </PageContent>
    </>
  );
};

export default CalculatorParking;
