import React from "react";
import { useTranslation } from "react-i18next";
import { LANGUAGE } from "../constants";

const PrivacyPolicyPage = () => {
  const { i18n } = useTranslation();

  return (
    <div className="w-full">
      <div
        style={{
          backgroundImage: "url('/images/change_vehicle/img_changecar.png')",
          height: 300,
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      />
      <div className="container mx-auto py-24 ">
        {i18n.language === LANGUAGE.CODE.TH ? <English /> : <English />}
      </div>
    </div>
  );
};

// const Thai = () => {
//   return (
//     <div>
//       <h1 className="text-center text-2xl font-semibold">
//         นโยบายการใช้คุกกี้ (Cookies Policy)
//       </h1>
//       <p className="indent-28 mt-10 px-10">
//         บริษัท ท่าอากาศยานไทย จำกัด (มหาชน) (“ทอท.”)
//         มีการใช้งานคุกกี้หรือเทคโนโลยีอื่นใดที่มีลักษณะใกล้เคียงกันบนเว็บไซต์ของ
//         ทอท. เพื่อช่วยให้ท่านได้รับประสบการณ์ที่ดีจากการใช้งานเว็บไซต์
//         และช่วยให้ ทอท.
//         สามารถพัฒนาคุณภาพการให้บริการเว็บไซต์ที่ตรงต่อความต้องการของท่านมากยิ่งขึ้น
//       </p>
//       <ul className="list-decimal px-28 mt-10 ">
//         <div className="py-5">
//           <li className="text-lg font-medium ">คุกกี้คืออะไร</li>
//           <p className="py-2 indent-12">
//             คุกกี้ คือ
//             ไฟล์ข้อความขนาดเล็กที่ถูกดาวน์โหลดไปยังเว็บเบราว์เซอร์บนอุปกรณ์อิเล็กทรอนิกส์
//             หรืออุปกรณ์เชื่อมต่ออินเทอร์เน็ตของท่าน
//             เพื่อบันทึกข้อมูลเกี่ยวกับการเยี่ยมชมเว็บไซต์ของ ทอท.
//             และการตั้งค่าต่างๆ ของท่าน โดยเครื่องเซิร์ฟเวอร์ของ ทอท.
//             จะเรียกดูข้อมูลที่ถูกบันทึกและเก็บรวบรวมและใช้ข้อมูลดังกล่าว
//             เพื่อพัฒนาการให้บริการของ ทอท.
//             แก่ท่านได้อย่างต่อเนื่องและมีประสิทธิภาพ
//           </p>
//         </div>

//         <div className="py-5">
//           <li className="text-lg font-medium ">
//             ประเภทและวัตถุประสงค์ในการใช้งานคุกกี้
//           </li>
//           <p className="py-2 indent-12">
//             เว็บไซต์ของ ทอท. จะมีการใช้งานคุกกี้ตามประเภทและวัตถุประสงค์ต่างๆ
//             เช่น
//             <p>
//               2.1 คุกกี้ประเภทจำเป็นถาวร (Strictly Necessary Cookies)
//               คุกกี้ประเภทนี้มีความสำคัญต่อการทำงานของเว็บไซต์ ทอท.
//               เพื่อให้ท่านสามารถเข้าถึงข้อมูลและใช้งานเว็บไซต์ของ ทอท.
//               ได้อย่างปลอดภัย
//             </p>
//             <p>
//               2.2 คุกกี้ประเภทการทำงาน (Functional Cookies)
//               คุกกี้ประเภทนี้จะจดจำท่านระหว่างการเยี่ยมชม
//               และตั้งค่าเว็บไซต์ให้สอดคล้องกับการใช้งานของท่าน
//               เพื่ออำนวยความสะดวกเมื่อท่านกลับเข้ามาใช้งานเว็บไซต์อีกครั้ง
//             </p>
//             <p>
//               2.3 คุกกี้ประเภทการวิเคราะห์หรือวัดผลการทำงาน
//               (Analytic/Performance Cookies) คุกกี้ประเภทนี้ทำให้ ทอท.
//               สามารถวิเคราะห์หรือวัดผลการทำงานของเว็บไซต์
//               และเข้าใจถึงความสนใจของท่าน เพื่อนำไปบริหารจัดการ ปรับปรุง
//               และพัฒนาเว็บไซต์ให้ดียิ่งขึ้น
//             </p>
//             <p>
//               2.4 คุกกี้ประเภทการโฆษณา (Advertising Cookies)
//               คุกกี้ประเภทนี้จะจดจำการตั้งค่าของท่านในการเข้าใช้งานหน้าเว็บไซต์
//               และนำไปใช้เป็นข้อมูลประกอบการปรับเปลี่ยนหน้าเว็บไซต์ เพื่อตั้งค่า
//               ปรับแต่ง
//               และนำเสนอข้อมูลหรือโฆษณาให้เหมาะสมตรงตามความสนใจและความชื่นชอบของท่านมากยิ่งขึ้น
//             </p>
//           </p>
//           <li className="text-lg font-medium ">การเก็บข้อมูลของคุกกี้</li>
//           <p className="py-2 indent-12">
//             ทอท.
//             เก็บรวบรวมข้อมูลการเยี่ยมชมเว็บไซต์ของท่านโดยอัตโนมัติผ่านทางคุกกี้
//             เช่น
//             <p>
//               3.1 อินเทอร์เน็ตโดเมน และหมายเลขประจำเครื่องคอมพิวเตอร์
//               หรืออินเทอร์เน็ตโพรโทคอล (IP Address) จากจุดที่ท่านเข้าสู่เว็บไซต์
//             </p>
//             <p>
//               3.2 ประเภทของเบราว์เซอร์ซอฟต์แวร์
//               ตลอดจนโครงสร้างและระบบปฏิบัติงานที่ใช้ในการเข้าสู่เว็บไซต์ของ ทอท.
//             </p>
//             <p>3.3 วันที่และเวลาที่ท่านเข้าสู่เว็บไซต์ของ ทอท.</p>
//             <p>
//               3.4
//               ที่อยู่ของเว็บไซต์อื่นที่ท่านใช้เพื่อเชื่อมโยงท่านเข้าสู่เว็บไซต์ของ
//               ทอท. หรือออกจากเว็บไซต์ของ ทอท
//             </p>
//             <p>
//               3.5 จำนวนผู้เยี่ยมชมเว็บไซต์ของ ทอท.
//               พฤติกรรมการเยี่ยมชมเว็บไซต์ของ ทอท. จำนวนหน้าเว็บไซต์ของ ทอท.
//               ที่ท่านเข้าใช้งาน ระยะเวลาที่ท่านใช้ในการเยี่ยมชม
//               และเนื้อหาบนหน้าเว็บไซต์ของ ทอท. ที่ท่านเยี่ยมชม
//             </p>
//             <p>
//               3.6 ภาษาที่ท่านเลือกใช้บนหน้าเว็บไซต์ และคำค้น (Keyword Search)
//               ล่าสุดที่ท่านใช้งาน ทั้งนี้ ข้อมูลดังกล่าวจะไม่ถูกเปิดเผย
//               หรือบ่งบอกความเป็นตัวตนของท่านได้ และไม่มีข้อมูลจำเพาะบุคคล
//             </p>
//           </p>
//           <li className="text-lg font-medium ">การตั้งค่าและการปฏิเสธคุกกี้</li>
//           <p className="py-2 indent-12">
//             ท่านสามารถเลือกปฏิเสธการทำงานของคุกกี้ได้
//             โดยการตั้งค่าเบราว์เซอร์หรือการตั้งค่าความเป็นส่วนตัวของท่าน
//             เพื่อระงับการเก็บรวบรวมข้อมูลโดยคุกกี้ในอนาคต
//             (ท่านสามารถศึกษารายละเอียดเพิ่มเติมเกี่ยวกับการตั้งค่าและการปฏิเสธคุกกี้ได้ที่
//             AboutCookies.org)
//             <p>
//               อย่างไรก็ตาม บริการบางอย่างบนเว็บไซต์ของ ทอท. จำเป็นต้องใช้คุกกี้
//               หากท่านตั้งค่าเบราว์เซอร์ หรือตั้งค่าความเป็นส่วนตัวของท่าน
//               ด้วยการปฏิเสธการทำงานของคุกกี้ทั้งหมด
//               อาจส่งผลให้ท่านไม่ได้รับความสะดวกในการเข้าใช้งานเว็บไซต์
//               และถูกจำกัดพื้นที่การใช้งานบนเว็บไซต์ได้
//             </p>
//           </p>
//         </div>
//       </ul>
//     </div>
//   );
// };

const English = () => {
  return (
    <div className="">
      <h1 className="text-3xl font-bold mb-4 text-center">Privacy Policy</h1>
      <p className="mb-4">Effective Date: August 04, 2023</p>
      <p className="mb-4 indent-12">
        AOT Smart Car Park, Airports of Thailand Public Company Limited ("AOT”,
        "we", "us", "the Service" or "our" recognizes the importance of
        protecting the privacy of your personal information, and we have
        prepared this Privacy Policy to provide you with important information
        about the privacy practices applicable to AOT Smart Car Park and any
        apps or website that links to or refers to this Privacy Policy
        (collectively, the "Services").
      </p>
      <h2 className="mt-5 font-semibold text-lg">1. Information We Collect</h2>

      <h3 className="indent-10">1.1 Personal Information:</h3>
      <p>
        When you register to use our Service, we will store the following
        personal information in the AOT Data Center Server throughout for the
        appropriate period according to AOT’s scope and purpose including other
        important matters such as legal requirements, accounting, and auditing
        purposes:
      </p>
      <ul className="list-disc px-10">
        <li>Full name</li>
        <li>Address</li>
        <li>Identification information (e.g., National ID number)</li>
        <li>Telephone number</li>
        <li>Email address</li>
        <li>Gender</li>
        <li>Nationality</li>
        <li>Date of birth</li>
        <li>
          Working information, such as affiliation, organization unit in AOT.
        </li>
        <li>
          Car information, such as license plate, license province, brand,
          model, color, and owner
        </li>
        <li>
          Documents copy such as National ID Card, Employee ID card, Security
          card, Employee Certificate of Airport, Car Registration book, etc.
          Then, when the user applies for parking membership. The admin will
          verify the documents copy for personal identify and generate the
          member card.
        </li>
      </ul>

      <h3 className="indent-10">1.2 Automatically Collected Information:</h3>
      <p>
        In addition to the personal information mentioned above, we may collect
        certain information automatically when you use the Service. This may
        include:
      </p>
      <ul className="list-disc px-10">
        <li>Device information (e.g., device type, operating system)</li>
        <li>Log data (e.g., access times, pages visited, actions performed)</li>
        <li>Usage information (e.g., features used, preferences selected)</li>
        <li>
          Cookies and similar technologies (please refer to our Cookie Policy
          for more information)
        </li>
      </ul>

      <h2 className="mt-5 font-semibold text-lg">2. Use of Information</h2>

      <p>
        We use the collected personal information for the following purposes:
      </p>
      <ul className="list-disc px-10">
        <li>To create and manage user accounts</li>
        <li>To provide and improve the Service</li>
        <li>To communicate with users and respond to their inquiries</li>
        <li>
          To enforce our terms of service and protect the rights and safety of
          users
        </li>
        <li>To comply with legal obligations</li>
      </ul>

      <h2 className="mt-5 font-semibold text-lg">
        3. Data Sharing and Disclosure
      </h2>

      <p className="indent-10">
        The data does not share with any third party and it stores at the AOT
        Data Center Server.
      </p>

      <h2 className="mt-5 font-semibold text-lg">4. Data Security</h2>

      <p className="indent-10">
        We implement reasonable security measures to protect the personal
        information we collect. However, please note that no method of
        transmission or storage can guarantee absolute security. We encourage
        you to take steps to protect your personal information and not to share
        sensitive information with us or others via the Service.
      </p>

      <h2 className="mt-5 font-semibold text-lg">5. Your Choices and Rights</h2>

      <p className="indent-10">
        You have certain rights regarding your personal information, which may
        include the right to access, correct, update, or delete the information
        we hold about you. You may also have the right to object to certain
        processing activities or to restrict the use of your information. To
        exercise these rights, please contact us using the information provided
        in Section 7.
      </p>

      <h2 className="mt-5 font-semibold text-lg">6. Children's Privacy</h2>

      <p className="indent-10">
        Our Service is not directed to individuals under the age of 18. We do
        not knowingly collect personal information from children. If you are a
        parent or guardian and believe that your child has provided us with
        personal information without your consent, please contact us, and we
        will take steps to remove the information from our systems.
      </p>

      <h2 className="mt-5 font-semibold text-lg">7. Contact Us</h2>

      <p className="indent-10">
        If you have any questions, concerns, or requests regarding this Privacy
        Policy or our privacy practices, please contact us at:
      </p>

      <p className="indent-10">
        Data Controller Contact Information
        <br />
        Airports of Thailand Public Company Limited (AOT)
        <br />
        333 Cherdwutagard Road, Srikan, Don Mueang, Bangkok 10210, Thailand
        <br />
        Tel.: 1722 or (+66) 2535 1192
        <br />
        E-mail: aotpr@airportthai.co.th
      </p>
      <div className="mt-8">
        <p className="indent-10">
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and the "Effective Date" at the top will
          indicate the latest revision. We encourage you to review this Privacy
          Policy periodically to stay informed about our data practices.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
