import { Box, styled, useMediaQuery } from "@mui/material";
import React from "react";

const OuterBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "0",
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: "20px",
  paddingRight: "20px",
  width: "100%",
  overflow: "hidden",
  transform: "translateY(10px)",
}));

const SemiBox = styled(Box)(({ theme }) => ({
  width: "25px",
  height: "25px",
  borderRadius: "50%",
  borderTop: "5px solid #B3BBC2",
  backgroundColor: "#FFF",
}));

const BoxSemiCircle = () => {
  const isLGScr = useMediaQuery((theme) => theme.breakpoints.only("lg"));
  const isXLScr = useMediaQuery((theme) => theme.breakpoints.only("xl"));
  const isMDScr = useMediaQuery((theme) => theme.breakpoints.only("md"));

  return (
    <OuterBox sx={{}}>
      {isXLScr ? (
        <>
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
        </>
      ) : isLGScr ? (
        <>
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
        </>
      ) : isMDScr ? (
        <>
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
        </>
      ) : (
        <>
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
          <SemiBox />
        </>
      )}
    </OuterBox>
  );
};

export default BoxSemiCircle;
