import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js";
import { DATE_FORMATS } from "../constants";
dayjs.extend(utc);
dayjs.extend(timezone);

const formattedPrice = (val) => {
  return val
    ? Number(val).toLocaleString("th-TH", {
        style: "currency",
        currency: "THB",
      })
    : 0;
};

const formattedPriceWithTextCurrency = (val, locale) => {
  const currency = locale === "th" ? "THB" : "THB"; //TODO: map locale currency
  const formattedAmount =
    currency +
    " " +
    Number(val).toLocaleString("en-US", { minimumFractionDigits: 2 });

  return formattedAmount;
};

const formattedPriceOnlyDigit = (val, digit) => {
  const formattedAmount = Number(val).toLocaleString("th-TH", {
    minimumFractionDigits: digit,
  });
  return formattedAmount;
};

const formattedDateByLocale = (date, locale) => {
  return dayjs(date).format("DD MMMM YYYY");
};

const formattedDate_01 = (date) => {
  if (!date) return;
  return dayjs(date).format(DATE_FORMATS.FORMAT_03);
};

const formattedBase64Image = (ext, dataBase64) => {
  return `data:image/${ext};base64,${dataBase64}`;
};

function convertGmtToClientTimeZone(gmtTime) {
  // Comprehensive list of GMT+7 time zones
  const gmtPlus7Zones = [
    "Asia/Bangkok",
    "Asia/Ho_Chi_Minh",
    "Asia/Jakarta",
    "Asia/Krasnoyarsk",
    "Asia/Novosibirsk",
    "Asia/Brunei",
    "Asia/Pontianak",
    "Asia/Makassar",
    "Australia/Darwin",
  ];
  const inputFormat = "YYYY-MM-DD HH:mm:ss";
  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (clientTimeZone.includes(gmtPlus7Zones)) {
    return gmtTime.format(inputFormat);
  }

  const timeInGmtPlus7 = dayjs.tz(gmtTime, inputFormat, "Asia/Bangkok");
  const timeInGmt = timeInGmtPlus7.utc();
  // Convert GMT time to client's time zone (e.g., GMT+8)
  const timeInClientZone = timeInGmt.tz(clientTimeZone).format(inputFormat);
  return timeInClientZone;
}


export {
  formattedPrice,
  formattedDateByLocale,
  formattedPriceWithTextCurrency,
  formattedPriceOnlyDigit,
  formattedBase64Image,
  formattedDate_01,
  convertGmtToClientTimeZone
};
