const LocalStorageKey = {
  PMSUSER: "pmsuser",
  ACCESS_TOKEN: "accesstoken",
  REFRESH_TOKEN: "refreshtoken",
  PMLSID: "pmlsid",
};

// Get item from localStorage by key with proper handling of invalid data
const getItemFromLocalStorageByKey = (key) => {
  if (!key) return null;
  const item = localStorage.getItem(key);

  // Check if the item is not null or invalid ("undefined" as a string is also considered invalid)
  if (item === null || item === "undefined") {
    return null;
  }

  try {
    return JSON.parse(item);
  } catch (error) {
    console.error(`Error parsing JSON for key: ${key}`, error);
    return null;
  }
};

// Set item to localStorage with a check to ensure the data is valid
const setItemLocalStorage = (key, data) => {
  if (!key || data === undefined || data === null) return;

  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.error(`Error setting item in localStorage for key: ${key}`, error);
  }
};

// Retrieve token from storage
const getTokenFromStorage = () => {
  const data = getItemFromLocalStorageByKey(LocalStorageKey.PMSUSER);
  return data ? data.accesstoken : null;
};

// Retrieve refresh token from storage
const getRefreshTokenFromStorage = () => {
  const data = getItemFromLocalStorageByKey(LocalStorageKey.PMSUSER);
  return data ? data.refreshtoken : null;
};

// Retrieve PMLS ID from storage
const getPmlsIdFromStorage = () => {
  return getItemFromLocalStorageByKey(LocalStorageKey.PMLSID);
};

export {
  LocalStorageKey,
  getTokenFromStorage,
  getRefreshTokenFromStorage,
  getItemFromLocalStorageByKey,
  setItemLocalStorage,
  getPmlsIdFromStorage,
};
