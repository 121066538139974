/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TitleWithValue } from "../Base/TitleWithValue";
import {
  convertGmtToClientTimeZone,
  formattedPriceWithTextCurrency,
} from "../../utils/format";
import dayjs from "dayjs";
import { DATE_FORMATS } from "../../constants";
import BoxContent from "../Box/BoxContent";

const calPaymentExpiredDate = (currentDate, expiredt) => {
  return currentDate > expiredt;
};

const WaitPaymentStep = ({
  requestData,
  callBackWhenPaymentExpired,
  waitPaymentTopHeaderDisplay,
  showCheckPaymentButton = false,
  onClickCheckPaymentBtnHandler,
}) => {
  const { t } = useTranslation();
  const [dateNow, setDateNow] = useState(dayjs());
  const [intervalId, setIntervalId] = useState(null);
  const [renderOne, setOneRenderOne] = useState(true);
  const [oneMinutePassed, setOneMinutePassed] = useState(false); // enabled check payment after 1 minute
  const bankexpiredt = convertGmtToClientTimeZone(requestData?.bankexpiredt);
  // const bankexpiredt = "2024-08-19 22:50:00"; //for bankexpiredt test only

  useEffect(() => {
    // Update date every second
    const newIntervalId = setInterval(() => {
      setDateNow(dayjs());
    }, 1000);
    setIntervalId(newIntervalId);

    // Set oneMinutePassed to true after 1 minute
    const oneMinuteTimer = setTimeout(() => {
      setOneMinutePassed(true);
    }, 60000); // 60000 milliseconds = 1 minute

    // Cleanup the interval when the component is unmounted
    return () => {
      clearInterval(newIntervalId);
      clearTimeout(oneMinuteTimer);
    };
  }, []);

  // Trigger payment expired and render one time only
  useEffect(() => {
    if (calPaymentExpiredDate(dateNow, dayjs(bankexpiredt)) && renderOne) {
      clearInterval(intervalId); // Clear interval when payment expires
      callBackWhenPaymentExpired();
      setOneRenderOne(false);
    }
    return () => {
      if (!renderOne) {
        setOneRenderOne(true);
      }
    };
  }, [bankexpiredt, dateNow, renderOne]);

  return (
    <section className="flex items-center flex-col w-full gap-y-3 text-left">
      <div className="text-center flex flex-col items-center">
        <img
          src="/images/payment/img_wait_payment.svg"
          className="w-[190px] h-auto"
          alt="waiting_payment_image"
        />
        <p className="text-[#EF8A49] font-bold text-2xl mt-6">
          {t("Waiting for payment")}
        </p>
        <p className="mt-4 font-normal text-sm">
          {dateNow.format(DATE_FORMATS.FORMAT_02)}
        </p>
      </div>
      <div className="w-full max-w-3xl space-y-10" id="waitingPaymentBox">
        <BoxContent boxBorderRadius={2}>
          <div className="flex flex-col items-center gap-y-6 ">
            {/* Renew */}
            {waitPaymentTopHeaderDisplay && waitPaymentTopHeaderDisplay}
            <div className="w-full">
              <TitleWithValue
                title={t("Payment Amount")}
                titleStyles="font-bold"
                value={formattedPriceWithTextCurrency(requestData?.amount)}
                valueStyles="font-bold"
              />
            </div>
            <div className="bg-[#113566] h-16 w-full flex items-center justify-center gap-x-4">
              <img
                src="/images/feeparking/tqp.png"
                className="w-[115px] h-auto object-contain"
                alt="tqp"
              />
              <p className="text-base lg:text-xl text-white font-semibold">
                {t("Scan the QR Code to pay")}
              </p>
            </div>
            <div className="w-[230px] h-auto space-y-2">
              <img
                src={`data:image/png;base64,${requestData?.qrbase64}`}
                className="w-full h-auto"
                alt="qr"
              />
            </div>
            <div className="text-center">
              <p className="text-sm">{requestData?.ppnameth}</p>
              <p className="text-sm">{requestData?.ppnameen}</p>
              <p className="text-sm">
                {t("BillerID")} {requestData?.ppid}
              </p>
            </div>
            <div className="mt-2 text-sm">
              <div className="grid grid-cols-2">
                <p>{t("Reference No")}:</p>
                <p className="text-right">{requestData?.onlinetransid}</p>
              </div>
              <div className="grid grid-cols-2">
                <p>{t("Amount")}:</p>
                <p className="text-right">
                  {requestData?.amount?.toLocaleString()} Baht
                </p>
              </div>
              <div className="grid grid-cols-2">
                <p>{t("Please pay before")}:</p>
                <p className="text-red-500 text-right">
                  {dayjs(bankexpiredt).format(DATE_FORMATS.FORMAT_02)}
                </p>
              </div>
            </div>
          </div>
        </BoxContent>
        {showCheckPaymentButton && (
          <div className="flex items-center justify-center">
            <button
              className="bg-orange-400 rounded-xl px-12 py-3 text-white hover:bg-opacity-50 disabled:bg-[rgba(0,0,0,0.04)] disabled:cursor-not-allowed disabled:text-[rgba(0,0,0,0.25)]"
              disabled={!oneMinutePassed}
              onClick={onClickCheckPaymentBtnHandler}
            >
              {t("Verify Payment")}
            </button>
          </div>
        )}
        <div>
          <h2 className="font-bold text-lg lg:text-xl">
            {t("the instructions")}
          </h2>
          <ul className="list-decimal pl-10 mt-2 space-y-2">
            <li className="text-sm lg:text-base">{t("stepone")}</li>
            <li className="text-sm lg:text-base">{t("steptwo")}</li>
            <li className="text-sm lg:text-base">{t("stepthree")}</li>
            <li className="text-sm lg:text-base">{t("stepfour")}</li>
            <li className="text-sm lg:text-base">{t("stepfive")}</li>
            <li className="text-sm lg:text-base">{t("stepsix")}</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default WaitPaymentStep;
