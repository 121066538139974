import { createSlice } from "@reduxjs/toolkit";
import { AIRPORT } from "../constants"; // Adjust the path to your constants file

const initialState = {
  airportList: [],
  pmlsList: [],
  pmlsId: undefined,
};

export const airportSlice = createSlice({
  name: "airport",
  initialState,
  reducers: {
    setAirportList: (state, action) => {
      state.airportList = action.payload;
    },
    setPmlsList: (state, action) => {
      state.pmlsList = action.payload;
    },
    setPmlsId: (state, action) => {
      state.pmlsId = Number(action.payload); // Ensure it's a number
    },
    updatePmlsIdFromAirport: (state, action) => {
      const airport = action.payload;

      if (airport === AIRPORT.CODE.BKK) {
        state.pmlsId = AIRPORT.ID.SUVARNABHUMI; // Update to Suvarnabhumi ID
      } else if (airport === AIRPORT.CODE.DMK) {
        state.pmlsId = AIRPORT.ID.DONMUANG; // Update to Don Muang ID
      } else {
        state.pmlsId = undefined; // Default to an invalid or placeholder ID
      }
    },
  },
});

export const {
  setAirportList,
  setPmlsList,
  setPmlsId,
  updatePmlsIdFromAirport,
} = airportSlice.actions;
export default airportSlice.reducer;
