import { Box } from "@mui/material";
import { DesktopDatePicker, DesktopTimePicker } from "@mui/x-date-pickers";
import React from "react";
import { InputAOT } from "./InputAOT";

const DateAndTimePickerAOT = ({
  labelDate,
  labelTime,
  width,
  dateVal,
  handleChangeDate,
  timeVal,
  handleChangeTime,
  sxa,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "2px",
        // backgroundColor: "#FFFFFF",
        width,
        // borderRadius: "10px",
        // border: "2px solid #3D9AEE",
        ...sxa,
      }}
    >
      <DesktopDatePicker
        label={labelDate}
        inputFormat="DD/MM/YYYY"
        value={dateVal}
        onChange={handleChangeDate}
        renderInput={(params) => (
          <InputAOT
            {...params}
            variant="filled"
            InputProps={{ ...params?.InputProps, disableUnderline: true }}
            sx={{
              width: "65%",
              svg: {
                //padding: '1rem',
                //width: '24px',
                //height: '24px',
                color: "#245AB1",
              },
              button: {
                borderRadius: "none",
                "&:hover": { backgroundColor: "transparent" },
              },
              input: {
                backgroundColor: "#FFFFFF !important",
                borderRadius: "10px",
              },
            }}
          />
        )}
      />
      {/* <Box
        sx={{
          width: "1%",
          borderLeft: "1px solid #245AB1",
          height: "3rem",
        }}
      >
        &nbsp;
      </Box> */}
      <DesktopTimePicker
        label={labelTime}
        inputFormat="HH:mm"
        ampm={false}
        value={timeVal}
        onChange={handleChangeTime}
        renderInput={(params) => (
          <InputAOT
            {...params}
            variant="filled"
            InputProps={{ ...params?.InputProps, disableUnderline: true }}
            sx={{
              width: "34%",
              svg: {
                // padding: '10px',
                // width: '24px',
                // height: '24px',
                color: "#245AB1",
              },
              button: {
                borderRadius: "none",
                "&:hover": { backgroundColor: "transparent" },
              },
              input: {
                backgroundColor: "#FFFFFF !important",
                borderRadius: "10px",
              },
            }}
          />
        )}
      />
    </Box>
  );
};

export default DateAndTimePickerAOT;
