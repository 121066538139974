import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const CookiePolicyPopup = ({ open, onClose }) => {
  const { t } = useTranslation();
  const handleAccept = () => {
    Cookies.set("cookieAccepted", "true", { expires: 365 }); // Set the cookie to expire in 1 year
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          position: "fixed",
          bottom: "0",
          width: "100%",
          backgroundColor: "#ebebeb", // Customize the background color here
          maxWidth: "100%",
          margin: 0,
        },
      }}
    >
      <DialogContent>
        <div className="flex justify-between items-center">
          <p>
            {t("Cookies msg")}{" "}
            <a
              className="underline cursor-pointer"
              target="_blank"
              rel="noreferrer"
              href={"/bkk/policy"}
            >
              {t("Cookies msg cont")}
            </a>
          </p>
          <button
            className="bg-blue-900 px-4 py-2 text-white rounded-md"
            onClick={handleAccept}
          >
            {t("Agree")}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CookiePolicyPopup;
