import {
  styled,
  Menu,
  Button,
  MenuItem,
  Typography,
  alpha,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useState } from "react";
import { LANGUAGE } from "../constants/index";

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.text.primary,
    boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px, 
      rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, 
      rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, 
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px`,
    "& .MuiMenuItem-root": {
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const FlagText = styled(Typography)(({ theme }) => ({
  paddingRight: "1rem",
  fontSize: theme.breakpoints.down("md") ? "16px" : "18px",
  lineHeight: theme.breakpoints.down("md") ? "22px" : "33px",
  fontFamily: "Noto Sans Thai",
}));

const FlagImage = styled("img")({
  marginLeft: "1rem",
  marginRight: "1rem",
});

const languages = [
  {
    code: LANGUAGE.CODE.TH,
    label: "Thai",
    flag: "/images/header/ico_thaiflage.svg",
  },
  {
    code: LANGUAGE.CODE.EN,
    label: "English",
    flag: "/images/header/ico_engflage.svg",
  },
];

const LanguagesDropdown = ({ textColor = "text-black" }) => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const lang = i18n.language || LANGUAGE.CODE.TH;
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleChangeLang = (lng) => {
    i18n.changeLanguage(lng);
    dayjs.locale(lng);
    handleClose();
  };

  return (
    <>
      <Button
        sx={{ textTransform: "none", color: "white" }}
        onClick={handleClick}
        className="flex items-center gap-2"
      >
        <div className="flex items-center">
          <FlagImage
            src={lang === LANGUAGE.CODE.TH ? languages[0].flag : languages[1].flag}
            alt="flag-image"
          />
          <Typography className={`${textColor} text-lg`}>
            {lang === LANGUAGE.CODE.TH ? "Thai" : "English"}
          </Typography>
        </div>
        <KeyboardArrowDownIcon />
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "language-selector",
        }}
      >
        {languages.map(({ code, label, flag }) => (
          <MenuItem
            key={code}
            disableRipple
            onClick={() => handleChangeLang(code)}
          >
            <FlagImage src={flag} alt={`${label}-flag`} />
            <FlagText>{label}</FlagText>
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default LanguagesDropdown;
