const AIRPORT = {
  ID: {
    SUVARNABHUMI: 1,
    DONMUANG: 2,
  },
  CODE: {
    BKK: "bkk",
    DMK: "dmk",
  },
  CODE_PATH: {
    BKK: "/bkk",
    DMK: "/dmk",
  },
  AVAILABLE: {
    BKK: process.env.REACT_APP_AIRPORT_SUVARNABHUMI_AVAILABLE,
    DMK: process.env.REACT_APP_AIRPORT_DONMUANG_AVAILABLE,
  },
  BKK_IS_AVAILABLE: process.env.REACT_APP_AIRPORT_SUVARNABHUMI_AVAILABLE === "1",
  DMK_IS_AVAILABLE: process.env.REACT_APP_AIRPORT_DONMUANG_AVAILABLE === "1",
  IS_ALL_AVAILABLE:
    process.env.REACT_APP_AIRPORT_SUVARNABHUMI_AVAILABLE === "1" &&
    process.env.REACT_APP_AIRPORT_DONMUANG_AVAILABLE === "1",
  TEXT_BY_ID: {
    1: {
      th: "ท่าอากาศยานสุวรรณภูมิ",
      en: "Suvarnabhumi Airport",
    },
    2: {
      th: "ท่าอากาศยานดอนเมือง",
      en: "Don Mueang International Airport",
    },
  },
};

const LANGUAGE = {
  ID: {
    TH: 2,
    EN: 1,
  },
  CODE: {
    TH: "th",
    EN: "en",
  },
};

const RESPONSE_CODE = {
  SUCCESS: "2100000",
};

const DATE_FORMATS = {
  DEFAULT: "YYYY-MM-DD HH:mm:ss",
  FORMAT_01: "DD/MM/YYYY",
  FORMAT_02: "DD/MM/YYYY HH:mm:ss",
  FORMAT_03: "DD/MM/YYYY HH:mm",
};

const PUBLIC_WEB_TOKEN = process.env.REACT_APP_TOKEN_PUBLIC;

const CONTENT_TYPE_CODE = {
  NEWS: "CT001",
  PROMO: "CT002",
  INFO: "CT003",
};

const VISIBILITY_TYPE = {
  PUBLIC: "V001",
  MEMBER: "V002",
};

const MEMBER_STATUS = {
  REGISTER: "Register",
  ACTIVE: "Active",
};

const REGEX = {
  EMAIL: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
  LICENSE_PLATE: /^[a-zA-Zก-ฮ-0-9]+$/,
  PHONE:
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  PASSWORD: /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/,
  NAME: /^[A-Za-zก-๙]+$/,
};

const FOOTER_CONTACT_US = {
  FACEBOOK: "https://www.facebook.com/AOTofficial",
  LINE: "https://line.me/R/ti/p/@889xttyg?from=page",
  INSTAGRAM: "https://www.instagram.com/aot_official_th",
  TWITTER: "https://twitter.com/AOTthailand",
};

const FACING_MODE = {
  ENVIRONMENT: "environment",
  USER: "user",
};

const PROCESS = {
  TYPE_CODE: {
    RENEW: "P02",
  },
  RENEW_STATE: {
    RENEW: "R01",
    WAIT_PAYMENT: "R02",
    SUCCESS_RENEW: "R03",
    CANCEL_RENEW: "R04",
  },
};

export {
  PROCESS,
  LANGUAGE,
  RESPONSE_CODE,
  PUBLIC_WEB_TOKEN,
  CONTENT_TYPE_CODE,
  VISIBILITY_TYPE,
  MEMBER_STATUS,
  REGEX,
  FOOTER_CONTACT_US,
  FACING_MODE,
  AIRPORT,
  DATE_FORMATS,
};
