import { Box, styled, Typography, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const OuterBox = styled(Box)(({ theme }) => ({
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "0px 3px 6px #00000029",
  border: "1px solid #B3BBC2",
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    //minHeight: '300px'
  },
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  background: "#E9F0F5 0% 0% no-repeat padding-box",
  padding: "1rem",
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    //minHeight: '300px'
  },
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 20px/40px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("lg")]: {
    //minHeight: '300px'
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingLeft: "3rem",
  paddingRight: "3rem",
  paddingTop: "2rem",
  paddingBottom: "3rem",
  position: "relative",
  [theme.breakpoints.down("lg")]: {
    //minHeight: '300px'
  },
}));

const BoldText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px/30px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("lg")]: {
    //minHeight: '300px'
  },
}));

const NormalBoldText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("lg")]: {
    //minHeight: '300px'
  },
}));

const NormalText = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 16px/24px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("lg")]: {
    //minHeight: '300px'
  },
}));

const BoxButton = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  paddingTop: "5.233rem",
  gap: 40,
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const ButtonCancel = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "3rem",
  background: "#E9F0F5 0% 0% no-repeat padding-box",
  borderRadius: "10px",
  font: "normal normal normal 22px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginBottom: "1rem",
    marginRight: "1rem",
  },
}));

const ButtonSave = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "3rem",
  font: "normal normal normal 22px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#FFFFFF",
  background:
    "transparent linear-gradient(90deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box",
  border: "2px solid #3D9AEE",
  borderRadius: "10px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const BoxCondition = ({ setActiveStep, loading }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  return (
    <>
      <OuterBox>
        <HeaderBox>
          <HeaderText>เงื่อนไขการสมัครเป็นสมาชิกลานจอดรถ</HeaderText>
        </HeaderBox>

        <ContentBox>
        <BoldText>เงื่อนไขคุณสมบัติของผู้ที่ต้องการสมัคร</BoldText>
        <NormalText>ผู้สมัครสมาชิกต้องเป็นเจ้าหน้าที่ ทอท. กับผู้ที่ปฏิบัติงานที่มีสัญญากับ ทอท.</NormalText>
          <BoldText>กรุณาเตรียมหลักฐานประกอบการสมัคร ดังนี้</BoldText>
          <NormalBoldText>
            หลักฐานประกอบที่ใช้ในการสมัครสมาชิกลานจอดรถ
          </NormalBoldText>
          <NormalText>1. สำเนาบัตรประชาชน</NormalText>
          <NormalText>2. สำเนาบัตรประจำตัวพนักงาน</NormalText>
          <NormalText>
            3. สำเนาบัตรรักษาความปลอดภัย ที่ ทดม.ออกให้
            กรณีไม่มีบัตรรักษาความปลอดภัย ซึ่งออกโดย ฝรภ.ทดม หรือบัตร
            รปภ.ประเภทชั่วคราว ต้องมีหนังสือรับรองการเป็นพนังงาน
            ของบริษัทสายการบินหรือผู้ประกอบการนั้นๆ
          </NormalText>
          <NormalText>
            4. สำเนาใบคู่มือจดทะเบียนรถ โดยผู้เป็นเจ้าของรถ
          </NormalText>
          <NormalText>
            - สำเนาใบคู่มือจดทะเบียนรถ กรณีรถเป็นชื่อตนเอง
          </NormalText>
          <NormalText>
            -
            สำเนาใบคู่มือจดทะเบียนรถและสำเนาทะเบียนบ้านของผู้ที่เป็นสมาชิกบัตรจอดรถยนต์
            กรณีรถเป็นชื่อบิดาหรือมารดา
          </NormalText>
          <NormalText>
            - สำเนาใบคู่มือจดทะเบียนรถและสำเนาทะเบียนสมรส
            กรณีรถเป็นชื่อของคู่สมรส
          </NormalText>
          <NormalText>
            - สำเนาใบคู่มือจดทะเบียนรถ
            สำเนาทะเบียนบ้านของผู้ที่เป็นสมาชิกบัตรจอดรถยนต์
            และสำเนาทะเบียนบ้านของบุตร กรณีรถเป็นชื่อของบุตรโดยชอบด้วยกฎหมาย
          </NormalText>
          <NormalText>
            5. เอกสารอื่นๆตามที่เจ้าหน้าที่ขอเพิ่มเติม
            หลักฐานประกอบกรณีเปลี่ยนรถใช้ถาวร
          </NormalText>

          <NormalBoldText>หลักฐานประกอบกรณีเปลี่ยนรถใช้ถาวร</NormalBoldText>
          <NormalText>1. สำเนาบัตรประชาชน</NormalText>
          <NormalText>2. สำเนาบัตรประจำตัวพนักงาน</NormalText>
          <NormalText>3. สำเนาใบคู่มือจดทะเบียนรถ</NormalText>
          <NormalText>
            4. เอกสารหลักฐานอื่นๆ ตามที่เจ้าหน้าที่ขอเพิ่มเติม
          </NormalText>
          <NormalBoldText>หลักฐานประกอบกรณีเปลี่ยนรถใช้ชั่วคราว</NormalBoldText>
          <NormalText>1. สำเนาใบแจ้งซ่อม</NormalText>
          <NormalText>
            2. สำเนาทะเบียนรถยนต์คันใหม่ที่ต้องการใช้ทดแทน
          </NormalText>
          <NormalText>3. สำเนาบัตรประชาชน</NormalText>
          <NormalText>4. สำเนาบัตรประจำตัวพนักงาน</NormalText>
          <NormalText>
            5. เอกสารหลักฐานอื่นๆ ตามที่เจ้าหน้าที่ขอเพิ่มเติม
          </NormalText>
        </ContentBox>
      </OuterBox>
      <BoxButton>
        <ButtonCancel
          onClick={() => {
            if (user) {
              navigate("/pages");
            } else {
              navigate("/home");
            }
          }}
        >
          ยกเลิก
        </ButtonCancel>
        <ButtonSave onClick={handleNext} disabled={loading}>
          ต่อไป
        </ButtonSave>
      </BoxButton>
    </>
  );
};

export default BoxCondition;
