import { Box, Button, styled, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { formattedPriceWithTextCurrency } from "../../utils/format";
import { useTranslation } from "react-i18next";
import { Colors } from "../../constants/colors";
import { DATE_FORMATS } from "../../constants";

const BoxOuter = styled(Box)(({ theme }) => ({
  boxShadow: "0px 3px 6px #00000029",
  border: "1px solid #707070",
  borderRadius: "13px",
  width: "100%",
  display: "flex",
  paddingInline: "2rem",
  flexDirection: "column",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    paddingInline: "1rem",
  },
}));

const BoxHeader = styled(Box)(({ theme }) => ({
  paddingTop: "2rem",
  display: "flex",
  borderBottom: "1px solid #2F2E2F",
  [theme.breakpoints.down("lg")]: {},
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  font: "normal normal 500 30px/60px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal 600 24px/60px Noto Sans Thai",
  },
}));

const BoxTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  paddingTop: "1rem",
  [theme.breakpoints.down("lg")]: {},
}));

const TitleText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 20px/60px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  paddingLeft: "2rem",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal 600 18px/60px Noto Sans Thai",
  },
}));

const BoxDetail = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  paddingTop: "2rem",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const NormalText = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 18px/20px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal normal 16px/20px Noto Sans Thai",
  },
}));

const GreyText = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 18px/20px Noto Sans Thai",
  textAlign: "right",
  letterSpacing: "0px",
  color: "#898A8F",
  [theme.breakpoints.down("lg")]: {
    fontSize: 16,
  },
}));

const BoxSubject = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #B3BBC2",
  paddingBottom: "20px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    paddingRight: "0",
  },
}));

const RedText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 22px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#FC3838",
  [theme.breakpoints.down("lg")]: {
    marginBottom: "1rem",
  },
}));

const BoxSummary = styled(Box)(({ theme }) => ({
  paddingTop: "1rem",
  marginLeft: "2rem",
  marginRight: "2rem",
  marginBottom: "0.5rem",
  display: "flex",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "2rem",
    marginRight: "2rem",
    flexDirection: "column",
  },
}));

const BoxTotalLeft = styled(Box)(({ theme }) => ({
  width: "50%",
  background: "#E9F0F5 0% 0% no-repeat padding-box",
  height: "7rem",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
  paddingBottom: "1rem",
  paddingRight: "1rem",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "0",
    paddingRight: "0",
  },
}));

const TotalLeftText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 22px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  paddingLeft: "2rem",
  [theme.breakpoints.down("lg")]: {},
}));

const BoxTotalRight = styled(Box)(({ theme }) => ({
  width: "50%",
  background: "#245AB1 0% 0% no-repeat padding-box",
  height: "7rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const TotalRightText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 50px/86px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#FFFFFF",
  paddingLeft: "2rem",
  [theme.breakpoints.down("lg")]: {},
}));

const PayButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "4rem",
  backgroundColor: "#EF8A49",
  color: "#FFFFFF",
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: "#EF8A49",
    color: "#FFFFFF",
    opacity: "0.9",
  },
  "&:disabled": {
    backgroundColor: Colors.disabledButton,
    cursor: "not-allowed",
  },
  [theme.breakpoints.down("lg")]: {},
}));

const ButtonText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 28px/43px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#FFFFFF",
  [theme.breakpoints.down("lg")]: {},
}));

const BoxFeeParking = ({
  displayInformationData,
  onClickPaymentButtonHandler,
  disabledBillBtn,
  isCloseOnlinePayment,
}) => {
  const { t } = useTranslation();

  const disabledPaymentButton =
    displayInformationData?.fee === 0 ||
    disabledBillBtn ||
    !displayInformationData?.fee;

  return (
    <>
      <BoxOuter>
        <BoxHeader>
          <HeaderText>{t("Check a Parking Fee")}</HeaderText>
        </BoxHeader>
        <BoxTitle>
          <img
            src="/images/calparking/ico_linegraycar.svg"
            alt=""
            style={{ height: "50px", width: "50px" }}
          />
          <TitleText>{t("Parking Fee Detail")}</TitleText>
        </BoxTitle>
        <BoxDetail>
          <div className="w-full px-4 flex justify-center flex-col gap-4">
            <BoxSubject>
              <NormalText>{t("Airport")}</NormalText>
              <GreyText>{displayInformationData?.airport}</GreyText>
            </BoxSubject>

            <BoxSubject>
              <NormalText>{t("License Plate")}</NormalText>
              <GreyText>{displayInformationData?.licensePlate}</GreyText>
            </BoxSubject>
            <BoxSubject>
              <NormalText>{t("Entry Date Time")}</NormalText>
              <GreyText>
                {(displayInformationData?.start &&
                  dayjs(displayInformationData?.start).format(
                    DATE_FORMATS.FORMAT_03
                  )) ||
                  "-"}
              </GreyText>
            </BoxSubject>
            <BoxSubject>
              <NormalText>{t("Exit Date Time")}</NormalText>
              <GreyText>
                {" "}
                {(displayInformationData?.end &&
                  dayjs(displayInformationData?.end).format(
                    DATE_FORMATS.FORMAT_03
                  )) ||
                  "-"}
              </GreyText>
            </BoxSubject>
            <BoxSubject sx={{ borderBottom: "none" }}>
              <NormalText>{t("Total Parking Time")}</NormalText>
              <RedText>
                {`${
                  displayInformationData?.durationday
                    ? displayInformationData?.durationday + ` ${t("Day")}`
                    : ""
                }
                ${
                  displayInformationData?.durationhour
                    ? displayInformationData?.durationhour + ` ${t("Hour")}`
                    : ""
                } ${
                  displayInformationData?.durationminute
                    ? displayInformationData?.durationminute + ` ${t("Minute")}`
                    : ""
                }`}
              </RedText>
            </BoxSubject>
          </div>
        </BoxDetail>
        <BoxSummary>
          <BoxTotalLeft>
            <TotalLeftText>{t("Total Parking Fee")}</TotalLeftText>
          </BoxTotalLeft>
          <BoxTotalRight>
            <TotalRightText>
              {displayInformationData?.fee
                ? formattedPriceWithTextCurrency(
                    displayInformationData?.fee,
                    "th"
                  )
                : "0"}
            </TotalRightText>
          </BoxTotalRight>
        </BoxSummary>
      </BoxOuter>
      {isCloseOnlinePayment && (
        <p className="py-4 text-center text-red-500 font-semibold text-lg">
          {t("noti close parkingfee detail")}
        </p>
      )}
      <PayButton
        onClick={onClickPaymentButtonHandler}
        disabled={disabledPaymentButton}
      >
        <ButtonText sx={{ font: "normal normal 600 20px/43px Noto Sans Thai" }}>
          {t("Pay a Parking Fee")}{" "}
          {displayInformationData?.fee
            ? formattedPriceWithTextCurrency(displayInformationData?.fee, "th")
            : ""}
        </ButtonText>
      </PayButton>
    </>
  );
};

export default BoxFeeParking;
