import { useParams } from "react-router-dom";
import { AIRPORT } from "../constants";

const useAirportParams = () => {
  const { airport } = useParams();

  const pathnameWithAirport = (pathname, pmlsid) => {
    if (pmlsid) {
      const _airportCode =
        Number(pmlsid) === AIRPORT.ID.SUVARNABHUMI
          ? AIRPORT.CODE.BKK
          : AIRPORT.CODE.DMK;
      return `/${_airportCode}${pathname}`;
    } 
    if (airport) {
      return `/${airport}${pathname}`;
    } else {
      return pathname;
    }
  };

  return {
    airport,
    pathnameWithAirport,
  };
};

export default useAirportParams;
