/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import {
  Box,
  Grid,
  styled,
  Typography as TypographyMui,
  Button,
} from "@mui/material";
import NewSelectAOT from "../Controls/NewSelectAOT";
import DatePickerAOT from "../Controls/DatePickerAOT";
import DropdownAOT from "../Controls/DropdownAOT";
import GenderRadioAOT from "../Controls/GenderRadioAOT";
import InputTextFieldAOT from "../Controls/InputTextFieldAOT";
import MultiLineAOT from "../Controls/MultiLineAOT";
import ReceiptRadioAOT from "../Controls/ReceiptRadioAOT";
import registerService from "../../services/registerService";
import AutocompleteAOT from "../Controls/AutoCompleteAOT";
import InputTelAutoCompleteAOT from "../Controls/InputTelAutoCompleteAOT";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useCallback } from "react";
import { formattedPrice } from "../../utils/format";
import TablePackageDetail from "../Base/TablePackageDetail";
import { useTranslation } from "react-i18next";
import { Colors } from "../../constants/colors";
import { isThaiNationality } from "../../utils";
import {
  generateNationalityOptions,
  generateOfficeTypeOptions,
  generateProvinceOptions,
  generateTitleOptions,
  generateVehicleTypeOptions,
} from "../../utils/optionsUtils";

const Typography = styled(TypographyMui)(({ theme }) => ({
  fontSize: 14,
}));

const BoxOuter = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "1rem",
  },
}));

const BoxHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  marginTop: "2rem",
  // marginBottom: "0.2rem",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "1rem",
  },
}));

const AirportText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 20px/30px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "1rem",
  },
}));

const ImgPlane = styled((props) => <Box component="img" {...props} />)(
  ({ theme }) => ({
    width: "3rem",
    height: "1.8rem",
    marginRight: "1rem",
    [theme.breakpoints.down("lg")]: {
      //display: 'none'
    },
  })
);

const ImgUser = styled((props) => <Box component="img" {...props} />)(
  ({ theme }) => ({
    width: "2rem",
    height: "2rem",
    marginRight: "1.5rem",
    [theme.breakpoints.down("lg")]: {
      //display: 'none'
    },
  })
);

const GridContainer = styled((props) => (
  <Grid container spacing={2} {...props} />
))(({ theme }) => ({
  marginTop: "1rem",
  [theme.breakpoints.down("lg")]: {
    //display: 'none'
  },
}));

const GridItem = styled((props) => <Grid item lg={6} xs={12} {...props} />)(
  ({ theme }) => ({
    paddingTop: "1rem",
    paddingBottom: "1rem",
    [theme.breakpoints.down("lg")]: {
      //display: 'none'
    },
  })
);

const GridItemFull = styled((props) => <Grid item xs={12} {...props} />)(
  ({ theme }) => ({
    paddingTop: "1rem",
    paddingBottom: "1rem",
    [theme.breakpoints.down("lg")]: {
      //display: 'none'
    },
  })
);

const BoxGender = styled(Box)(({ theme }) => ({
  marginLeft: "1rem",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "0rem",
  },
}));

const BoxButton = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  paddingTop: "2rem",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const BoxMessErr = styled(Box)(() => ({
  position: "relative",
  color: "red",
  marginRight: "auto",
  paddingLeft: "0.5rem",
}));

export const ButtonSave = styled(Button)(({ theme }) => ({
  width: "50%",
  height: "4rem",
  font: "normal normal normal 22px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#FFFFFF",
  background:
    "transparent linear-gradient(90deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box",
  borderRadius: "10px",
  "&.Mui-disabled": {
    background: Colors.disabledButton,
    border: "none",
    cursor: "not-allowed",
    pointerEvents: "auto",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const RegisterParkingStep1 = ({ formik, currentAirportPmlsId }) => {
  const { pmlsId } = useSelector((state) => state.airport);
  const { nationality, title, officeType, province, vehicletype } = useSelector(
    (state) => state.masterData
  );
  const { t } = useTranslation();
  const [valueProvince, setValueProvince] = useState("");
  const [dataDistrict, setDataDistrict] = useState([]);
  const [valueDistrict, setValueDistrict] = useState("");
  const [dataSubDistrict, setDataSubDistrict] = useState([]);
  const [valueSubDistrict, setValueSubDistrict] = useState("");
  const [valuesStartDate, setValuesStartDate] = useState("");
  // const [valuesExpirationDate, setValuesExpirationDate] = useState("");
  const [packageGroup, setPackageGroup] = useState([]);
  const [valuePackageGroup, setValuePackageGroup] = useState("");
  const [parkingGroup, setParkingGroup] = useState([]);
  const [valueParkingGroup, setValueParkingGroup] = useState("");
  const [memberOrdderList, setMemberOrdderList] = useState([]);
  const [isRequiredEmail, setIsRequiredEmail] = useState(false);
  const [ErrForm, setErrForm] = useState({});

  const nationalityOptions = useMemo(() => {
    return generateNationalityOptions(nationality);
  }, [nationality]);

  const titleOptions = useMemo(() => {
    return generateTitleOptions(title);
  }, [title]);

  const officeTypeOptions = useMemo(() => {
    return generateOfficeTypeOptions(officeType);
  }, [officeType]);

  const provinceOptions = useMemo(() => {
    return generateProvinceOptions(province);
  }, [province]);

  const vehicleTypeOptions = useMemo(() => {
    return generateVehicleTypeOptions(vehicletype);
  }, [vehicletype]);

  //? filter แค่สมาชิก
  const getTotal = useCallback(() => {
    if (memberOrdderList.length > 0) {
      const total = memberOrdderList.reduce((sum, item) => {
        return sum + Number(item.totalprice ? item.totalprice * item.qty : 0);
      }, 0);
      return formattedPrice(total);
    } else {
      return 0;
    }
  }, [memberOrdderList]);

  const handleNext = () => {
    formik.setFieldValue("memberorder.items", memberOrdderList);
  };

  const handleBirthdate = (e) => {
    let x = dayjs(e).format("YYYY-MM-DD");
    formik.setFieldValue("birthdate", x);
  };
  const handleGender = (e) => {
    formik.setFieldValue("gender", e.target.value);
  };

  const handleFirstname = (e) => {
    formik.setFieldValue("firstname", e.target.value);
  };

  const handleLastname = (e) => {
    formik.setFieldValue("lastname", e.target.value);
  };
  const handleAffiliation = (e) => {
    formik.setFieldValue("affiliation", e.target.value);
  };
  const handleTelOffice = (e) => {
    formik.setFieldValue("telOffice", e.target.value);
  };
  const handleTel = (e) => {
    formik.setFieldValue("mobile", e);
  };
  const handleEmail = (e) => {
    formik.setFieldValue("email", e.target.value);
  };

  const handleOfficeType = (e, newValue) => {
    formik.setFieldValue("officetype", newValue);
  };

  const handleProvince = (e, newValue) => {
    if (newValue) {
      setValueProvince(newValue);
      setValueDistrict("");
      setValueSubDistrict("");
      formik.setFieldValue("district", "");
      formik.setFieldValue("subdistrict", "");
      formik.setFieldValue("postcode", "");
      formik.setFieldValue("province", newValue.id);
    } else {
      setValueProvince("");
      setValueDistrict("");
      setValueSubDistrict("");
      formik.setFieldValue("district", "");
      formik.setFieldValue("subdistrict", "");
      formik.setFieldValue("province", "");
      formik.setFieldValue("postcode", "");
    }
  };
  const handleDistrict = (e, newValue) => {
    if (newValue) {
      setValueDistrict(newValue);
      formik.setFieldValue("subdistrict", "");
      formik.setFieldValue("district", newValue.id);
      formik.setFieldValue("postcode", "");
    } else {
      setValueDistrict("");
      setValueSubDistrict("");
      formik.setFieldValue("subdistrict", "");
      formik.setFieldValue("district", "");
      formik.setFieldValue("postcode", "");
    }
  };
  const handleSubDistrict = (e, newValue) => {
    if (newValue) {
      setValueSubDistrict(newValue);
      formik.setFieldValue("subdistrict", newValue.id);
      formik.setFieldValue("postcode", newValue?.postcode);
    } else {
      setValueSubDistrict("");
      formik.setFieldValue("subdistrict", "");
      formik.setFieldValue("postcode", "");
    }
  };

  const handleBuilding = (e) => {
    formik.setFieldValue("building", e.target.value);
  };
  const handleHouseno = (e) => {
    formik.setFieldValue("houseno", e.target.value);
  };
  const handleParkingGroup = (e) => {
    setValuePackageGroup("");
    setMemberOrdderList([]);
    formik.setFieldValue("parkingGroup", e.id);
  };
  const handlePackageGroup = (e) => {
    formik.setFieldValue("packageGroup", e.id);
  };
  const handleDateStart = (e) => {
    let x = dayjs(e).format("YYYY-MM-DD");
    formik.setFieldValue("dateStart", x);
    formik.setFieldValue("dateExpiration", "");
    // setValuesExpirationDate(null);
  };
  const handleDateExpiration = (e) => {
    let x = dayjs(e).format("YYYY-MM-DD");
    formik.setFieldValue("dateExpiration", x);
  };

  const handleByPath = (e) => {
    formik.setFieldValue("bypath", e.target.value);
  };

  useEffect(() => {
    let result = {};

    if (formik.values.birthdate === "") {
      result = { ...result, birthdate: "Birthdate is required" };
    }
    if (formik.values.title === "") {
      result = { ...result, title: "Title is required" };
    }
    if (formik.values.province === "") {
      result = { ...result, province: "Province is required" };
    }
    if (formik.values.district === "") {
      result = { ...result, district: "District is required" };
    }
    if (formik.values.subdistrict === "") {
      result = { ...result, subdistrict: "Subdistrict is required" };
    }
    if (formik.values.vehicletypeid === "") {
      result = { ...result, vehicletypeid: "Vehicletype is required" };
    }
    if (formik.values.parkingGroup === "") {
      result = { ...result, parkingGroup: "ParkingGroup is required" };
    }
    if (formik.values.packageGroup === "") {
      result = { ...result, packageGroup: "PackageGroup is required" };
    }
    if (formik.values.dateStart === "") {
      result = { ...result, dateStart: "DateStart is required" };
    }
    if (formik.values.dateExpiration === "") {
      result = { ...result, dateExpiration: "DateExpiration is required" };
    }
    if (formik.values.dateStart !== "" && formik.values.dateExpiration !== "") {
      let a = dayjs(formik.values.dateStart, "YYYY-MM-DD");
      let b = dayjs(formik.values.dateExpiration, "YYYY-MM-DD");
      if (a.diff(b) > 0) {
        result = { ...result, dateExpiration: "DateExpiration is invalid" };
      }
    }
    setErrForm(result);
  }, [
    formik.values.birthdate,
    formik.values.dateExpiration,
    formik.values.dateStart,
    formik.values.district,
    formik.values.membertype,
    formik.values.packageGroup,
    formik.values.parkingGroup,
    formik.values.province,
    formik.values.subdistrict,
    formik.values.title,
    formik.values.vehicletype,
  ]);

  useEffect(() => {
    let mounted = true;
    const GetDistrict = async (provinceid) => {
      try {
        const result = await registerService.getDistrict(provinceid);
        const data = await result?.data?.msDistrict;
        let district = [];
        for (let i = 0; i < data.length; i++) {
          district.push({ id: data[i].districtid, display: data[i].nameth });
        }
        setDataDistrict(district);
      } catch (error) {
        console.log(error);
      }
    };
    if (mounted) {
      GetDistrict(valueProvince ? valueProvince.id : "");
      setValueDistrict({ id: 0, display: "" });
      setValueSubDistrict("");
    }
    return () => {
      mounted = false;
    };
  }, [valueProvince]);

  useEffect(() => {
    let mounted = true;
    const GetSubDistrict = async (provinceid, districtid) => {
      try {
        const result = await registerService.getSubDistrict(
          provinceid,
          districtid
        );
        const data = await result?.data?.MsSubDistrict;
        let subdistrict = [];
        for (let i = 0; i < data.length; i++) {
          subdistrict.push({
            id: data[i].subdistrictid,
            display: data[i].nameth,
            postcode: data[i].postcode,
          });
        }
        setDataSubDistrict(subdistrict);
      } catch (error) {
        console.log(error);
      }
    };
    if (mounted) {
      GetSubDistrict(
        valueProvince ? valueProvince.id : "",
        valueDistrict ? valueDistrict.id : ""
      );
      setValueSubDistrict("");
    }
    return () => {
      mounted = false;
    };
  }, [valueDistrict, valueProvince]);

  //#1
  const getParkingGroup = useCallback(async () => {
    try {
      const result = await registerService.getParkingGroup(pmlsId);
      const data = result?.data?.allTbParkingGroup ?? [];
      if (data.length > 0) {
        const parkingGroup = data
          .filter((d) => d.ismobile === 1)
          .map((d) => ({
            id: d.parkinggroupid,
            display: d.name,
          }));
        setParkingGroup(parkingGroup);
      }
    } catch (error) {
      console.log(error);
    }
  }, [pmlsId]);

  useEffect(() => {
    getParkingGroup();
  }, []);

  // #2
  const getPackageGroup = useCallback(async () => {
    try {
      const response = await registerService.getParkingPackageGroup(pmlsId);
      const data = response?.data?.allParkingPackage ?? [];
      const filteredData = data.filter(
        (d) =>
          d.parkingid === valueParkingGroup &&
          d.vehicletypeid === formik.values.vehicletypeid
      );
      const packagegroup = filteredData.map((d) => ({
        id: d.packagegroupid,
        display: d.packagegroupname,
      }));
      setPackageGroup(packagegroup);
    } catch (error) {
      console.log(error);
    }
  }, [pmlsId, valueParkingGroup, formik.values.vehicletypeid]);

  useEffect(() => {
    getPackageGroup();
  }, [getPackageGroup]);

  useEffect(() => {
    let mounted = true;
    const GetPackageGroup = async (packagegroupid) => {
      try {
        const pmlsid = pmlsId;
        const result = await registerService.getPackagegroup({
          pmlsid,
          packagegroupid,
        });
        if (
          result &&
          result?.data?.PackageGroup &&
          result?.data?.PackageGroup.items
        ) {
          if (
            formik.values.dateStart &&
            formik.values.dateExpiration &&
            result.data.PackageGroup.items.length > 0
          ) {
            let m1 = dayjs(formik.values.dateStart, "YYYY-MM-DD");
            let m2 = dayjs(formik.values.dateExpiration, "YYYY-MM-DD");
            if (m1.diff(m2) < 0) {
              // เช็ค m1 น้อยกว่า m2
              let d = Math.ceil(m2.diff(m1, "months", true)); // .cil การปัดเศษขึ้น
              let x = result.data.PackageGroup.items.map((obj) => {
                if (obj.chargetype === "002") return { ...obj, qty: d };
                return { ...obj, qty: 1 };
              });
              setMemberOrdderList(x);
            } else {
              let x = result.data.PackageGroup.items.map((obj) => ({
                ...obj,
                qty: 1,
              }));
              setMemberOrdderList(x);
            }
          } else {
            let x = result.data.PackageGroup.items.map((obj) => ({
              ...obj,
              qty: 1,
            }));
            setMemberOrdderList(x);
          }
        } else {
          setMemberOrdderList([]);
        }
      } catch (error) {
        console.log(error);
        setMemberOrdderList([]);
      }
    };
    if (mounted && valuePackageGroup !== "") {
      GetPackageGroup(valuePackageGroup);
    }
    return () => {
      mounted = false;
    };
  }, [
    formik.values.dateExpiration,
    formik.values.dateStart,
    pmlsId,
    valuePackageGroup,
  ]);

  // set field value for tax information
  useEffect(() => {
    const switchTagVal = formik.values.switchtaxtype;
    if (switchTagVal === 2) {
      const {
        title: titleId,
        Idcard,
        firstname,
        lastname,
        houseno,
        building,
        road,
        subdistrict,
        district,
        province: provinceId,
        postcode,
      } = formik.values;
      const titleText = titleOptions.find((t) => t.id === titleId)?.display;
      const subDistrictText =
        dataSubDistrict.find((item) => item.id === subdistrict)?.display || "";
      const districtText =
        dataDistrict.find((item) => item.id === district)?.display || "";
      const provinceText =
        province.find((item) => item.id === provinceId)?.display || "";

      const taxName = `${titleText}${firstname} ${lastname}`;
      const addr1 = `${houseno} ${building}`;
      const addr2 = `${road} ${subDistrictText}`;
      const addr3 = `${districtText} ${provinceText}`;
      const addr4 = `${postcode}`;
      formik.setFieldValue("taxname", taxName);
      formik.setFieldValue("receiptaddr1", addr1);
      formik.setFieldValue("receiptaddr2", addr2);
      formik.setFieldValue("receiptaddr3", addr3);
      formik.setFieldValue("receiptaddr4", addr4);
      formik.setFieldValue("taxid", Idcard);
    } else {
      formik.setFieldValue("taxname", formik.values.affiliation);
    }
  }, [formik.values.switchtaxtype]);

  useEffect(() => {
    if (formik.values.issentmail === 1) {
      setIsRequiredEmail(true);
    } else {
      setIsRequiredEmail(false);
    }
  }, [formik.values.issentmail]);

  const isSelectedTHNationality = useMemo(() => {
    return isThaiNationality(formik.values.nationality?.id);
  }, [formik.values]);

  return (
    <>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <BoxOuter>
          <BoxHeader>
            <ImgPlane src="/images/auth/ico_plane.svg" />
            {currentAirportPmlsId === "1" ? (
              <AirportText>{t("Suvarnabhumi airport")}</AirportText>
            ) : (
              <AirportText>
                {t("Don Mueang International Airport")}{" "}
              </AirportText>
            )}
          </BoxHeader>
          <BoxHeader>
            <ImgUser src="/images/auth/ico_user_1.svg" />
            <AirportText>{t("Personal Information")}</AirportText>
          </BoxHeader>
          <GridContainer>
            <GridItem>
              {isSelectedTHNationality ? (
                <InputTextFieldAOT
                  label={t("ID No")}
                  name={"Idcard"}
                  handleChange={(e) => {
                    formik.setFieldValue("Idcard", e.target.value);
                  }}
                  val={formik.values.Idcard}
                  required={true}
                />
              ) : (
                <InputTextFieldAOT
                  label={t("Passport No")}
                  name={"passport"}
                  handleChange={(e) => {
                    formik.setFieldValue("passport", e.target.value);
                  }}
                  val={formik.values.passport}
                  required={true}
                />
              )}

              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {isSelectedTHNationality
                    ? formik.errors.Idcard && formik.touched
                      ? formik.errors.Idcard
                      : null
                    : formik.errors.passport && formik.touched
                    ? formik.errors.passport
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <AutocompleteAOT
                label={t("Nationality")}
                name="nationality"
                required
                options={nationalityOptions}
                handleChange={(e, newValue) => {
                  formik.setFieldValue("nationality", newValue);
                }}
                val={formik.values.nationality}
              />

              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {formik?.errors?.nationality && formik.touched
                    ? formik?.errors?.nationality
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <DatePickerAOT
                disabled
                label={t("Birthdate")}
                name="birthdate"
                val={formik.values.birthdate}
                handleChange={(e) => {
                  handleBirthdate(e);
                }}
                required
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {ErrForm.birthdate && formik.touched
                    ? ErrForm.birthdate
                    : null}
                </Typography>
              </BoxMessErr>
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {formik.errors.birthdate && formik.touched
                    ? formik.errors.birthdate
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <BoxGender>
                <GenderRadioAOT
                  name="gender"
                  handleChange={handleGender}
                  value={formik.values.gender}
                  disabled
                  required
                />
              </BoxGender>
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {formik.errors.gender && formik.touched
                    ? formik.errors.gender
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <NewSelectAOT
                label={t("Title")}
                name="title"
                options={titleOptions}
                value={formik.values.title}
                onChange={(e) => {
                  formik.setFieldValue("title", e.target.value);
                }}
                fullWidth
                disabled
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {ErrForm.title && formik.touched ? ErrForm.title : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("Name")}
                name="firstname"
                handleChange={handleFirstname}
                required={true}
                val={formik.values.firstname}
                disabled={true}
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {formik.errors.firstname && formik.touched
                    ? formik.errors.firstname
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("Middle Name")}
                name="middlename"
                val={formik.values.middlename}
                disabled={true}
              />
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("Surname")}
                name="lastname"
                handleChange={handleLastname}
                required
                val={formik.values.lastname}
                disabled={true}
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {formik.errors.lastname && formik.touched
                    ? formik.errors.lastname
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("Affiliation")}
                name="affiliation"
                handleChange={handleAffiliation}
                required
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {formik.errors.affiliation && formik.touched
                    ? formik.errors.affiliation
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <AutocompleteAOT
                label={t("Department")}
                name="officetype"
                options={officeTypeOptions}
                val={formik?.values?.officetype}
                handleChange={handleOfficeType}
                required
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {formik?.errors?.officetype && formik.touched
                    ? formik?.errors?.officetype
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItemFull>
              <InputTextFieldAOT
                label={t("Office Phone No")}
                name="telOffice"
                handleChange={handleTelOffice}
                required
                val={formik?.values?.telOffice}
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {formik.errors.telOffice && formik.touched
                    ? formik.errors.telOffice
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItemFull>
            <GridItemFull>
              <InputTelAutoCompleteAOT
                width="100%"
                name="mobile"
                handleChange={handleTel}
                value={formik.values.mobile}
                disabled
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {formik.errors.tel && formik.touched
                    ? formik.errors.tel
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItemFull>
            <GridItem>
              <InputTextFieldAOT
                label={t("Email")}
                name="email"
                handleChange={handleEmail}
                required={isRequiredEmail}
                val={formik.values.email}
                disabled
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {formik.errors.email && formik.touched
                    ? formik.errors.email
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("House No")}
                name="houseno"
                handleChange={handleHouseno}
                required
                val={formik?.values?.houseno}
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {formik.errors.houseno && formik.touched
                    ? formik.errors.houseno
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("VillageBuilding")}
                name="building"
                handleChange={handleBuilding}
                val={formik?.values?.building}
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {formik.errors.building && formik.touched
                    ? formik.errors.building
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("Road")}
                name="road"
                handleChange={formik.handleChange}
                val={formik?.values?.road}
              />
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("Lane")}
                name="bypath"
                handleChange={handleByPath}
                val={formik?.values?.bypath}
              />
            </GridItem>
            <GridItem>
              <AutocompleteAOT
                label={t("Province")}
                name="province"
                options={provinceOptions}
                val={valueProvince}
                handleChange={handleProvince}
                required
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {ErrForm.province && formik.touched ? ErrForm.province : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <AutocompleteAOT
                label={t("DistrictArea")}
                name="district"
                options={dataDistrict}
                val={valueDistrict}
                handleChange={handleDistrict}
                required
                disabled={!formik?.values?.province}
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {ErrForm.district && formik.touched ? ErrForm.district : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <AutocompleteAOT
                label={t("SubdistrictSubarea")}
                name="subdistrict"
                options={dataSubDistrict}
                val={valueSubDistrict}
                handleChange={handleSubDistrict}
                required
                disabled={!formik?.values?.district}
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {ErrForm.subdistrict && formik.touched
                    ? ErrForm.subdistrict
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            {/* postcode */}
            <GridItem>
              <InputTextFieldAOT
                label={t("Postal Code")}
                name="postcode"
                val={formik.values.postcode}
                required
                disabled
              />
            </GridItem>
          </GridContainer>
          <BoxHeader>
            <ImgUser src="/images/auth/ico_package_1.svg" />
            <AirportText>{t("Member Package Information")}</AirportText>
          </BoxHeader>
          <GridContainer>
            <GridItem>
              <NewSelectAOT
                label={t("Vehicle Type")}
                name="vehicletypeid"
                options={vehicleTypeOptions}
                value={formik.values.vehicletypeid}
                fullWidth
                required
                disabled
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {ErrForm.vehicletype && formik.touched
                    ? ErrForm.vehicletype
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <DropdownAOT
                label={t("Parking Group")}
                name="parkingGroup"
                data={parkingGroup}
                val={valueParkingGroup}
                setVal={setValueParkingGroup}
                handleChange={handleParkingGroup}
                required
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {ErrForm.parkingGroup && formik.touched
                    ? ErrForm.parkingGroup
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItemFull>
              <DropdownAOT
                label={t("Member Package")}
                name="packageGroup"
                data={packageGroup}
                val={valuePackageGroup}
                setVal={setValuePackageGroup}
                handleChange={handlePackageGroup}
                required
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {ErrForm.packageGroup && formik.touched
                    ? ErrForm.packageGroup
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItemFull>
            <GridItem>
              <DatePickerAOT
                label={t("Apply Start Date")}
                name="dateStart"
                val={valuesStartDate}
                handleChange={(e) => {
                  let selectedDate = dayjs(e);
                  let currentMonth = dayjs().startOf("month");

                  if (selectedDate.isAfter(currentMonth)) {
                    let s = selectedDate.startOf("month");
                    let x = selectedDate.endOf("month");
                    setValuesStartDate(s);
                    handleDateStart(s);
                    // setValuesExpirationDate(x);
                    handleDateExpiration(x);
                  }
                }}
                required
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {ErrForm.dateStart && formik.touched
                    ? ErrForm.dateStart
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
            <GridItem>
              <DatePickerAOT
                label={t("Expired Date")}
                name="dateExpiration"
                val={formik.values.dateExpiration}
                disabled
                handleChange={() => {}}
                required
                // handleChange={(e) => {
                //   let x = dayjs(e).endOf("month");
                //   setValuesExpirationDate(x);
                //   handleDateExpiration(x);
                // }}
              />
              <BoxMessErr>
                <Typography sx={{ position: "absolute" }}>
                  {ErrForm.dateExpiration && formik.touched
                    ? ErrForm.dateExpiration
                    : null}
                </Typography>
              </BoxMessErr>
            </GridItem>
          </GridContainer>

          <TablePackageDetail data={memberOrdderList} totalPrice={getTotal()} />
          <BoxHeader sx={{ marginTop: "2rem" }}>
            <ImgUser src="/images/auth/ico_vat_1.svg" />
            <AirportText>{t("Tax Invoice Information")}</AirportText>
          </BoxHeader>
          <GridContainer>
            <GridItemFull>
              <ReceiptRadioAOT
                name="switchtaxtype"
                onChange={formik.handleChange}
              />
            </GridItemFull>
            <GridItem>
              <InputTextFieldAOT
                label={t("NameSurnameCompany")}
                name="taxname"
                val={formik.values.taxname}
                handleChange={formik.handleChange}
              />
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("Tax ID")}
                name="taxid"
                val={formik.values.taxid || ""}
                handleChange={formik.handleChange}
              />
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("Branch")}
                name="taxbranchno"
                handleChange={formik.handleChange}
              />
            </GridItem>
            <GridItem />
            <GridItem>
              <MultiLineAOT
                label={t("Address One")}
                name="receiptaddr1"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                val={formik.values.receiptaddr1}
              />
            </GridItem>
            <GridItem>
              <MultiLineAOT
                label={t("Address Two")}
                name="receiptaddr2"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                val={formik.values.receiptaddr2}
              />
            </GridItem>
            <GridItem>
              <MultiLineAOT
                label={t("Address Three")}
                name="receiptaddr3"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                val={formik.values.receiptaddr3}
              />
            </GridItem>
            <GridItem>
              <MultiLineAOT
                label={t("Address Four")}
                name="receiptaddr4"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                val={formik.values.receiptaddr4}
              />
            </GridItem>
          </GridContainer>
        </BoxOuter>
        <BoxButton>
          {/* <ButtonCancel onClick={handleBack}>ยกเลิก</ButtonCancel> */}
          <ButtonSave
            onClick={handleNext}
            type="submit"
            disabled={!formik.isValid || JSON.stringify(ErrForm) !== "{}"}
          >
            {t("SaveNext")}
          </ButtonSave>
        </BoxButton>
      </form>
    </>
  );
};

export default RegisterParkingStep1;
