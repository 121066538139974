import React, { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputTextFieldAOT from "../../components/Controls/InputTextFieldAOT";
// import BoxAppParking from "../../components/Login/BoxAppParking";
import registerService from "../../services/registerService";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import dayjs from "dayjs";
import DropdownAOT from "../../components/Controls/DropdownAOT";
import DatePickerAOT from "../../components/Controls/DatePickerAOT";
import { useMutation } from "@tanstack/react-query";
import CountdownTimer from "../../components/CountdownTimer";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AutocompleteAOT from "../../components/Controls/AutoCompleteAOT";
import AuthUserLayout from "../../components/Layouts/AuthUserLayout";
import {
  generateNationalityOptions,
  generateTitleOptions,
} from "../../utils/optionsUtils";
import ButtonAction from "../../components/Base/ButtonAction";
import { DATE_FORMATS, REGEX } from "../../constants";
import { toastErr } from "../../utils/index";

const BoxMessErr = styled(Box)(() => ({
  color: "red",
  marginRight: "auto",
  paddingLeft: "0.2rem",
  height: "12px",
  marginTop: 2,
  fontSize: "12px",
}));

const BoxGropOtp = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  columnGap: "10px",
  textAlignLast: "center",
  marginTop: "2rem",
}));

const InputOtp = styled(TextField)(({ theme }) => ({
  width: "60px",
  height: "60px",
  borderRadius: "5px",
  font: "normal normal bold 32px/35px Noto Sans Thai",
  lineHeight: 1,
}));

const TextOtp = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 20px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  // padding: "0rem",
}));

const LoginText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 30px/54px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  padding: "2rem",
  textAlign: "center",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal 600 24px/34px Noto Sans Thai",
  },
}));

const RefCodeText = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 20px/25px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  marginRight: "0.8rem",
}));

const RefCodeRightText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 20px/25px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  background: "#eaebf1",
  borderRadius: "10px",
  padding: "0.3rem",
}));

const HinText = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 16px/25px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#3D9AEE",
  padding: "0rem 0rem 0rem 0.2rem",
  cursor: "pointer",
  alignSelf: "flex-start",
}));

const validationSchema = Yup.object({
  mobile: Yup.string()
    .matches(REGEX.PHONE, "Phone number is not valid")
    .required("mobile is required"),
  password: Yup.string()
    .matches(
      REGEX.PASSWORD,
      "Password must contain at least one letter and one number"
    )
    .required("Password is required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password confirmation is required"),
  email: Yup.string().email("Not a proper email").required("email is required"),
  titleid: Yup.number().required(),
  titlename: Yup.string().required(),
  firstname: Yup.string()
    .matches(
      REGEX.NAME,
      "First name can only contain alphabetical characters in English or Thai"
    )
    .required(),
  lastname: Yup.string()
    .matches(
      REGEX.NAME,
      "Last name can only contain alphabetical characters in English or Thai"
    )
    .required(),
  birthdate: Yup.string().required(),
  gender: Yup.string().required(),
  nationality: Yup.object().shape({}).required("Nationality is required"),
});

const RegisterUser = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { nationality, title } = useSelector((state) => state.masterData);
  const pRef1 = useRef(null);
  const pRef2 = useRef(null);
  const pRef3 = useRef(null);
  const pRef4 = useRef(null);
  const pRef5 = useRef(null);
  const pRef6 = useRef(null);
  const pRefButton = useRef(null);

  const [visibilityPassword, setVisibilityPassword] = useState(false);
  const [visibilityConfirmPassword, setVisibilityConfirmPassword] =
    useState(false);

  const [active, setActive] = useState(1);
  const [timerKey, setTimerKey] = useState(0); // Used to reset the timer
  const [showTimer, setShowTimer] = useState(false);

  const [valueOtp1, setValueOtp1] = useState("");
  const [valueOtp2, setValueOtp2] = useState("");
  const [valueOtp3, setValueOtp3] = useState("");
  const [valueOtp4, setValueOtp4] = useState("");
  const [valueOtp5, setValueOtp5] = useState("");
  const [valueOtp6, setValueOtp6] = useState("");

  const masterNationalityDataOptions = useMemo(() => {
    return generateNationalityOptions(nationality);
  }, [nationality]);

  const masterTitleDataOptions = useMemo(() => {
    return generateTitleOptions(title);
  }, [title]);

  useEffect(() => {
    if (active === 2) {
      window.scrollTo({ top: 0 });
    }
  }, [active]);

  const formik = useFormik({
    initialValues: {
      lang: "th",
      username: "",
      password: "",
      titleid: 0,
      firstname: "",
      middlename: "",
      lastname: "",
      email: "",
      mobile: "",
      createby: 0,
      birthdate: null,
      nationality: "",
      gender: "",
      ismapp: 0,
      passwordConfirmation: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
      token: "",
    },
    isInitialValid: false,
    onSubmit: async (values) => {
      try {
        const payloadRegister = {
          lang: values.lang,
          username: values.email,
          password: values.password,
          titleid: values.titleid,
          firstname: values.firstname,
          middlename: values.middlename,
          lastname: values.lastname,
          email: values.email,
          mobile: values.mobile,
          createby: values.createby,
          birthdate: values.birthdate,
          nationality: values?.nationality?.id,
          gender: values.gender,
          ismapp: 0,
        };
        validateUserMutate.mutateAsync(values.email).then((resValidate) => {
          if (resValidate.ouIsComplete === 1) {
            if (resValidate.data.isValidUserName === 0) {
              registerMemberMutate
                .mutateAsync(payloadRegister)
                .then(async (resRegister) => {
                  if (resRegister.ouIsComplete === 1) {
                    const token = resRegister?.data?.accesstoken;
                    if (token) {
                      formik.setFieldValue("token", token);
                      clearAllInputOtp();
                      setActive((prev) => prev + 1);
                      requestOtpMutation.mutate({
                        mobileno: values.mobile,
                        accesstoken: encodeURIComponent(token),
                      });
                    } else {
                      toast.error("token not found!", {
                        position: "top-center",
                        autoClose: 1000,
                      });
                    }
                  }
                });
            } 
            else {
              toast.error("Username exist!", {
                position: "top-center",
                autoClose: 2000,
              });
            }
          } else {
            toast.error(resValidate.ouMessage, {
              position: "top-center",
              autoClose: 1000,
            });
          }
        });
      } catch (error) {
        toast.error(error, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    },
    validationSchema,
  });

  const validateUserMutate = useMutation({
    mutationFn: (data) => registerService.userValidate(data),
  });

  const registerMemberMutate = useMutation({
    mutationFn: (data) => registerService.registerUser(data),
  });

  const verifyOTPMutate = useMutation({
    mutationFn: (data) => {
      return registerService.verifyOtp(
        data.otpid,
        data.valOtp,
        formik.values.token
      );
    },
    onSuccess: async (res) => {
      if (res.ouIsComplete === 1 && res.data) {
        if (res.data.isverify !== 1) {
          toast.error("OTP Error", {
            position: "top-center",
            autoClose: 1000,
          });
        } else {
          const resActive = await registerService.activateUser(
            formik.values.token
          );
          if (resActive.ouIsComplete === 1) {
            toast.success("Register successfully", {
              position: "top-center",
              autoClose: 2000,
            });
            navigate("/auth/user/login");
          } else {
            toast.error(resActive.ouMessage, {
              position: "top-center",
              autoClose: 1000,
            });
          }
        }
      }
    },
    onError: (res) => {
      toast.error("Something went wrong ," + res?.message, {
        position: "top-center",
        autoClose: 1000,
      });
    },
  });

  const handleChangeMobile = (e) => {
    formik.setFieldValue("mobile", e.target.value);
  };

  const handleChangeEmail = (e) => {
    formik.setFieldValue("email", e.target.value);
  };

  const handleChangePassword = (e) => {
    formik.setFieldValue("password", e.target.value);
  };

  const handleChangePasswordConfirmation = (e) => {
    formik.setFieldValue("passwordConfirmation", e.target.value);
  };

  const handleTogglePassword = () =>
    setVisibilityPassword((visibilityPassword) => !visibilityPassword);

  const requestOtpMutation = useMutation({
    mutationFn: (payload) => registerService.requestOtp(payload),
    onSuccess: (res) => {
      if (res.ouIsComplete === 1) {
        handleTimeout();
        setShowTimer(true);
        setTimerKey((prevKey) => prevKey + 1);
      } else if (res.ouReasonCode === "100163") {
        toast.error(res?.ouMessage + res?.message, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    },
    onError: (res) => {
      toastErr(res);
    },
  });

  const handleTimeout = () => {
    setShowTimer(false);
  };

  const requestOtpData = requestOtpMutation?.data?.data;
  const requestOtpLoading = requestOtpMutation.isLoading;

  const disabledSubmitOtp = () => {
    if (
      valueOtp1 === "" ||
      valueOtp2 === "" ||
      valueOtp3 === "" ||
      valueOtp4 === "" ||
      valueOtp5 === "" ||
      valueOtp6 === "" ||
      !requestOtpData?.otpref
    ) {
      return true;
    }
    return false;
  };

  const clearAllInputOtp = () => {
    setValueOtp1("");
    setValueOtp2("");
    setValueOtp3("");
    setValueOtp4("");
    setValueOtp5("");
    setValueOtp6("");
  };

  return (
    <AuthUserLayout>
      {active === 1 && (
        <FormRegister
          formik={formik}
          handleChangeEmail={handleChangeEmail}
          handleChangeMobile={handleChangeMobile}
          handleChangePassword={handleChangePassword}
          handleChangePasswordConfirmation={handleChangePasswordConfirmation}
          handleTogglePassword={handleTogglePassword}
          masterNationalityDataOptions={masterNationalityDataOptions}
          masterTitleDataOptions={masterTitleDataOptions}
          setVisibilityConfirmPassword={setVisibilityConfirmPassword}
          visibilityConfirmPassword={visibilityConfirmPassword}
          visibilityPassword={visibilityPassword}
        />
      )}
      {active !== 1 && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            height: requestOtpLoading ? 400 : "100%",
            alignItems: "center",
          }}
        >
          {requestOtpLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={formik.handleSubmit}
              autoComplete="off"
              className="text-center flex flex-col gap-4 w-full items-center"
            >
              <TextOtp>
                {t("You will receive the OTP via SMS")}
                {requestOtpData?.expireddt
                  ? dayjs(requestOtpData?.expireddt).format(DATE_FORMATS.FORMAT_03)
                  : "-"}
              </TextOtp>

              <BoxGropOtp>
                <InputOtp
                  type="tel"
                  name="otp1"
                  inputMode="numeric"
                  pattern="\d{1}"
                  value={valueOtp1}
                  inputProps={{ maxLength: 1 }}
                  inputRef={pRef1}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    setValueOtp1(e.target.value);
                    pRef2.current.focus();
                  }}
                />
                <InputOtp
                  type="tel"
                  name="otp2"
                  inputMode="numeric"
                  pattern="\d{1}"
                  inputRef={pRef2}
                  value={valueOtp2}
                  inputProps={{ maxLength: 1 }}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    setValueOtp2(e.target.value);
                    pRef3.current.focus();
                  }}
                />
                <InputOtp
                  type="tel"
                  name="otp3"
                  inputMode="numeric"
                  pattern="\d{1}"
                  inputRef={pRef3}
                  value={valueOtp3}
                  inputProps={{ maxLength: 1 }}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    setValueOtp3(e.target.value);
                    pRef4.current.focus();
                  }}
                />
                <InputOtp
                  type="tel"
                  name="otp4"
                  inputMode="numeric"
                  pattern="\d{1}"
                  inputRef={pRef4}
                  value={valueOtp4}
                  inputProps={{ maxLength: 1 }}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    setValueOtp4(e.target.value);
                    pRef5.current.focus();
                  }}
                />
                <InputOtp
                  type="tel"
                  name="otp5"
                  inputMode="numeric"
                  pattern="\d{1}"
                  inputRef={pRef5}
                  value={valueOtp5}
                  inputProps={{ maxLength: 1 }}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    setValueOtp5(e.target.value);
                    pRef6.current.focus();
                  }}
                />
                <InputOtp
                  type="tel"
                  name="otp6"
                  inputMode="numeric"
                  pattern="\d{1}"
                  inputRef={pRef6}
                  value={valueOtp6}
                  onFocus={(e) => e.target.select()}
                  inputProps={{ maxLength: 1 }}
                  onChange={(e) => {
                    setValueOtp6(e.target.value);
                  }}
                />
              </BoxGropOtp>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "1rem",
                  textAlign: "center",
                }}
              >
                <RefCodeText>{t("Ref Code")}: </RefCodeText>
                <RefCodeRightText>
                  {requestOtpData?.otpref ? requestOtpData?.otpref : "N/A"}
                </RefCodeRightText>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "1rem",
                  textAlign: "center",
                  gap: 3,
                  color: "text-gray-400",
                  fontSize: 18,
                }}
              >
                <span
                  className={`${!showTimer ? "underline cursor-pointer" : ""} `}
                  onClick={() => {
                    if (!showTimer) {
                      if (formik.values.mobile && formik.values.token) {
                        clearAllInputOtp();
                        requestOtpMutation.mutate({
                          mobileno: formik.values.mobile,
                          accesstoken: formik.values.token,
                        });
                      }
                    }
                  }}
                >
                  {t("Resend OTP")}
                </span>{" "}
                {showTimer && (
                  <CountdownTimer
                    key={timerKey}
                    initialSeconds={60}
                    onTimeout={handleTimeout}
                  />
                )}
              </Box>

              <div className="w-full">
                <ButtonAction
                  ref={pRefButton}
                  onClick={() => {
                    const payload = {
                      valOtp:
                        valueOtp1 +
                        valueOtp2 +
                        valueOtp3 +
                        valueOtp4 +
                        valueOtp5 +
                        valueOtp6,
                      otpid: requestOtpData?.otpid,
                    };
                    verifyOTPMutate.mutate(payload);
                  }}
                  disabled={disabledSubmitOtp()}
                >
                  {t("Submit")}
                </ButtonAction>
              </div>
            </form>
          )}
        </Box>
      )}
    </AuthUserLayout>
  );
};

const FormRegister = ({
  formik,
  masterNationalityDataOptions,
  masterTitleDataOptions,
  handleChangeMobile,
  handleChangeEmail,
  handleChangePassword,
  visibilityConfirmPassword,
  visibilityPassword,
  handleTogglePassword,
  handleChangePasswordConfirmation,
  setVisibilityConfirmPassword,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off" className="w-full">
      <LoginText>{t("Create an Account")}</LoginText>
      <div className="space-y-5 w-full">
        <div>
          <InputTextFieldAOT
            size="small"
            required
            label={t("Phone No")}
            name="tel"
            width="100%"
            handleChange={handleChangeMobile}
          />
          <BoxMessErr>
            {formik.errors.mobile && formik.touched
              ? formik.errors.mobile
              : null}
          </BoxMessErr>
        </div>
        <div>
          <DropdownAOT
            size="small"
            label={t("Title")}
            name="titleid"
            data={masterTitleDataOptions}
            val={formik.values.titleid}
            handleChange={(e) => {
              formik.setFieldValue("titleid", e?.id);
              formik.setFieldValue("titlename", e?.display);
            }}
            required
          />
          <BoxMessErr>
            {formik.errors.titleid && formik.touched
              ? formik.errors.titleid
              : null}
          </BoxMessErr>
        </div>
        <div>
          <InputTextFieldAOT
            size="small"
            label={t("Name")}
            name="firstname"
            width="100%"
            handleChange={formik.handleChange}
            val={formik.values.firstname}
            required={true}
          />
          <BoxMessErr>
            {formik.errors.firstname && formik.touched
              ? formik.errors.firstname
              : null}
          </BoxMessErr>
        </div>
        <div>
          <InputTextFieldAOT
            size="small"
            label={t("Middle Name")}
            name="middlename"
            width="100%"
            handleChange={formik.handleChange}
            val={formik.values.middlename}
          />
          <BoxMessErr>
            {formik.errors.middlename && formik.touched
              ? formik.errors.middlename
              : null}
          </BoxMessErr>
        </div>

        <div>
          <InputTextFieldAOT
            size="small"
            label={t("Surname")}
            name="lastname"
            width="100%"
            handleChange={formik.handleChange}
            val={formik.values.lastname}
            required={true}
          />
          <BoxMessErr>
            {formik.errors.lastname && formik.touched
              ? formik.errors.lastname
              : null}
          </BoxMessErr>
        </div>
        <div>
          <DropdownAOT
            size="small"
            label={t("Gender")}
            name="titleid"
            data={[
              { id: "1", display: "ชาย" },
              { id: "0", display: "หญิง" },
            ]}
            val={formik.values.gender}
            handleChange={(e) => {
              formik.setFieldValue("gender", e.id);
            }}
            required
          />
          <BoxMessErr>
            {formik.errors.gender && formik.touched
              ? formik.errors.gender
              : null}
          </BoxMessErr>
        </div>
        <div>
          <DatePickerAOT
            label={t("Birthdate")}
            name="birthdate"
            val={formik.values.birthdate}
            handleChange={(e) => {
              let x = dayjs(e).format("YYYY-MM-DD");
              formik.setFieldValue("birthdate", x);
            }}
            required
            size="small"
          />
          <BoxMessErr>
            {formik.errors.birthdate && formik.touched
              ? formik.errors.birthdate
              : null}
          </BoxMessErr>
        </div>
        <div>
          <AutocompleteAOT
            size="small"
            label={t("Nationality")}
            required
            options={masterNationalityDataOptions}
            name="nationality"
            val={formik.values.nationality}
            handleChange={(e, newValue) => {
              formik.setFieldValue("nationality", newValue);
            }}
          />
          <BoxMessErr>
            {formik?.errors?.nationality && formik.touched
              ? formik?.errors?.nationality
              : null}
          </BoxMessErr>
        </div>

        <div>
          <InputTextFieldAOT
            label={t("Email")}
            name="email"
            size="small"
            width="100%"
            handleChange={handleChangeEmail}
            required={true}
          />
          <BoxMessErr>
            {formik.errors.email && formik.touched ? formik.errors.email : null}
          </BoxMessErr>
        </div>
        <div>
          <InputTextFieldAOT
            size="small"
            required={true}
            label={t("Password")}
            name="password"
            type={visibilityPassword ? "text" : "password"}
            variant="outlined"
            width="100%"
            handleChange={handleChangePassword}
            sxb={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibilityPassword"
                    onClick={handleTogglePassword}
                  >
                    {visibilityPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <BoxMessErr>
            {formik.errors.password && formik.touched
              ? formik.errors.password
              : null}
          </BoxMessErr>
        </div>
        <div>
          <HinText>{t("Password must have at least")}</HinText>
          <InputTextFieldAOT
            size="small"
            required={true}
            label={t("Confirm Password")}
            name="passwordConfirmation"
            type={visibilityConfirmPassword ? "text" : "password"}
            width="100%"
            handleChange={handleChangePasswordConfirmation}
            sxb={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibilityPassword"
                    onClick={() =>
                      setVisibilityConfirmPassword((visible) => !visible)
                    }
                  >
                    {visibilityConfirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <BoxMessErr>
            {formik.errors.passwordConfirmation && formik.touched
              ? formik.errors.passwordConfirmation
              : null}
          </BoxMessErr>
        </div>
        <ButtonAction type="submit" disabled={!formik.isValid}>
          {t("Create an Account")}
        </ButtonAction>
        <p
          className="mt-4 font-semibold cursor-pointer text-center"
          onClick={() => navigate("/auth/user/login")}
        >
          {t("Back")}
        </p>
      </div>
    </form>
  );
};

export default RegisterUser;
