import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import instance from "./config/apiAxios";
const loadPath = `/ms/translate?langcode={{lng}}`;

i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    //ns: ['pmscustomer'],
    // defaultNS: 'pmscustomer',
    fallbackLng: ["en", "th"], // Always fallback to both 'en' and 'th'
    supportedLngs: ["en", "th"], // Restrict to only these languages
    lng: localStorage.getItem("selectedLanguage") || "th",
    detection: {
      order: ["localStorage", "navigator"],
    },
    backend: {
      loadPath: loadPath,
      parse: function (data) {
      },
      request: (options, url, payload, callback) => {
        instance.get(url, null, process.env.REACT_APP_TOKEN_PUBLIC)
          .then((res) => {
            const data = res.data.data?.allTranslate
              ? res.data.data?.allTranslate
              : null;
            let langObj = {};
            if (data) {
              for (let i = 0; i < data.length; i++) {
                const a = JSON.parse(
                  `{"${data[i].label}": "${data[i].translate}"}`
                );
                langObj = { ...langObj, ...a };
              }
              let result = { ...res, data: langObj };
              callback(null, result);
            } else {
              callback(null, res);
            }
          })
          .catch((err) => {
            console.log(err);
            callback(err, null);
          });
      },
      init: {
        mode: "no-cors",
        credentials: "include",
        cache: "default",
      },
    },
    cache: {
      enabled: true,
      prefix: "i18next_translations_",
      expirationTime: 24 * 60 * 60 * 1000, //one day
    },
  });

// Listen for language changes and update local storage
i18next.on("languageChanged", (lang) => {
  localStorage.setItem("selectedLanguage", lang);
});
