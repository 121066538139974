import instance from "../config/apiAxios";

const BASE_URL = "/subsystem/pmls/sys";

// PMCS_TB_0355 : Get entry exit global config from pmls
const getEntryExitGlobalConfigFormPMLS = async (params) => {
  const response = await instance.get(BASE_URL + "/entryexit/global/conf", {
    params,
  });
  return response.data;
};

// PMCS_TB_0398 : Get online config from pmls
const getOnlineConfigFromPMLS = async (params) => {
  const response = await instance.get(BASE_URL + "/onlineconfig/all", {
    params,
  });
  return response.data;
};

const ConfigService = {
  getEntryExitGlobalConfigFormPMLS,
  getOnlineConfigFromPMLS,
};
export default ConfigService;
