import { Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InputTextFieldAOT } from "../Controls";

const ReceivePaymentConfirmCheckbox = ({
  emailIsValid,
  checkedSendEmail,
  sendEmailValue,
  setCheckedSendEmail,
  setSendEmailValue,
}) => {
  const { t } = useTranslation();
  return (
    <div className="">
      <div className="mt-6 ">
        <Checkbox
          checked={checkedSendEmail}
          onChange={(event) => {
            setCheckedSendEmail(event.target.checked);
          }}
          inputProps={{ "aria-label": "controlled" }}
          label="Label"
          sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
          id="checkedSendEmail"
          name="checkedSendEmail"
        />
        <label htmlFor="checkedSendEmail">{t("Receive payment confirmation via email")}</label>
      </div>
      {checkedSendEmail && (
        <div className="mb-6">
          <InputTextFieldAOT
            label={t("Enter your email address")}
            required
            handleChange={(e) => {
              setSendEmailValue(e.target.value);
            }}
            val={sendEmailValue}
            fullWidth
          />
          {!emailIsValid && (
            <p className="text-red-500 text-xs h-6 mt-2">
              Email format is not valid
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default ReceivePaymentConfirmCheckbox;
