/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress } from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";

import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import BoxPageContent from "../../components/BoxPageContent";
import RenewStep1 from "./RenewStep1";
import RenewStep2 from "./RenewStep2";
import registerService from "../../services/registerService";
import PaymentService from "../../services/payment-service";
import { DATE_FORMATS } from "../../constants";
import ONLINE_PAYMENT_STATUS from "../../constants/payment.js";
import RenewByCardService from "../../services/renewbycard-service";
import ErrorResultWithText from "../Result/ErrorResultWithText";
import LogoAotWithAirportText from "./../LogoAotWithAirportText";
import { toastErr } from "../../utils/index.js";
import useAirportParams from "../../hooks/useAirportParams";

//# 1574
const RenewVisitor = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { pathnameWithAirport } = useAirportParams();
  const [activeStep, setActiveStep] = useState(1);
  const [selectedVehicle, setSelectedVehicle] = useState({
    vehicletypeid: "",
    licenseplate: "",
    licenseprovince: "",
  });
  const [dateDuration, setDataDuration] = useState({
    startDate: "",
    endDate: "",
  });
  const [numberOfMonths, setNumberOfMonths] = useState(1);
  // payment state
  // const [disabledBillBtn, setDisabledBillBtn] = React.useState(true);
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [data, setData] = useState({
    cardId: "",
    pmlsId: "",
  });

  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    const cardId = searchParams.get("cardid");
    const pmlsId = searchParams.get("pmlsid");
    if (!cardId) {
      alert("cardId not found!");
      return;
    }
    if (!pmlsId) {
      alert("pmlsId not found!");
      return;
    }

    getMemberByCardIdFormPMLS.mutate({
      pmlsid: pmlsId,
      cardid: cardId,
    });

    setData({
      cardId: cardId,
      pmlsId: pmlsId,
    });
  }, []);

  const getMemberByCardIdFormPMLS = useMutation({
    mutationFn: (params) =>
      RenewByCardService.getMemberByCardIdFormPmls(params),
    onSuccess: (response) => {
      if (response?.ouIsComplete === 1) {
        const data = response?.data?.TbMember;
        const pmlsId = searchParams.get("pmlsid");
        if (!data) {
          return;
        }
        // const _package = data?.packages?.find(
        //   (_package) =>
        //     _package.status === "Active" && _package.vehicletypeid === 1
        // );

        const primaryVehicle = data?.vehicles?.find(
          (vehicle) =>
            vehicle.status === "Active" && vehicle.vehicletypeid === 1
        );

        const pathname = pathnameWithAirport(
          `/renew?pmlsid=${pmlsId}&licenseplate=${primaryVehicle?.licenseplate}&vehicletypeid=${primaryVehicle?.vehicletypeid}&licenseprovince=${primaryVehicle?.licenseprovince}`,
          pmlsId
        );


        navigate(pathname);

        // setSelectedVehicle({
        //   licenseplate: primaryVehicle?.licenseplate,
        //   vehicletypeid: primaryVehicle?.vehicletypeid,
        //   licenseprovince: primaryVehicle?.licenseprovince,
        // });

        // getPackageGroupMutate.mutate(_package?.packagegroupid);
      }
    },
    onError: () => {},
  });

  const memberData = useMemo(() => {
    return getMemberByCardIdFormPMLS?.data?.data?.TbMember || {};
  }, [getMemberByCardIdFormPMLS?.data?.data?.TbMember]);

  const updateMemberRenewByCardAtPmlsMutate = useMutation({
    mutationFn: (payload) =>
      RenewByCardService.updateMemberRenewByCardAtPmls(payload),
  });

  const memberPackage = useMemo(() => {
    return (
      memberData?.packages?.find(
        (_package) =>
          _package.status === "Active" && _package.vehicletypeid === 1
      ) || {}
    );
  }, [memberData]);

  const getPackageGroupMutate = useMutation({
    mutationFn: (payload) =>
      registerService.getPackagegroup({
        pmlsid: data.pmlsId,
        packagegroupid: payload,
      }),
  });

  useEffect(() => {
    // Check if memberPackage exists and is active
    if (memberPackage && memberPackage.status === "Active") {
      const nowDate = dayjs();
      const packageEndDate = memberPackage.enddate;
      const monthsPassed = nowDate.diff(packageEndDate, "month");
      let _newStart = "";
      let _newEnd = "";

      if (monthsPassed >= 1) {
        _newStart = dayjs().startOf("month").format(DATE_FORMATS.DEFAULT);
        _newEnd = dayjs().endOf("month").format(DATE_FORMATS.DEFAULT);
      } else {
        _newStart = dayjs(packageEndDate)
          .add(1, "month")
          .startOf("month")
          .format(DATE_FORMATS.DEFAULT);
        _newEnd = dayjs(packageEndDate)
          .add(1, "month")
          .endOf("month")
          .format(DATE_FORMATS.DEFAULT);
      }

      setDataDuration({
        startDate: _newStart,
        endDate: _newEnd,
      });
    }
  }, [memberPackage]);

  // 2. create payment
  const onClickPaymentBtn = () => {
    try {
      const processIdFormatted = String(
        updateMemberRenewByCardAtPmlsMutate?.data?.data?.memberprocessid
      ).padStart(9, "0");
      const memberNo = memberData?.memberno;
      const currentDateFormatted = dayjs().format("DDMMYYYY");
      const paymentRef = processIdFormatted + memberNo + currentDateFormatted;
      const payload = {
        pmlsid: data?.pmlsId,
        amount: getTotalPackagePrice,
        paymentsource: "06",
        paymentref1: paymentRef,
      };
      createPaymentQR.mutate(payload);
    } catch (err) {
      toastErr(err);
    }
  };

  const createPaymentQR = useMutation({
    mutationFn: async (payload) =>
      PaymentService.mmapMemberCreateSecurePaymentQrCode(payload),
    onSuccess: (response) => {
      if (response.ouIsComplete === 1) {
        if (!response?.data?.onlinetransid) {
          alert("Error occurred onlinetransid not found!");
          setActiveStep(1);
          return;
        }
        setActiveStep(3);
      } else {
        setIsPaymentComplete(false);
      }
    },
    onError: (response) => {
      alert("Error occurred!");
      setIsPaymentComplete(false);
    },
    select: (data) => data?.data,
  });

  // 3. query qr status
  const qrQueryStatus = useMutation({
    mutationFn: async (payload) => PaymentService.queryQRStatus(payload),
    onSuccess: async (response) => {
      const onlinePaymentData = response?.data?.tbonlinepayment;
      if (
        response.ouIsComplete === 1 &&
        onlinePaymentData?.responsestatus === "200" &&
        onlinePaymentData?.responsedt !== null
      ) {
        try {
          clearInterval(intervalId);
          const renewData = updateMemberRenewByCardAtPmlsMutate?.data?.data;
          const payloadCreateReceiptRenewMember = {
            pmlsid: data?.pmlsId,
            createsource: 3,
            processid: renewData?.memberprocessid,
            createby: 366,
            transtype: "T03",
            paytype: "02",
            totalamount: getTotalPackagePrice,
            paysourcecode: "06",
            memberid: memberData?.memberid,
            memberno: memberData?.memberno,
            remark: "",
            receipttype: "01",
            memberorderid: 0,
            detail: [
              {
                productname: packageData?.name,
                productcode: "productcode",
                itemno: 1,
                unitamount:
                  updateMemberRenewByCardAtPmlsMutate?.data?.data?.amount,
                qty: 1,
                itemdesc1: `${dayjs(renewData?.newexpiredate)
                  .startOf("day")
                  .format(DATE_FORMATS.DEFAULT)} ${dayjs(
                  renewData?.expiredate
                ).format(DATE_FORMATS.DEFAULT)}`,
                itemdesc2: "",
                itemdesc3: "",
              },
            ],
            vatrate: 0,
            info1: "",
            info2: "",
          };
          createReceiptRenewMember.mutate(payloadCreateReceiptRenewMember);
        } catch (err) {
          console.log(err);
        }
      } else if (onlinePaymentData?.responsestatus !== null) {
        clearInterval(intervalId);
        const paymentStatusDesc =
          ONLINE_PAYMENT_STATUS[Number(onlinePaymentData?.responsestatus)]
            ?.description;
        alert(paymentStatusDesc);
        setIsPaymentComplete(false);
        setActiveStep(1);
      }
    },
    onError: (response) => {
      clearInterval(intervalId);
      alert("Error occurred!");
      setIsPaymentComplete(false);
      setActiveStep(1);
    },
  });

  const createReceiptRenewMember = useMutation({
    mutationFn: (payload) =>
      PaymentService.createReceiptForRenewMemberByCard(payload),
    onSuccess: (response) => {
      if (response.ouIsComplete === 1) {
        setActiveStep(4);
      }
    },
    onError: () => {
      alert("Error occurred!");
    },
  });

  // interval loop after createPaymentQR success && !isPaymentComplete
  // clear interval after return or qrQueryStatus completed
  useEffect(() => {
    let newIntervalId = null;
    if (createPaymentQR?.data?.ouIsComplete === 1 && !isPaymentComplete) {
      newIntervalId = setInterval(() => {
        const payloadQRQueryStatus = {
          pmlsid: data?.pmlsId,
          onlinetransid: createPaymentQR?.data?.data?.onlinetransid,
        };
        qrQueryStatus.mutate(payloadQRQueryStatus);
      }, 3000);
      setIntervalId(newIntervalId);
    }

    return () => {
      clearInterval(newIntervalId);
    };
  }, [createPaymentQR?.data, isPaymentComplete]);

  //1. Create renew member request at pmls
  const onClickUpdateButtonHandler = () => {
    try {
      const payload = {
        pmlsid: data?.pmlsId, // ส่ง 1 หรือ 2 ตามที่จับได้จากเลขขึ้นต้นบัตรสมาชิก
        memberno: memberData?.memberno, // ส่ง memberno ที่ได้จาก /subsystem/pmls/member/getbycardid?
        vehicletypeid: 1, // ส่ง 1 (fix)
      };
      updateMemberRenewByCardAtPmlsMutate
        .mutateAsync(payload)
        .then((res) => {
          if (res.ouMessage === "Success" && res.ouIsComplete === 1) {
            setActiveStep((prev) => prev + 1);
          }
        })
        .catch((error) => {
          alert(error?.response?.data?.message);
        });
    } catch {}
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const packageData = getPackageGroupMutate?.data?.data?.PackageGroup || {};

  const getTotalPackagePrice = useMemo(() => {
    if (packageData?.items?.length > 0) {
      const total = packageData?.items
        ?.filter((item) => item.chargetype === "002")
        .reduce((sum, item) => {
          return (
            sum +
            Number(item?.totalprice ? item?.totalprice * numberOfMonths : 0)
          );
        }, 0);
      return total;
    } else {
      return 0;
    }
  }, [numberOfMonths, packageData]);

  if (getMemberByCardIdFormPMLS.isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  const showEmpty = Object.keys(memberData).length === 0;

  return (
    <div className="flex flex-col mt-20 items-center justify-center">
      <LogoAotWithAirportText pmlsid={data?.pmlsId} />
      {!showEmpty ? (
        <Box sx={{ width: "100%" }}>
          <BoxPageContent
            breadcrumbRoot={t("Home")}
            breadcrumbBranch={t("Renew Membership")}
            showBreadcrumb={false}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              {activeStep === 1 ? (
                <RenewStep1
                  setActiveStep={setActiveStep}
                  data={memberData}
                  selectedVehicle={selectedVehicle}
                  setSelectedVehicle={setSelectedVehicle}
                  packageData={packageData}
                  dateDuration={dateDuration}
                  setDataDuration={setDataDuration}
                  numberOfMonths={numberOfMonths}
                  setNumberOfMonths={setNumberOfMonths}
                  getTotalPackagePrice={getTotalPackagePrice}
                  disabled={false}
                  onClickUpdateButtonHandler={onClickUpdateButtonHandler}
                  memberPackage={memberPackage}
                />
              ) : (
                <RenewStep2
                  numberOfMonths={numberOfMonths}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  selectedVehicle={selectedVehicle}
                  dateDuration={dateDuration}
                  totalPrice={getTotalPackagePrice}
                  onClickPaymentBtn={onClickPaymentBtn}
                  paymentData={createPaymentQR?.data?.data}
                  responsePaymentData={createReceiptRenewMember?.data?.data}
                  pmlsId={data?.pmlsId}
                />
              )}
            </Box>
          </BoxPageContent>
        </Box>
      ) : (
        <ErrorResultWithText
          text={
            <p className="text-blue-800 text-xl md:text-2xl font-bold">
              ไม่พบข้อมูลบัตรสมาชิกของท่าน <br /> กรุณาติดต่อเจ้าหน้าที่
            </p>
          }
        />
      )}
    </div>
  );
};

export default RenewVisitor;
